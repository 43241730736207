import React from 'react'
import { Box, Button, Typography } from '@mui/material'
  import { useNavigate } from 'react-router-dom'

function BlockedUserNotification({value}) {

  const navigate = useNavigate()
  return (
    <Box maxWidth={'100%'}>
      <Box m='20px' gap="20px" sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <img 
              src="https://www.loanbooks.co/wp-content/uploads/2023/09/blocked.png" alt="blocked" 
              style={{ width: '200px', height: 'auto'}} 
            />
        {value === 'blocked' && <Box m='20px' gap="20px" sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
          <h1>FREE TRIAL EXPIRED</h1>
          <Typography>Dear User, your 30 Days Free Trial has Expired.</Typography>
          <Typography>To continue using this software, kindly buy a Subscription.</Typography>
          <Button onClick={()=>navigate('/packages')} sx={{width: '150px'}}
          color='info' variant='contained'>BUY NOW</Button>
        </Box>}
        {value === 'subscriptionExpired' && <Box m='20px' gap="20px" sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
          <h1>SUBSCRIPTION EXPIRED</h1>
          <Typography>Dear User, your subscription has expired.</Typography>
          <Typography>To continue using this software, kindly renew your subscription.</Typography>
          <Button onClick={()=>navigate('/packages')} sx={{width: '150px'}}
          color='info' variant='contained'>RENEW SUBSCRIPTION</Button>
        </Box>}
      </Box>
    </Box>
  )
}

export default BlockedUserNotification