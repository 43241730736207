import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

export default function TransitionAlerts({buttonText, message, button1, button2}) {
  const [open, setOpen] = useState(false)

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
        severity='info'
        action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        sx={{ mb: 2, fontSize: "13px"}}
        >
        <Box display={'flex'} flexDirection={'column'}>
          {message} 
          {(button1 || button2) && <Box display={'flex'} flexDirection={'row'} gap={'30px'} p="20px">
            {button1} 
            {button2}
          </Box>}
        </Box>
        </Alert>
      </Collapse>
      {!open && <Button
        disabled={open}
        color="info"
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        {buttonText}
      </Button>}
    </Box>
  );
}