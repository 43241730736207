import { Box, IconButton, useTheme, Button, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { SignOutContext, UserEmailContext, UserTypeContext } from "../../App";
import '../../index.css'
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate()
  
  const signOut = useContext(SignOutContext)
  const email = useContext(UserEmailContext);
  const {userTypeAWS} = useContext(UserTypeContext)
  const link = 'https://web.whatsapp.com/send/?phone=12027736878&text=Hi+LoanTabs+Support.+I+need+help+with...'

  //states
  const [showRibbon, setShowRibbon] = useState(false)

  return (<>
      <Box display="flex" justifyContent="space-between" pl='10px' pr="10px" className='topbar'
        flexDirection={'row'} alignItems={'center'} backgroundColor="black" mb="20px"
        width="100%"
        maxWidth={'900px'} >
        {!showRibbon && <IconButton onClick={()=>setShowRibbon(true)}>
          <InfoIcon color="info"/>
        </IconButton>}
        {showRibbon && <Button color={'warning'} size={'small'} variant="outlined"
          onClick={()=>setShowRibbon(false)}>Hide info </Button>}
        {showRibbon && <><Box display="flex" flexDirection={'row'} alignItems={"center"}>
          <Typography  >Logged in as {email} </Typography>
          <Tooltip title="Log Out">
            <IconButton onClick={()=>{
                    navigate('/')
                    signOut()
                  }}>
              <HighlightOffIcon color="error"/>
            </IconButton>
          </Tooltip>
        </Box>
        <Typography ml="15px"  >Access Level: {userTypeAWS} </Typography></>}
        <Box display="flex" flexDirection={'row'} alignItems={"center"}>
          <Typography ml="15px" >Need help?</Typography>
          <Tooltip title="Contact Support">
            <IconButton onClick={()=> window.open(link, '_blank')}>
              <WhatsAppIcon color="success"/>
            </IconButton>
          </Tooltip>
        </Box>
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <Button onClick={signOut} variant="contained" color="error">Sign out</Button> */}
      </Box>
    
  </>);
};

export default Topbar;