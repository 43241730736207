import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

//component imports
import CreateExpenseForm from './ExpenseForms/ExpenseForm';
import Header from '../../components/Header';
import UploadExpenseDocs from './ExpenseForms/UploadExpenseDocs';
import SuccessMessage from '../../components/SuccessMessage';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function NewExpense(){
  return <></>
}

// export default function NewExpense() {

//   //variables
//   const navigate = useNavigate();
//   const {id, firstName, otherName, phoneNumber, otherPhoneNumber} = useParams()
//   const sliced = string => string.slice(1)
  
//   //states
//   const [value, setValue] = React.useState(0);
//   const [editableBorrower, setEditableBorrower] = useState()
//   const [successMessage, setSuccessMessage] = useState()
//   const [loading, setLoading] = useState()

//   //useEffect 
//   useEffect(()=>{
//     const getExpenseDetails = async()=>{
//       try{
//         const expenseDetails = await API.graphql(graphqlOperation(`query MyQuery {
//           getExpense(id: "${sliced(id)}") {
//             address
//             borrowerDocument1
//             borrowerDocument1URL
//             borrowerDocument2
//             borrowerDocument2URL
//             borrowerDocument3
//             borrowerDocument3URL
//             borrowerDocument4
//             borrowerDocument4URL
//             businessName
//             city
//             dateOfBirth
//             email
//             employerName
//             employmentStatus
//             gender
//             nationality
//             nationalIdPicture
//             passportPicture
//             uniqueIdNumber
//             state
//             typeOfBusiness
//             zipcode
//             creditScore
//             additionalNote1
//             additionalNote2
//           }
//         }
//         `));
//         if(expenseDetails.data.getExpense){
//           const expense = expenseDetails.data.getExpense
//           expense.id = sliced(id)
//           expense.firstname = sliced(firstName)
//           expense.othername = sliced(otherName)
//           expense.phoneNumber = sliced(phoneNumber)
//           expense.otherPhoneNumber = sliced(otherPhoneNumber)
//           setEditableExpense(expense)
//           setLoading(false)
//         }
//       }catch(e){
//           console.log('Error geting expense details: ',e)
//       }
//     }
//     id && getBorrowerDetails()
//     id && setLoading(true)
//     // eslint-disable-next-line
//   },[])

//   //funcitons
//   function getBorrowerName(){
//     return editableBorrower?.othername 
//     ? `${editableBorrower?.firstname} ${editableBorrower?.othername}`
//     : editableBorrower?.firstname
//   }

//   function readyToEdit(values){
//     setEditableBorrower(values)
//     // setSuccessMessage(`${getBorrowerName()} Created Successfully`)
//     // setTimeout(() => setSuccessMessage(false), 15000)
//   }

//   function editedBorrower(values){
//     const tempBorrower = {...editableBorrower}
//     for (const key in values) {
//       tempBorrower[key] = values[key];
//     }
//     setEditableBorrower(tempBorrower)
//     setSuccessMessage(`${getBorrowerName()} Edited Successfully`)
//     setTimeout(() => setSuccessMessage(false), 15000)
//   }

//   function handleChange(event, newValue){
//     setValue(newValue);
//   };

//   function syncBorrower(key, value){
//     const tempBorrower = {...editableBorrower}
//     tempBorrower[key] = value;
//     setEditableBorrower(tempBorrower);
//   }

//   function syncBorrowerDocs(docNameKey, docNameValue, docURLKey, docURLValue) {
//     const tempBorrower = {...editableBorrower}
//     tempBorrower[docNameKey] = docNameValue;
//     tempBorrower[docURLKey] = docURLValue;
//     setEditableBorrower(tempBorrower);
//   }

//   const deleteBorrower = async()=>{
//     try{
//       const deletedBorrower = await API.graphql(graphqlOperation(`mutation MyMutation {
//         deleteBorrower(input: {id: "${editableBorrower.id}"}) {
//           id
//         }
//       }`));
//       if(deletedBorrower){
//         setSuccessMessage(`${getBorrowerName()} Deleted Successfully`)
//         setTimeout(()=>navigate(`/manageBorrowers`), 1500)
//       }
//     }catch(e){
//       console.log('Error deleting borrower: ',e)
//     }
//   }


//   return (<>
//     {loading && <Box p='40px'>
//       <Typography variant='h2'>Loading Borrower Details...</Typography>
//     </Box>}
//     {!loading && <Box sx={{ width: 850, mt: '10px' }}>
//       {!editableBorrower && <Header title="Create Borrower" subtitle="Create a New Borrower Profile" />}
//       {editableBorrower && 
//       <Box>
//         {successMessage && <SuccessMessage message={successMessage}/>}
//         <Box mt='30px' mb='30px' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
//           <Button 
//             onClick={() => setEditableBorrower(null)}
//             variant="contained" color='success'>Add Another Borrower</Button>
//           <Button 
//             onClick={deleteBorrower}
//             variant="outlined" color="error">{`Delete ${getBorrowerName()}`}</Button>
//         </Box>
//         <Header title={`${getBorrowerName().toUpperCase()} (Borrower Profile)`}
//           subtitle="View/Update the Borrower details" />
//       </Box>
//         }
//       <Box sx = {{bgcolor: 'background.paper', mb: '10px'}}>
//           <Tabs
//             value={value}
//             onChange={handleChange}
//             variant="scrollable"
//             scrollButtons="auto"
//             aria-label="secondary tabs example"
//             textColor="secondary"
//             indicatorColor="secondary"
//           >
//             <Tab {...a11yProps(0)} label="BORROWER DETAILS" />
//             <Tab {...a11yProps(1)} label="PHOTO & ID" />
//             <Tab {...a11yProps(2)} label="EMPLOYMENT & OTHER DETAILS" />
//             <Tab {...a11yProps(3)} label="DOCUMENTS UPLOAD" /> 
//           </Tabs>  
//       </Box>
//       <CustomTabPanel value={value} index={0}>
//         {!editableBorrower && <CreateBorrowerForm readyToEdit={readyToEdit}/>}
//         {editableBorrower && <CreateBorrowerForm initialBorrower={editableBorrower}
//           editedBorrower={editedBorrower}/>}
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={1}>
//         {!editableBorrower && <Button sx={{m: '40px'}} onClick={()=>{setValue(0)}} variant='contained'
//           color='info'>You must first create a Borrower</Button>}
//         {editableBorrower && <PhotoAndId initialBorrower={editableBorrower}
//           borrowerId={editableBorrower.id} syncBorrower={syncBorrower}/>}
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={2}>
//         {!editableBorrower && <Button sx={{m: '40px'}} onClick={()=>{setValue(0)}} variant='contained'
//             color='info'>You must first create a Borrower</Button>}
//         {editableBorrower && <MoreBorrowerDetailsForm initialBorrower={editableBorrower}
//           editedBorrower={editedBorrower}/>}
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={3}>
//         {!editableBorrower && <Button sx={{m: '40px'}} onClick={()=>{setValue(0)}} variant='contained'
//           color='info'>You must first create a Borrower</Button>}
//         {editableBorrower && <UploadBorrowerDocs initialBorrower={editableBorrower}
//           borrowerId={editableBorrower.id} syncBorrower={syncBorrowerDocs}/>}
//       </CustomTabPanel>
//     </Box>}
//   </>);
// }
