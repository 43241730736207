import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";
import UploadFile from "../../../components/UploadFile";
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { uploads3, assetPlaceHolderImage } from "../../../assets/uploads3";
import UploadNamedDocument from "../../../components/UploadNamedDocument";

const UploadBorrowerDocs = ({borrowerId, initialBorrower, syncBorrower}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [borrowerDoc1, setBorrowerDoc1] = useState('')
  const [borrowerDocument1Pic, setBorrowerDocument1Pic] = useState()
  const [borrowerDocument1PicCloud, setBorrowerDocument1PicCloud] = useState()
  const [borrowerDoc2, setBorrowerDoc2] = useState('')
  const [borrowerDocument2Pic, setBorrowerDocument2Pic] = useState()
  const [borrowerDocument2PicCloud, setBorrowerDocument2PicCloud] = useState()
  const [borrowerDoc3, setBorrowerDoc3] = useState('')
  const [borrowerDocument3Pic, setBorrowerDocument3Pic] = useState()
  const [borrowerDocument3PicCloud, setBorrowerDocument3PicCloud] = useState()
  const [borrowerDoc4, setBorrowerDoc4] = useState('')
  const [borrowerDocument4Pic, setBorrowerDocument4Pic] = useState()
  const [borrowerDocument4PicCloud, setBorrowerDocument4PicCloud] = useState()
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(()=>{
    if(borrowerDocument1PicCloud){
      saveToAmplify(
        `borrowerDocument1: "${borrowerDoc1}",`,
        `borrowerDocument1URL: "${borrowerDocument1PicCloud}",`
        );
      syncBorrower(
        'borrowerDocument1', borrowerDoc1,
        'borrowerDocument1URL', borrowerDocument1PicCloud
      )
    } 
  }, [borrowerDocument1PicCloud])

  useEffect(()=>{
    if(borrowerDocument2PicCloud){
      saveToAmplify(
        `borrowerDocument2: "${borrowerDoc2}",`,
        `borrowerDocument2URL: "${borrowerDocument2PicCloud}",`
        );
      syncBorrower(
        'borrowerDocument2', borrowerDoc2,
        'borrowerDocument2URL', borrowerDocument2PicCloud
      )
    } 
  }, [borrowerDocument2PicCloud])

  useEffect(()=>{
    if(borrowerDocument3PicCloud){
      saveToAmplify(
        `borrowerDocument3: "${borrowerDoc3}",`,
        `borrowerDocument3URL: "${borrowerDocument3PicCloud}",`
        );
      syncBorrower(
        'borrowerDocument3', borrowerDoc3,
        'borrowerDocument3URL', borrowerDocument3PicCloud
      )
    } 
  }, [borrowerDocument3PicCloud])

  useEffect(()=>{
    if(borrowerDocument4PicCloud){
      saveToAmplify(
        `borrowerDocument4: "${borrowerDoc4}",`,
        `borrowerDocument4URL: "${borrowerDocument4PicCloud}",`
        );
      syncBorrower(
        'borrowerDocument4', borrowerDoc4,
        'borrowerDocument4URL', borrowerDocument4PicCloud
      )
    } 
  }, [borrowerDocument4PicCloud])

  const saveToAmplify =  async(docName, docURL) =>{
    try{
      const updatedBorrower = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          updateBorrower(input: {
            ${docName}
            ${docURL}
            id: "${borrowerId}"
          }) 
          {
            id
          }
        }`
      ))
      if(updatedBorrower)
      {
        alert('Document Saved Successfully')
      }
    }
    catch(e){
      console.log('File upload Failed', e)
    }
  }
    
  function handleSave(req, setRes){
    uploads3(req, setRes)
  }

  return (
    <Box width="100%" p="20px" sx={{ display: 'flex', flexDirection: 'column'}}>
      <Typography>
        {`Upload upto 4 Documents relevant to this borrower 
        (e.g. bank statement, employment contract, etc).`}<br/>
        {`(Max. size per upload is 10MB)`}</Typography>
      <Typography mt="15px" color="error">
        {`For security reasons, some file names are scrambled after upload.`}<br/>
        {`To open a downloaded file, save it with the correct extension (e.g. ".docx", ".xls"). `}
        <a style={{color: 'grey'}} target="_blank" href='https://helpdesk.flexradio.com/hc/en-us/articles/204676189-How-to-change-a-File-Extension-in-Windows'>Learn More</a>
      </Typography>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}>
        <Box sx={{ gridColumn: "span 2" }}>
          <UploadNamedDocument
            handleSave={handleSave}
            reqFile = {borrowerDocument1Pic}
            setResFileURL = {setBorrowerDocument1PicCloud}
            resFileURL = {borrowerDocument1PicCloud}
            uploadLabel={'Upload File / Document'}
            setUploadFile={setBorrowerDocument1Pic}
            placeholderImage={(function(){
              return initialBorrower.borrowerDocument1URL
                ? initialBorrower.borrowerDocument1URL
                : assetPlaceHolderImage
              })()}
            prevFileName={initialBorrower.borrowerDocument1}
            prevFileURL={initialBorrower.borrowerDocument1URL}
            setTitle={setBorrowerDoc1}
          />
        </Box>
        <Box sx={{ gridColumn: "span 2" }}>
          <UploadNamedDocument
            handleSave={handleSave}
            reqFile = {borrowerDocument2Pic}
            setResFileURL = {setBorrowerDocument2PicCloud}
            resFileURL = {borrowerDocument2PicCloud}
            uploadLabel={'Upload File / Document'}
            setUploadFile={setBorrowerDocument2Pic}
            placeholderImage={(function(){
              return initialBorrower.borrowerDocument2URL
                ? initialBorrower.borrowerDocument2URL
                : assetPlaceHolderImage
              })()}
            prevFileName={initialBorrower.borrowerDocument2}
            prevFileURL={initialBorrower.borrowerDocument2URL}
            setTitle={setBorrowerDoc2}
          />
        </Box>
        <Box sx={{ gridColumn: "span 2" }}>
          <UploadNamedDocument
            handleSave={handleSave}
            reqFile = {borrowerDocument3Pic}
            setResFileURL = {setBorrowerDocument3PicCloud}
            resFileURL = {borrowerDocument3PicCloud}
            uploadLabel={'Upload File / Document'}
            setUploadFile={setBorrowerDocument3Pic}
            placeholderImage={(function(){
              return initialBorrower.borrowerDocument3URL
                ? initialBorrower.borrowerDocument3URL
                : assetPlaceHolderImage
              })()}
            prevFileName={initialBorrower.borrowerDocument3}
            prevFileURL={initialBorrower.borrowerDocument3URL}
            setTitle={setBorrowerDoc3}
          />
        </Box>
        <Box sx={{ gridColumn: "span 2" }}>
          <UploadNamedDocument
            handleSave={handleSave}
            reqFile = {borrowerDocument4Pic}
            setResFileURL = {setBorrowerDocument4PicCloud}
            resFileURL = {borrowerDocument4PicCloud}
            uploadLabel={'Upload File / Document'}
            setUploadFile={setBorrowerDocument4Pic}
            placeholderImage={(function(){
              return initialBorrower.borrowerDocument4URL
                ? initialBorrower.borrowerDocument4URL
                : assetPlaceHolderImage
              })()}
            prevFileName={initialBorrower.borrowerDocument4}
            prevFileURL={initialBorrower.borrowerDocument4URL}
            setTitle={setBorrowerDoc4}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UploadBorrowerDocs;