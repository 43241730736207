import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {  useState} from "react";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
import "../assets/custom.css";
import { tokens } from "../theme";
import { numberRegex } from "../assets/currenciesObj";

const GridTextInput = ({updateValue, cancelEdit, oldValue, type, deletePayment}) => {
 
  //states
    const [processing, setProcessing] = useState(false)

  //constants
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const initialValues = {
      formerValue: oldValue,
    };
        
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'
    
    const handleFormSubmit = (values) => {
      updateValue(values)
    };
    const checkoutSchema = yup.object().shape({
      formerValue: type === 'number' 
          ? yup.string().matches(numberRegex, "must be a number").required("required")
          : yup.string().required("required"),
    });

    return (
      <Box sx={{backgroundColor: '#071318'}} className='printNot' display={'flex'}
        flexDirection={'row'} alignItems={'center'}>
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
        
            }) => (
            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'row'}}>
                <TextField 
                    fullWidth
                    variant="filled"
                    type="text"
                    height="1em !important"
                    label=''
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={
                      (function(){
                        return type === 'number' 
                          ? values.formerValue.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          : values.formerValue
                      })()
                    }
                    name="formerValue"
                    error={!!touched.formerValue && !!errors.formerValue}
                    helperText={touched.formerValue && errors.formerValue}
                />
                
                <Box display={'flex'} flexDirection={'column'}>
                  <Box m="3px">
                    <Button variant="contained" color="success" size="small"
                      onClick={handleSubmit}>SAVE</Button>
                  </Box>
                  {deletePayment !== null && <Box m="3px">
                    <Button variant="contained" color="error" size="small"
                      onClick={deletePayment}>DELETE</Button>
                  </Box>}
                </Box>
            </form>
            )}
        </Formik>
        <CancelPresentationIcon fontSize='medium' color='info' onClick={cancelEdit} cursor="pointer" />
        {processing && <Box display='flex'>
            <Typography
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "5px" }}
            >Processing... </Typography>
          </Box>}
      </Box>
    );
  };
 
  
export default GridTextInput