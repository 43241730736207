import { Typography, Box } from '@mui/material'
import React from 'react'

function UnderDevelopment() {
  return (
    <Box p="40px" gap="20px" display="flex" flexDirection={'column'}>
        <Typography variant="h4">This Feature is Under Development</Typography>
        <Typography variant="h2">Expected by 15-Mar-2024</Typography>
    </Box>
  )
}

export default UnderDevelopment