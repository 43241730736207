import { Box, Button, Typography, useTheme} from '@mui/material'
import React from 'react'
import InfoIcon from '@mui/icons-material/Info';
import {tokens} from '../theme'

const Notice = ({message, viewNotice}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box 
        display='flex'
        p='8px'
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'
        boxShadow={'0 0 10px rgba(0, 0, 0, 0.5)'}
        gap="10px"
        width="100%"
        backgroundColor={colors.redAccent[600]}
        onClick={viewNotice}
    >
        <InfoIcon 
        fontSize='large'
        />
        <Typography variant='h6'
        sx={{
          color: 'white',
          fontWeight: '600'          
        }}>{message}</Typography>
        <Button variant="contained" color='success'>
          View
        </Button>
    </Box>
  )
}

export default Notice