import React from 'react'
import loading from '../assets/loading.gif'
import logo from '../assets/loantabs_logo.png'
import { Box, Typography } from '@mui/material'

function Loading() {
  return (
    <Box display={'flex'} flexDirection={'column'} p='40px'
        alignItems={'center'}>
        <img src={logo} alt="LoanTabs Logo" />
        <Typography variant="h4">LoanTabs Loading...</Typography>
        <img src={loading} alt="LoanTabs Loading" />
    </Box>
  )
}

export default Loading