import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LoansContext, SignOutContext, UserTypeContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
//compoent imports
import { getNavItems, goToLink } from '../../assets/navigationMenu';

function MobileMenu(props) {
    //contants
    const drawerWidth = 280;
    const navigate = useNavigate()
    const signOut = useContext(SignOutContext)
    const {loansContext} = useContext(LoansContext)
    const [navItems, setNavItems] = useState(getNavItems('/loadReports'))

    useEffect(()=>{
        loansContext && setNavItems(getNavItems('/reportsPanel'))
      }, [loansContext])
        
    const {businessName} = useContext(UserTypeContext)
    const displayBusinessName = businessName || (businessName !== '') ? businessName : 'LoanTabs'
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left', px: '20px'}}>
      <Divider />
      <List>
        {navItems.map((item) => (<>
          {item?.head && <Typography
            sx={{color: '#CECECE'}}
          >{item.head}</Typography>}
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {item?.icon}
            <ListItem key={item.title} disablePadding>
                <ListItemButton 
                    onClick={()=>{
                        if(item?.to){
                            navigate(item?.to)
                        }else{
                            goToLink(item?.link)
                        }
                    }}
                >
                <ListItemText primary={item.title} />
                </ListItemButton>
            </ListItem>
          </Box>
        </>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }} className='printNot'>
      <CssBaseline />
      <AppBar component="nav" sx={{ display: {md: 'none'}}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            // sx={{ flexGrow: 1,  }}
          >
            {displayBusinessName.toUpperCase()}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box'}, width: drawerWidth
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default MobileMenu;
