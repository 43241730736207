import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
//componet imports
import ReportDateRange from '../../components/ReportDateRange';
import { tokens } from '../../theme';
import { LoansContext } from '../../App';
import { distributePayment, distributePaymentLS } from '../../assets/reportsCalculations';
import { UserTypeContext } from '../../App';

function PaymentsReport() {

    //constants
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const {loansContext} = useContext(LoansContext)
    const {decimalPoints} = useContext(UserTypeContext);

    //states
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [rows, setRows] = useState()

    //useEffect
    useEffect(() => {
        const configureReport = () => {
            const loansInPeriod = loansContext.filter((loan) => { 
                return moment(loan.startDate).isSameOrAfter(moment(startDate))
                    && moment(loan.startDate).isSameOrBefore(moment(endDate))
            })
            const rowsArray = []
            const createRow = (status, id) => {
                const selectedLoans = loansInPeriod.filter((loan) => 
                    loan.status === status
                )
                if(selectedLoans.length > 0){
                    //first row
                    const row1 = {
                        id: id,
                        status: status.toUpperCase(),
                        principal: selectedLoans.reduce((acc,loan)=> acc + loan?.principal, 0),
                        interest: selectedLoans.reduce((acc,loan)=> acc + loan?.totalInterest, 0),
                    }
                    row1.total = row1.principal + row1.interest
                    rowsArray.push(row1)
                    //second row
                    const payments = selectedLoans.map((loan)=>{
                        if(loan?.payments?.length >0){
                            const payment = (loan?.loanType === 'instalments') 
                                || (loan?.loanType === 'flatInstalments') 
                                ? distributePayment(loan?.principal, loan?.duration, 
                                parseFloat(loan?.interestRate.slice(0,-1)), loan?.payments, loan?.loanType,
                                loan?.durationInterval, loan?.rateInterval)
                                : distributePaymentLS(loan?.principal, loan?.payments)
                            return {
                                principal: payment.principalPaid, 
                                interest: payment.interestPaid,
                                total: payment.paymentsSum
                            }
                        }else{
                            return {principal: 0, interest: 0, total: 0}
                        }
                    })
                    const row2 = {
                        id: id*1.01,
                        status: 'Payments made',
                        principal: payments.reduce((acc, payment) => acc + payment?.principal, 0),
                        interest: payments.reduce((acc, payment) => acc + payment?.interest, 0),
                        total: payments.reduce((acc, payment) => acc + payment?.total, 0)
                    }
                    rowsArray.push(row2)
                    rowsArray.push({
                        id: id*1.02,
                        status: 'Unpaid balance',
                        principal: row1.principal - row2.principal,
                        interest: row1.interest - row2.interest,
                        total: row1.total - row2.total
                    })
                }else{
                    rowsArray.push({id: id, status: status.toUpperCase(), principal: 0, 
                        interest: 0, total: 0})
                    rowsArray.push({id: id*1.01, status: 'Payments made', principal: 0, 
                        interest: 0, total: 0})
                    rowsArray.push({id: id*1.02, status: 'Unpaid balance', principal: 0, 
                        interest: 0, total: 0})
                }
            }
            let count = 1;
            createRow('current', count++)
            createRow('payment due', count++)
            createRow('overdue', count++)
            createRow('cleared', count++)
            
         //configure lumpsum payments
            setRows(rowsArray);
        }
        loansContext && configureReport()
    },[startDate, endDate, loansContext])

    //columns
    const columns = [
        {
            field: "status",
            sortable: false,
            headerName: "Loan Status",
            flex: 2,
            align: 'left',
            headerAlign: 'left',
            type: 'text',
            renderCell: (params) => {
                switch(params.row.status){
                    case 'Payments made':
                        return <Box display="flex" flexDirection="row" justifyContent={'flex-end'}
                            width="100%">
                            {params.row.status}
                        </Box>; 
                    case 'Unpaid balance':
                        return <Box display="flex" flexDirection="row" justifyContent={'flex-end'}
                            width="100%">{params.row.status}</Box>
                    default:
                        return <Box display="flex" flexDirection="row" justifyContent={'space-between'}
                            width="100%">
                            {params.row.status}
                            <Typography color={colors.greenAccent[200]}>Loans value</Typography>
                        </Box>; 
                }
            },
        },
        {
            field: "principal",
            sortable: false,
            headerName: "Principal",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.principal?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "interest",
            sortable: false,
            headerName: "Interest Charged",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.interest?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "total",
            sortable: false,
            headerName: "Total",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.total?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
    ]

  return (
    <Box sx={{minWidth: '900px'}} display="flex" flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'} gap={'60px'} mb='20px' justifyContent={'space-between'}>
            <Typography variant="h2" >PAYMENTS REPORT</Typography>
            <Button color='error' variant='outlined' 
                onClick={()=>navigate('/reportsPanel')}>Close Report</Button>
        </Box>
        <Box>
            {startDate && endDate && <ReportDateRange startDate={startDate} endDate={endDate}
                setStartDate={setStartDate} setEndDate={setEndDate}/>}
        </Box>
        {rows && <Box mt='20px'width="850px" pb="60px"
            sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
            },
            "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important"
            },
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                height: "30px",
                border: "none",
            },
            "& .name-column--cell": {
                color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            ".balanceRow": {
                borderBottom: "3px solid white",
                color: colors.redAccent[200]
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            "& .normalLineHeight": {
                height: `200px !important`,
            },
            ".paymentRow": {
                color: colors.blueAccent[200],
            }
            }}>
          <DataGrid rows={rows} columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            autoHeight={true}
            hideFooter={true}
            getRowClassName={(params) => {
                switch(params.row.status) {
                    case "Payments made":
                        return 'paymentRow';
                    case "Unpaid balance":
                        return 'balanceRow';
                    default: return ''
                }
            }}
          />
        </Box>}
    </Box>
  )
}

export default PaymentsReport