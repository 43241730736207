import { createLoanStatement, formatNumber } from './loanStatementArrays';
import { formatGraphQLDate } from './loanStatementCalculations';
import { createLoanStatementA } from './AmortizedLoanStatementArrays';
import { scheduleLoan } from './loanCalculationsPerDayInt';

const moment = require('moment');

function loanCompleter(allLoans, decimalPoints) {
  const today = moment(); // Get current date

// Filter loans where instalments are not fully paid
  const loansArray = []

  allLoans.forEach(loan => {
    const { startDate, payments, principal, interestRate, durationInterval, penalties,
      duration, loanType, maturityDate, status, rateInterval, lateInterestBySystem,
      numberOfPayments, paymentFrequency } = loan;

    const interest = parseFloat(interestRate.slice(0, -1))

    const schedule = scheduleLoan(principal, interest, duration, startDate, 
      rateInterval, durationInterval, loanType, decimalPoints,
      numberOfPayments, paymentFrequency
      )

    const loanData = schedule[0]
    const paymentsData = payments.map((item) => {
      return({
        id: item.id,
        paymentAmount: item.amount, 
        paymentDate: formatGraphQLDate(item.paymentDate)
      })
    })
    const penaltiesData = penalties.map((item) => {
      return({
        id: item.id,
        penaltyAmount: item.amount, 
        penaltyDate: formatGraphQLDate(item.penaltyDate),
        comment: item.penaltyAttribute1 || "PENALTY"
      })
    })
  // for instalmnet loans
    if((loanType === 'instalments') || (loanType === 'flatInstalments')){
      const statementInfo = createLoanStatementA(loanData, paymentsData, startDate, duration, 
        interest, status, lateInterestBySystem, penaltiesData, 
        rateInterval, durationInterval, null, schedule[6])
      loan.statement = statementInfo[0]
      loan.status= statementInfo[2]
      loan.amountDue = statementInfo[3]
      loan.balance = formatNumber(statementInfo[0][statementInfo[0].length - 1].balance)
      loan.totalPaid = formatNumber(statementInfo[1])
      loan.totalInterest = schedule[7]
    }
  //for lumpsum loans
    if((loanType === 'lumpSum') || (loanType === 'simpleLumpSum')){
      const loanStatement = createLoanStatement(principal, startDate, interest, 
        payments, duration, loanType, lateInterestBySystem, penaltiesData,
        rateInterval, durationInterval)
      loan.statement = loanStatement[0]
      loan.status = loanStatement[5]
      loan.amountDue =  loanStatement[6]
      loan.balance = formatNumber(loanStatement[0][loanStatement[0].length - 1].newPrincipal)
      loan.totalPaid = formatNumber(loanStatement[1])
      loan.totalInterest = schedule[5]
    }
    loan.schedule = schedule; 
  // push object to final array
    loansArray.push(loan)
  });
  return loansArray;
}

function filterByStatus(loansArray, statusFilter){
  const filteredLoans = loansArray?.filter((loan)=>{
    return loan.status?.includes(statusFilter)
  })
  return filteredLoans
}

function filterByDuration(loansArray, minimumDays, maximumDays) {
  const filteredLoans = loansArray.filter(loan => {
    const maturityMoment = moment(loan.maturityDate);
    const today = moment();
    const daysDifference = today.diff(maturityMoment, 'days');
    const minDays = minimumDays || 0
    const maxDays = maximumDays || Infinity
    return (daysDifference >= minDays) && (daysDifference <= maxDays);
  });
  return filteredLoans;
}

function currentLoansFilter(loansArray, minimumDays, maximumDays) {
  let instalmentLoans = []
  let lumpsumLoans = []
  //for instalment loans
  instalmentLoans = loansArray.filter(loan => {
    if((loan.loanType === 'instalments') || (loan.loanType === 'flatInstalments')){
      const today = moment();
      const statement = loan.statement
      const dueInstalments = statement.filter((item) => {
        if(item.description === "Instalment Due"){
          const instalmentDate = moment(item.date);
          const today = moment();
          const daysDifference = instalmentDate.diff(today, 'days');
          const minDays = minimumDays || 0
          const maxDays = maximumDays || Infinity
          return (daysDifference >= minDays) && (daysDifference <= maxDays);
        }
      })
      return dueInstalments.length > 0
    }
  })
  lumpsumLoans = loansArray.filter(loan => {
    if((loan.loanType === 'lumpSum') || (loan.loanType === 'simpleLumpSum')){
      const maturityMoment = moment(loan.maturityDate);
      const today = moment();
      const daysDifference = maturityMoment.diff(today, 'days');
      const minDays = minimumDays || 0
      const maxDays = maximumDays || Infinity
      return (daysDifference >= minDays) && (daysDifference <= maxDays);
    }
  });
  
  //for lumpsum loans
  //return filtered loans
  return [...instalmentLoans, ...lumpsumLoans];
}
function maturityFilter(loansArray, minimumDays, maximumDays) {
    const maturingLoans = loansArray.filter(loan => {
        const maturityMoment = moment(loan.maturityDate);
        const today = moment();
        const daysDifference = maturityMoment.diff(today, 'days');
        const minDays = minimumDays || 0
        const maxDays = maximumDays || Infinity
        return (daysDifference >= minDays) && (daysDifference <= maxDays);
    });
  
  return maturingLoans;
}

export {loanCompleter, filterByStatus, filterByDuration, currentLoansFilter, maturityFilter}