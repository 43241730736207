import React, { useState, useContext} from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import '../../assets/custom.css'
import PointsManager from "../../components/PointsManager";
import ViewLoanStatementAsChild from "./ViewLoanStatementAsChild";
import { UserTypeContext } from "../../App";
import { emptyBorrowers } from "../../assets/loadingArrays";

const ViewLoansAndPayments = ({loans, hidePanel, updateLoans, deleteLoan, filter}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const options = { day: "2-digit", month: "short", year: "2-digit" };
  const [loanParams, setLoanParams] = useState()
  const {decimalPoints} = useContext(UserTypeContext);
  const loanRows = loans.length > 0? loans : emptyBorrowers

  const handleRowClick =(params)=>{
    if(params.id !== 'noDataAvailable'){
      hidePanel(true)
      setLoanParams(params.row)
    }
  }

  const viewLoans = (loanId) => {
    loanId && deleteLoan(loanId)
    setLoanParams(null)
    hidePanel(false)
  }

  const columnTotal = (column) => {
    return loans.reduce((acc, loan) => {      
      return acc + loan[column]
    },0)
  }
 
  const columns = [
    
    {
      field: "borrowerName", 
      headerName: "Borrower",
      flex: 1.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "principal",
      headerName: "Principal Released",
      flex: 1.2,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.principal) ? params.row.principal.toLocaleString('en-US', {
           maximumFractionDigits: decimalPoints
        })
        : 0
      }
    },
    {
      field: "startDate",
      headerName: "Date Taken",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'date',
      renderCell: (params)=>{
        return params.row.startDate ? params.row.startDate.toLocaleDateString("en-UK", options) : ''
      }
    },
    {
      field: "maturityDate",
      headerName: "Maturity Date",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      // type: 'date',
      renderCell: (params)=>{
        return params.row.maturityDate ? params.row.maturityDate.toLocaleDateString("en-UK", options) : ''
      }
    },
    {
      field: "interestRate",
      headerName: "Interest",
      align: 'center',
      headerAlign: 'center',
      flex: 0.6,
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 0.8,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.durationInterval
          ? `${params.row.duration}-${params.row.durationInterval.slice(0,1).toUpperCase()} `
          : params.row.duration
      }
    },
    {
      field: "amountDue",
      headerName: "Amount Due",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.amountDue) ? 
          params.row.amountDue.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
          : 0
      }
    },
    {
      field: "totalPaid",
      headerName: "Total Paid",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.totalPaid)
        ? params.row.totalPaid.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
        : 0
      }
    },
    {
      field: "balance",
      headerName: "Loan Balance",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
      renderCell: (params) => {
        return !isNaN(params.row.balance)
        ? params.row.balance.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
        : 0
      }
    },
    {
      field: "status",
      headerName: "Loan Status",
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if(params.row.status){
          const loanStatus = params.row.status.includes('override') 
            ? params.row.status.slice(12) + ` (OR)` : params.row.status
          return loanStatus.includes('current') 
          ? <Typography color={`${colors.greenAccent[300]}`} sx={{fontSize: '12px'}}>{loanStatus.toUpperCase()}</Typography>
          : loanStatus.includes('cleared') ?
            <Typography color={`${colors.blueAccent[300]}`} sx={{fontSize: '12px'}}>{loanStatus.toUpperCase()}</Typography>
            : loanStatus && 
            <Typography color={`${colors.redAccent[300]}`} sx={{fontSize: '12px'}}>
              {loanStatus.toUpperCase()}</Typography>
        }else return ''
      }
    },
    {
      field: "label",
      headerName: "View / Edit",
      flex: 0.6,
      renderCell: (params) => {
        return (params.row?.principal >0 ?
          <Box
            m="2px 2px"
            p="4px 10px 4px 10px"
            display="flex"
            sx={{cursor: 'pointer'}}
            // justifyContent="center"
            backgroundColor= {colors.blueAccent[500]}
            borderRadius="4px"
          >
            <ModeEditOutlineIcon fontSize="medium" />
          </Box>
          : ''
        );
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          mt: "-10px",
          ml: "20px",
          gap: "10px"
        }}
      >
        <Typography sx={{color: `${colors.greenAccent[200]} !important`,}}>TYPE A NAME TO SEARCH: </Typography>
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <Box width={'900px'}> 
      <PointsManager />
      {!loanParams && <Box mt='20px'
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            height: "50px"
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .normalLineHeight": {
            height: `200px !important`,
          },
        }}
      >
        {loans && <Box>
          <Box mb="40px" display="flex" gap="20px" flexDirection={'row'}>
            <Box sx={{width: '180px'}} gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Principal Released:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('principal').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  sx={{width: '150px'}} gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Amount Due:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('amountDue').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  sx={{width: '150px'}} gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Paid:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('totalPaid').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  sx={{width: '150px'}} gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Balance:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${columnTotal('balance').toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
            <Box  sx={{width: '200px'}} gap="10px">
              <Typography variant="h5" textAlign={"center"} color={colors.greenAccent[500]}>
                Total Interest Charged:</Typography>
              <Typography variant="h4" textAlign={"center"}>
                {` ${(columnTotal('balance') + columnTotal('totalPaid')
                - columnTotal('principal')).toLocaleString('en-US', {
                  maximumFractionDigits: decimalPoints
                })}`}
              </Typography>
            </Box>
          </Box>
          <DataGrid rows={loanRows} columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: QuickSearchToolbar }}
            initialState={{
              sorting: {
                  sortModel: [{field: 'startDate', sort: 'desc'}],
              },
              filter: {
                filterModel: {
                  items: [{ field: 'status', value: filter }],
                },
              },
            }}
            onRowClick={handleRowClick}
          />
        </Box>}
      </Box>} 
      {loanParams && <Box>
        <ViewLoanStatementAsChild loan={loanParams} viewLoans={viewLoans} 
        updateLoans={updateLoans}/>  
      </Box>}
    </Box>
  );
};

export default ViewLoansAndPayments;