function distributePayment(principal, loanDuration, monthlyInterestRate, paymentsArray, paymentSchedule, 
    durationInterval, rateInterval) {
    let monthlyInterestRateDecimal
    switch(rateInterval) {
        case 'per month': 
            monthlyInterestRateDecimal = monthlyInterestRate /100;
            break;
        case 'per year': 
            monthlyInterestRateDecimal = monthlyInterestRate /100/12;
            break;
        default: monthlyInterestRateDecimal = monthlyInterestRate /100;
    }

    let duration
    switch(durationInterval) {
        case 'months': 
            duration = loanDuration;
            break;
        case 'years': 
            duration = loanDuration /12;
            break;
        default: duration = loanDuration
    }

    let monthlyPayment
    const payments = [];
    if(paymentSchedule === 'instalments'){
        monthlyPayment = principal * monthlyInterestRateDecimal * 
        (Math.pow(1 + monthlyInterestRateDecimal, duration)) 
        / (Math.pow(1 + monthlyInterestRateDecimal, duration) - 1);
    
        //create payments schedule    
        for (let i = 0; i < duration; i++) {
            let interestPayment = principal * monthlyInterestRateDecimal;
            let principalPayment = monthlyPayment - interestPayment;
    
            principal -= principalPayment;
    
            payments.push({
                principalPayment: principalPayment,
                interestPayment: interestPayment,
            });
        }
    }else{
        for (let i = 0; i < duration; i++) {
            payments.push({
                principalPayment: principal / duration,
                interestPayment: principal * monthlyInterestRateDecimal
            })
        }
    }

    //function to return cummulated payments
    const paymentsReducer = (index, key)=>{
        let sum = 0; 
        for(let i=0; i<=index; i++){
            sum += payments[i][key]
        }
        return sum;
    }

    //aggregate the cummulated payments
    const aggegatedInstalments = [{
        index: 0,
        cummulativeInterest: payments[0].interestPayment,
        cummulativePrincipal: payments[0].principalPayment,
        cummulativeTotal: payments[0].interestPayment + payments[0].principalPayment
    }]
    for(let i=1; i<payments.length; i++){
      const aggregate = {
        index: i,
        cummulativePrincipal: paymentsReducer(i, 'principalPayment'),
        cummulativeInterest: paymentsReducer(i, 'interestPayment')
      }
      aggregate.cummulativeTotal = aggregate.cummulativeInterest + aggregate.cummulativePrincipal
      aggegatedInstalments.push(aggregate)
    }
    
    //get payments sum
    const paymentsSum = paymentsArray.reduce((acc, payment) => acc + payment.amount, 0) 

    //get relevant cummulated sum. 
    let index = 0;
    let principalAtInd =0
    let interestAtInd = 0
    for (let i = 0; i < aggegatedInstalments.length; i++) {
        index = i;
        if (aggegatedInstalments[i].cummulativeTotal > paymentsSum) {
            if(i>0){
                index = i-1
                const balance = paymentsSum - aggegatedInstalments[i-1].cummulativeTotal
                principalAtInd = Math.min(balance, payments[i].principalPayment)
                interestAtInd = Math.max(balance - payments[i].principalPayment, 0)
            }
            break;
        }
    }
    //return the principalPaid as totalPayment - cummulativeInterest
    const principalPaid = paymentsSum > aggegatedInstalments[0].cummulativePrincipal
        ? aggegatedInstalments[index].cummulativePrincipal + principalAtInd
        : paymentsSum
    let interestPaid = paymentsSum > aggegatedInstalments[0].cummulativeTotal
        ? aggegatedInstalments[index].cummulativeInterest + interestAtInd : 0
    if(index === 0){
        if(paymentsSum < aggegatedInstalments[0].cummulativeTotal){
            if(paymentsSum > aggegatedInstalments[0].cummulativePrincipal){
                interestPaid = paymentsSum - aggegatedInstalments[0].cummulativePrincipal
            }else interestPaid = 0
        }
    }
    return {principalPaid, interestPaid, paymentsSum}
}

function distributePaymentLS(principal, paymentsArray){

    const paymentsSum = paymentsArray.reduce((acc, payment) => acc + payment.amount, 0)
    const interestPaid = paymentsSum > principal ? paymentsSum - principal : 0
    const principalPaid = paymentsSum > principal ? principal : paymentsSum

    return {principalPaid, interestPaid, paymentsSum}
    
}

export {distributePayment, distributePaymentLS}
  
  