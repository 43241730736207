const moment = require('moment'); // You'll need a library like 'moment' for date calculations

const formatNumber = (stringWithCommas)=>{
  return stringWithCommas === '-' ? 0 : parseFloat(stringWithCommas.replace(/,/g, ''), 10);
}
const formatDate = (date) =>{
  const parsedDate = moment(date, "DD-MMM-YYYY");
  return parsedDate.format("YYYY-MM-DD");
}
const formatPaymentDate = (date) =>{
  const dateString = date?.toString()
  let parsedDate
  if(dateString.length < 10){
    parsedDate = moment(dateString, "DD MMM YY");
  }else{
    parsedDate = moment(dateString)
  }
  return parsedDate.format("YYYY-MM-DD");
}
const numberWithCommas = (number)=>{
  const newNumber = Math.floor(number)
  const numberString = new Intl.NumberFormat().format(newNumber)
  return (numberString !== "0" ? numberString : "-")
}
const dateAsString = (date) =>{
  const parsedDate = moment(date, "YYYY-MM-DD")
  return parsedDate.format("DD-MMM");
}
function groupInto30DayRanges(data) {
  const result = [];
  let currentBatch = [];
  let currentDate = null;
  let idCounter = 0;

  data.forEach(item => {
    const itemDate = moment(item.date);

    if (!currentDate) {
      currentDate = itemDate;
    }

    const diffDays = itemDate.diff(currentDate, 'days');
    
    if (diffDays < 30) {
      currentBatch.push(item);
    } else {
      const rangeLabel = `${currentDate.format("DDMMMYY")} to ${currentDate.clone().add(1, 'months').format("DDMMMYY")}`;
      result.push({ id: idCounter++, period: rangeLabel, transactions: currentBatch });
      
      currentDate = currentDate.clone().add(1, 'months');
      currentBatch = [item];
    }
  });

  if (currentBatch.length > 0) {
    const rangeLabel = `${currentDate.format("DDMMMYY")} to ${currentDate.clone().add(1, 'months').format("DDMMMYY")}`;
    result.push({ id: idCounter++, period: rangeLabel, transactions: currentBatch });
  }
  
  const firstPaymentDates = []
  for(let i=0; i<result.length; i++){
    for(let j=0; j<result[i].transactions.length; j++){
      if(result[i].transactions[j].description === 'Payment Made'){
        firstPaymentDates.push(result[i].transactions[j].date)
        j = result[i].transactions.length
      }
    }
  }
  
  // console.log(firstPaymentDates); 

  return [result, firstPaymentDates];

}

const createLoanStatement = (loanAmount, loanStartDate, loanInterestRate, paymentData, 
  duration, repaymentCycle, lateInterestBySystem, penaltiesData,
  rateInterval, durationInterval, dateAsObject) => {
  const principal = typeof loanAmount == 'string' ? formatNumber(loanAmount): parseFloat(loanAmount);
  const startDate = formatDate(loanStartDate)
  const interestRate = loanInterestRate/100;
  const today = moment();
  const endDate = moment(startDate).add(duration, durationInterval);

  const interestInterval = ()=>{
    switch(rateInterval){
        case 'per day':
            return 'days';
            break;
        case 'per week':
            return 'weeks';
            break;
        case 'per month':
            return 'months';
            break;
        case 'per year':
            return 'years';
            break;
        default: 
            return 'months';
    }
  }
  //format the payments array
  const payments = paymentData.map((item)=>({
    id: item.id,
    date: formatPaymentDate(item.paymentDate),
    amount: item.amount || item.paymentAmount
  }))
  const penalties = penaltiesData.map((item)=>({
    id: item.id,
    date: formatPaymentDate(item.penaltyDate),
    amount: item.amount || item.penaltyAmount,
    description: item.comment
  }))
  //Get total of payments
  const totalPayments = numberWithCommas(payments.reduce((acc, obj) => acc + obj.amount, 0))
  //check if loan is still current
  const isLoanCurrent = ()=>{
    return (endDate.isAfter(today));
  }
  const loanStillCurrent = isLoanCurrent()

  let loanStatus = loanStillCurrent ? 'current' : 'overdue'

  const interestRatePeriod = interestInterval()

  //create loan schedule dates if loan is still current
  const datesForCurrentLoan = () =>{
    const transactionDates = [];
    let currentDate = moment(startDate);
    const endDate = moment(startDate).add(duration, durationInterval);
    while(currentDate.isBefore(endDate)){
      transactionDates.push({ 
        id: Math.random() * 10000000,
        date: currentDate.format("YYYY-MM-DD"),
        description: `Interest of ${(interestRate*100).toLocaleString()}% ${rateInterval}`
      });
      currentDate.add(1, interestRatePeriod)
    }
    transactionDates.shift();
    return transactionDates
  }
  //create the dates if loan is overdue 
  const datesForOverDueLoan = (beginDate)=>{
      const transactionDates = [];
      let currentDate = moment(beginDate);
      const endDate = moment(startDate).add(duration, durationInterval);
      while(currentDate.isBefore(endDate)){
        transactionDates.push({ 
          id: Math.random() * 10000000,
          date: currentDate.format("YYYY-MM-DD"),
          description: `Interest of ${(interestRate*100).toLocaleString()}% ${rateInterval}`
        });
        currentDate.add(1, interestRatePeriod)
      }      
      
      const pastDate = moment(startDate).add(duration, interestRatePeriod) 
      const today = moment()
      while (pastDate.isBefore(today)) {
        lateInterestBySystem && transactionDates.push({ 
          id: Math.random() * 1777,
          date: pastDate.format('YYYY-MM-DD'),
          description: `LOAN OVERDUE! Interest ${(interestRate*100).toLocaleString()}% ${rateInterval}`
        });
        pastDate.add(1, interestRatePeriod);
      }
     
      transactionDates.shift();
      return (transactionDates)
  }
  //create dates depending on loan status. 
  const transactionDates = loanStillCurrent ? datesForCurrentLoan(startDate) : datesForOverDueLoan(startDate)

  //special case for simpleLumpsum loans with per loan interest frequency
  const datesForPerLoanInterest = ()=>{
    const transactionDates = []
    const endDate = moment(startDate).add(duration, durationInterval);
    transactionDates.push({
      id: Math.random(),
      date: endDate.format('YYYY-MM-DD'),
      description: "Loan Maturity Date Reached"
    })
    return transactionDates
  }

  const finalTransactionDates = rateInterval === 'per loan'
    ? datesForPerLoanInterest() : transactionDates

  //merge the payment dates and interest dates into 1 array and sort it. 
  const allTransactions = payments.concat(finalTransactionDates).concat(penalties)
    .sort((a, b) => moment(a.date).diff(moment(b.date)));
  const firstTransaction = {
      id: 657,
      date: startDate, 
      principal: principal,
      paymentAmount: 0,
      description: repaymentCycle === 'simpleLumpSum' ?
        `Loan taken at ${(interestRate*100).toLocaleString()}% ${rateInterval} Interest (Simple Interest)` 
        : `Loan taken at ${(interestRate*100).toLocaleString()}% ${rateInterval} Interest`,
  };
  firstTransaction.interestAmount = firstTransaction.principal * interestRate
  firstTransaction.newPrincipal = firstTransaction.principal + firstTransaction.interestAmount - firstTransaction.paymentAmount;
  // put the first transaction into the array
  allTransactions.unshift(firstTransaction);
  const statement = [];
  //modification for simple lumpsum loans
  statement.push(firstTransaction);
  
  //put all transactions into the array while calculating any changes. 
  for(let i = 1; i<allTransactions.length; i++){
    let entry = {
        id: allTransactions[i].id,
        date: allTransactions[i].date, 
      };
    entry.principal = statement[i-1].newPrincipal;

    
    if (payments.includes(allTransactions[i])) {
        entry.paymentAmount = allTransactions[i].amount
        entry.interestAmount = 0;
        entry.description = 'Payment Made'
    } else {
      if(repaymentCycle === 'simpleLumpSum'){
        entry.paymentAmount = 0
        entry.interestAmount = principal * interestRate
        entry.description = allTransactions[i].description
        if(rateInterval === 'per loan'){
          entry.interestAmount = 0
        }
      } else {
        entry.paymentAmount = 0
        entry.interestAmount = entry.principal * interestRate
        entry.description = allTransactions[i].description
      }
    }
    if (penalties.includes(allTransactions[i])) {
      entry.paymentAmount = 0
      entry.interestAmount = allTransactions[i].amount;
      entry.description = allTransactions[i].description
    }
    entry.newPrincipal = entry.principal + entry.interestAmount - entry.paymentAmount;
    statement.push(entry)
  }
  if(statement[statement.length - 1].newPrincipal <= 0.1){
    loanStatus = 'cleared'
  }

  //find the balance as of today
  function getAmountDue() {
    let amountDue = 0;
    statement.forEach(item => {
      const runningDate = moment(item.date)
      if (runningDate.isSameOrBefore(today)) {
        amountDue = item.newPrincipal;
      }
    });
    return amountDue;
  }

  //return dates and numbers to coomma and DD-MM-YYYY format
  const loanStatement = statement.map((item)=>({
    id: item.id,
    date: dateAsObject ? new Date(item.date)
      : moment(item.date, "YYYY-MM-DD").format("DDMMMYY"),
    description: item.description, 
    principal: numberWithCommas(item.principal),
    paymentAmount: `${numberWithCommas(item.paymentAmount)}`,
    interestAmount: numberWithCommas(item.interestAmount),
    newPrincipal: numberWithCommas(item.newPrincipal)
  }))

  const periodStatement = groupInto30DayRanges(loanStatement)

  const weeklyPaymentDates = periodStatement[1]

  const headingForPeriods = {
    id: 5000, period: "#",
    transactions: [{
      id: 50001,
      date: "DATE",
      description: "DESCRIPTION",
      principal: "PRINCIPAL",
      paymentAmount: "PAYMENTS MADE",
      interestAmount: "INTEREST",
      newPrincipal: "NEW PRINCIPAL"
    }]
  }
  periodStatement[0].unshift(headingForPeriods)
  //add 2 bottom rows for printing visibility
  for (let br =0; br <2; br++){
    let bottomRow = {
      id: 71000+br, period: "",
      transactions: [{
        id: 710000+br,
        date: "",
        description: "",
        principal: "",
        paymentAmount: "",
        interestAmount: "",
        newPrincipal: ""
      }]
    }
    periodStatement[0].push(bottomRow)
  }
  const maturityDate = moment(startDate).add(duration, durationInterval);
  const presentDate = moment()
  const daysLate = presentDate.diff(maturityDate, 'days');

  return([loanStatement, totalPayments, periodStatement[0], 
    maturityDate.format('DD-MMM-YYYY'), daysLate, loanStatus, getAmountDue()]);
}

const createLoanStatementDG = (loanAmount, loanStartDate, loanInterestRate, paymentData, 
  duration, repaymentCycle, lateInterestBySystem, penaltiesData) => {
  const principal = typeof loanAmount == 'string' ? formatNumber(loanAmount): parseFloat(loanAmount);
  const startDate = formatDate(loanStartDate)
  const interestRate = loanInterestRate/100;
  const today = moment();
  const endDate = moment(startDate).add(duration, 'months');
  //format the payments array
  const payments = paymentData.map((item)=>({
    id: item.id,
    date: formatPaymentDate(item.paymentDate),
    amount: item.amount || item.paymentAmount
  }))
  const penalties = penaltiesData.map((item)=>({
    id: item.id,
    date: formatPaymentDate(item.penaltyDate),
    amount: item.amount || item.penaltyAmount,
    description: item.comment
  }))
  //Get total of payments
  const totalPayments = numberWithCommas(payments.reduce((acc, obj) => acc + obj.amount, 0))
  //check if loan is still current
  const isLoanCurrent = ()=>{
    return (endDate.isAfter(today));
  }
  const loanStillCurrent = isLoanCurrent()

  let loanStatus = loanStillCurrent ? 'current' : 'overdue'

  //create loan schedule dates if loan is still current
  const datesForCurrentLoan = (beginDate, durationGiven) =>{
    const transactionDates = [];
    for (let i = 0; i < durationGiven; i++) {
      const currentDate = moment(beginDate).add(i, 'months');
      transactionDates.push({ 
        id: Math.random() * 10000000,
        date: currentDate.format("YYYY-MM-DD"),
        description: `Monthly interest of ${(interestRate*100).toLocaleString()}%`
      });
    }
    transactionDates.shift();
    
    return transactionDates
  }
  //create the dates if loan is overdue 
  const datesForOverDueLoan = (beginDate, durationGiven)=>{
      const transactionDates = [];
      for (let i = 0; i < durationGiven; i++) {
        const currentDate = moment(beginDate).add(i * 1, 'months');
        transactionDates.push({ 
          id: Math.random() * 10000000,
          date: currentDate.format("YYYY-MM-DD"),
          description: `Monthly interest of ${(interestRate*100).toLocaleString()}%`
        });
      }
      
      const currentDate = moment(startDate).add(duration, 'months') 
      const today = moment()
      while (currentDate.isBefore(today)) {
        lateInterestBySystem && transactionDates.push({ 
          id: Math.random() * 1777,
          date: currentDate.format('YYYY-MM-DD'),
          description: `LOAN OVERDUE! Monthly Interest ${(interestRate*100).toLocaleString()}%`
        });
        currentDate.add(1, 'months');
      }
     
      transactionDates.shift();
      return (transactionDates)
  }
  //create dates depending on loan status. 
  const transactionDates = loanStillCurrent ? datesForCurrentLoan(startDate, duration) : datesForOverDueLoan(startDate, duration)
  //merge the payment dates and interest dates into 1 array and sort it. 
  const allTransactions = payments.concat(transactionDates).concat(penalties)
    .sort((a, b) => moment(a.date).diff(moment(b.date)));
  const firstTransaction = {
      id: 657,
      date: startDate, 
      principal: principal,
      paymentAmount: 0,
      description: repaymentCycle === 'simpleLumpSum' ?
        `Loan taken at ${(interestRate*100).toLocaleString()}% Monthly Interest (Simple Interest)` 
        : `Loan taken at ${(interestRate*100).toLocaleString()}% Monthly Interest`,
  };
  firstTransaction.interestAmount = firstTransaction.principal * interestRate
  firstTransaction.newPrincipal = firstTransaction.principal + firstTransaction.interestAmount - firstTransaction.paymentAmount;
  // put the first transaction into the array
  allTransactions.unshift(firstTransaction);
  const statement = [];
  //modification for simple lumpsum loans
  statement.push(firstTransaction);
  
  //put all transactions into the array while calculating any changes. 
  for(let i = 1; i<allTransactions.length; i++){
    let entry = {
        id: allTransactions[i].id,
        date: allTransactions[i].date, 
      };
    entry.principal = statement[i-1].newPrincipal;
    
    if (payments.includes(allTransactions[i])) {
        entry.paymentAmount = allTransactions[i].amount
        entry.interestAmount = 0;
        entry.description = 'Payment Made'
    } else {
      if(repaymentCycle === 'simpleLumpSum'){
        entry.paymentAmount = 0
        entry.interestAmount = principal * interestRate
        entry.description = allTransactions[i].description
      } else {
        entry.paymentAmount = 0
        entry.interestAmount = entry.principal * interestRate
        entry.description = allTransactions[i].description
      }
    }
    if (penalties.includes(allTransactions[i])) {
      entry.paymentAmount = 0
      entry.interestAmount = allTransactions[i].amount;
      entry.description = allTransactions[i].description
    }
    entry.newPrincipal = entry.principal + entry.interestAmount - entry.paymentAmount;
    statement.push(entry)
  }
  if(statement[statement.length - 1].newPrincipal <= 0){
    loanStatus = 'cleared'
  }

  //find the balance as of today
  function getAmountDue() {
    let amountDue = 0;
    statement.forEach(item => {
      const runningDate = moment(item.date)
      if (runningDate.isSameOrBefore(today)) {
        amountDue = item.newPrincipal;
      }
    });
    return amountDue;
  }

  //return dates and numbers to coomma and DD-MM-YYYY format
  const loanStatement = statement.map((item)=>({
    id: item.id,
    date: new Date(item.date),
    description: item.description, 
    principal: numberWithCommas(item.principal),
    paymentAmount: `${numberWithCommas(item.paymentAmount)}`,
    interestAmount: numberWithCommas(item.interestAmount),
    newPrincipal: numberWithCommas(item.newPrincipal)
  }))

  const periodStatement = groupInto30DayRanges(loanStatement)

  const weeklyPaymentDates = periodStatement[1]

  const headingForPeriods = {
    id: 5000, period: "#",
    transactions: [{
      id: 50001,
      date: "DATE",
      description: "DESCRIPTION",
      principal: "PRINCIPAL",
      paymentAmount: "PAYMENTS MADE",
      interestAmount: "INTEREST",
      newPrincipal: "NEW PRINCIPAL"
    }]
  }
  periodStatement[0].unshift(headingForPeriods)
  //add 2 bottom rows for printing visibility
  for (let br =0; br <2; br++){
    let bottomRow = {
      id: 71000+br, period: "",
      transactions: [{
        id: 710000+br,
        date: "",
        description: "",
        principal: "",
        paymentAmount: "",
        interestAmount: "",
        newPrincipal: ""
      }]
    }
    periodStatement[0].push(bottomRow)
  }
  const maturityDate = moment(startDate).add(duration, 'months');
  const presentDate = moment()
  const daysLate = presentDate.diff(maturityDate, 'days');

  return([loanStatement, totalPayments, periodStatement[0], 
    maturityDate.format('DD-MMM-YYYY'), daysLate, loanStatus, getAmountDue()]);
}

function printInLandscape() {
  // Apply landscape CSS rules
  const style = document.createElement('style');
  style.textContent = '@page { size: landscape; }';
  document.head.appendChild(style);
  
  // Trigger print
  window.print();
  
  // Remove the added style after printing
  style.remove();
}
export {createLoanStatement, printInLandscape, formatNumber, createLoanStatementDG}

