import React from 'react'
import { tokens } from '../../theme';
import { useTheme, Box, Typography, Button } from '@mui/material';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { LoansContext} from '../../App';
import DashboardLoans from '../DashBoardComponents/DashboardLoans';
import moment from 'moment';
import { currentLoansFilter, filterByStatus } from '../../assets/loanPanelCalculations';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BasicPie from '../../components/CustomPieChart';
import AllLoans from './AllLoans';
import YouTubeIcon from '@mui/icons-material/YouTube';

function PerformanceSummary() {
  const {loansContext} = useContext(LoansContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

//states

//for loans this month:
  const startDate = moment().startOf('month').format('YYYY-MM-DD')
  const endDate = moment().format('YYYY-MM-DD')
  const loansThisMonth = loansContext.filter((loan) => { 
    return moment(loan.startDate).isSameOrAfter(moment(startDate))
        && moment(loan.startDate).isSameOrBefore(moment(endDate))
  })

//for overdue loans
  const overdueLoans = filterByStatus(loansContext, 'overdue')

//current with missed payments
const currentWithMissedPayments = filterByStatus(loansContext, 'payment due')
const currentLoans = filterByStatus(loansContext, 'current')

//due this week
const dueThisWeek = currentLoansFilter(filterByStatus(loansContext, 'current'), 0, 7)

//pie chart data
const columnTotal = (loans) => {
  return loans.reduce((acc, loan) => {      
  return acc + loan['balance']
  },0)
}
const totalBalance = columnTotal(loansContext)
const totalCurrentLoans = columnTotal(currentLoans)
const totalOverdueLoans = columnTotal(overdueLoans)
const totalCurrentWithMissedPayments = columnTotal(currentWithMissedPayments)

const pieData =[
  {id: 1, value: totalCurrentLoans, label: `CURRENT ${(100*totalCurrentLoans/totalBalance).toFixed(0)}%`},
  {id: 2, value: totalOverdueLoans, label: `OVERDUE ${(100*totalOverdueLoans/totalBalance).toFixed(0)}%`},
  {id: 3, value: totalCurrentWithMissedPayments, 
    label: `MISSED PAYMENTS ${(100*totalCurrentWithMissedPayments/totalBalance).toFixed(0)}%`},
]

  return (
    <Box  mb="80px" sx={{justifyContent: 'center', display: 'flex'}}>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {loansContext && 
          <Box mt="20px">
              <Box  display="flex" flexDirection={'row'} 
                sx={{gap: {xs: '20px', md: "40px"}}}
                mb="30px" justifyContent={'center'} flexWrap={'wrap'}>
                <Button color='warning' variant='contained' startIcon={<YouTubeIcon/>}
                  onClick={()=> window.open('https://www.loantabs.com/video-tutorials/', '_blank')}
                  >VIDEO TUTORIALS</Button>
                <Button color='info' variant='outlined' startIcon={<AddCircleOutlineIcon/>}
                  onClick={()=> navigate('/newLoan')}>ADD NEW LOAN</Button>
                <Button color='success' variant='outlined' startIcon={<ReceiptOutlinedIcon/>}
                  onClick={()=>navigate('/loansPanel')}>All Loans</Button>
                <Button color='warning' variant='outlined' startIcon={<AssessmentIcon/>}
                  onClick={()=>navigate('/reportsPanel')}>Reports</Button>
              </Box>
              <Typography variant="h6">ALL LOANS</Typography>
              <Box display="flex" flexDirection={'row'} width="100%" mb="20px"
                justifyContent={'space-between'}
                sx={{p: {xs: '8px', md:"15px"}, border: ' 1px solid', flexWrap: 'wrap',
                  borderColor: colors.greenAccent[300]}}>
                <Box sx={{width: {xs: '100%', md: "47%"}}}>
                  <AllLoans title={'ALL LOANS'}
                  totalCurrentLoans={totalCurrentLoans} totalOverdueLoans={totalOverdueLoans}
                  totalCurrentWithMissedPayments={totalCurrentWithMissedPayments}/>
                </Box>
                <Box sx={{width: {xs: '100%', md: "47%"}}}>
                  {pieData && <BasicPie data={pieData}/>}
                </Box>
              </Box>
              <Box display="flex" flexDirection={'row'} width="100%" 
                sx={{justifyContent: {md: 'space-between'}}}
                flexWrap={'wrap'}>
                <Box sx={{width: {xs: '100%', md: "48%"}, mt: '10px'}}>
                  <DashboardLoans title={'LOANS TAKEN THIS MONTH'} loans={loansThisMonth}/>
                </Box>
                <Box 
                  sx={{width: {xs: '100%', md: "48%"}, mt: '10px'}}
                  >
                  <DashboardLoans title={'OVERDUE LOANS (PAST MATURITY)'} loans={overdueLoans}/>
                </Box>
              </Box>
              <Box display="flex" flexDirection={'row'} width="100%" 
                sx={{justifyContent: {md: 'space-between'}}}
                flexWrap={'wrap'}>
                <Box sx={{width: {xs: '100%', md: "48%"}, mt: '10px'}}>
                  <DashboardLoans title={'CURRENT LOANS WITH MISSED PAYMENTS'} loans={currentWithMissedPayments}/>
                </Box>
                <Box sx={{width: {xs: '100%', md: "48%"}, mt: '10px'}}>
                  <DashboardLoans title={'LOANS WITH PAYMENT DUE THIS WEEK'} loans={dueThisWeek}/>
                </Box>
              </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default PerformanceSummary