import React, { useState } from 'react'
import { tokens } from '../../theme';
import { useTheme, Box, Typography, Button} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { LoansContext} from '../../App';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

function QuickLinks() {
  const {loansContext} = useContext(LoansContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

  //states
  const [quickStartGuide, setQuickStartGuide] = useState()

  return (
    <Box mb="80px" sx={{justifyContent: 'center', display: 'flex'}}>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        
        {loansContext && <Box>
          <Box backgroundColor={'#081329'} p="15px" mt="20px">
            <Box display="flex" flexDirection={'row'} gap={'30px'} flexWrap={'wrap'}
              alignItems={'center'} justifyContent={'space-between'}>
              <Typography>GUIDES</Typography>
              <Box display="flex" flexDirection="row"
                sx={{gap: {xs: '20px', md: '40px'}}} flexWrap={'wrap'}>
                {!quickStartGuide && <Button variant="outlined" color={'info'} startIcon={<HelpOutlineOutlinedIcon />}
                  sx={{width: "250px"}} onClick={()=>setQuickStartGuide(true)} 
                  size="large">QUICK START GUIDE</Button>}
                {quickStartGuide && <Button variant="outlined" color={'warning'} 
                  sx={{width: "250px"}} onClick={()=>setQuickStartGuide(false)} 
                  size="large">Hide Guide </Button>}
                <Button variant="outlined" color={'success'} startIcon={<OndemandVideoIcon />}
                  sx={{width: "250px"}} onClick={()=>window.open('https://www.loantabs.com/video-tutorials/', '_blank')} 
                  size="large">VIDEO TUTORIALS</Button>
              </Box>
            </Box>
            {quickStartGuide && <Box mt="20px"
              sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <Typography sx={{color: colors.grey[200]}}>
                Step 1: Click "Add New Borrower" to create a borrower. Create as many borrowers as you need.</Typography>
                <Typography sx={{color: colors.grey[200]}}>
                Step 2: Click "Add New Loan" to create a loan for any of your borrowers</Typography>
                <Typography sx={{color: colors.grey[200]}}>
                Step 3: After creating loans, you can add payments, view statements etc.</Typography>
            </Box>}
          </Box>
          <Box backgroundColor={'#081329'} p="15px" mt="20px" >
            <Box display="flex" flexDirection={'row'} gap={'30px'} flexWrap={'wrap'}
              alignItems={'center'} justifyContent={'space-between'}>
              <Typography>BORROWERS</Typography>
              <Box display="flex" flexDirection="row"
                sx={{gap: {xs: '20px', md: '40px'}}} flexWrap={'wrap'}>
                <Button variant="outlined" color={'info'} startIcon={<PersonAddIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/createBorrower')} 
                  size="large">ADD NEW BORROWER</Button>
                <Button variant="outlined" color={'success'} startIcon={<PeopleOutlinedIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/manageBorrowers')} 
                  size="large">View & Edit Borrowers</Button>
              </Box>
            </Box>
          </Box>
          <Box backgroundColor={'#081329'} p="15px" mt="20px" >
            <Box display="flex" flexDirection={'row'} gap={'30px'} flexWrap={'wrap'}
              alignItems={'center'} justifyContent={'space-between'}>
              <Typography>LOANS</Typography>
              <Box display="flex" flexDirection="row"
                sx={{gap: {xs: '20px', md: '40px'}}} flexWrap={'wrap'}>
                <Button variant="outlined" color={'info'} startIcon={<AddCircleOutlineIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/newLoan')} 
                  size="large">ADD NEW LOAN</Button>
                <Button variant="outlined" color={'success'} startIcon={<ReceiptOutlinedIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/loansPanel')} 
                  size="large">ALL LOANS</Button>
              </Box>
            </Box>
          </Box>
          <Box backgroundColor={'#081329'} p="15px" mt="20px" >
            <Box display="flex" flexDirection={'row'} gap={'30px'} flexWrap={'wrap'}
              alignItems={'center'} justifyContent={'space-between'}>
              <Typography>ACCOUNTS</Typography>
              <Box display="flex" flexDirection="row"
                sx={{gap: {xs: '20px', md: '40px'}}} flexWrap={'wrap'}>
                <Button variant="outlined" color={'info'} startIcon={<LocalAtmIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/createAccount')}
                  size="large">ADD NEW ACCOUNT</Button>
                <Button variant="outlined" color={'success'} startIcon={<CurrencyExchangeIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/manageAccounts')}
                  size="large">MANAGE ACCOUNTS</Button>
              </Box>
            </Box>
          </Box>
          <Box backgroundColor={'#081329'} p="15px" mt="20px" >
            <Box display="flex" flexDirection={'row'} gap={'30px'} flexWrap={'wrap'}
              alignItems={'center'} justifyContent={'space-between'}>
              <Typography>TEAM</Typography>
              <Box display="flex" flexDirection="row"
                sx={{gap: {xs: '20px', md: '40px'}}} flexWrap={'wrap'}>
                <Button variant="outlined" color={'info'} startIcon={<GroupAddIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/createUserAdmin')}
                  size="large">ADD NEW TEAM MEMBER</Button>
                <Button variant="outlined" color={'success'} startIcon={<Diversity3Icon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/manageUserAdmins')}
                  size="large">MANAGE TEAM</Button>
              </Box>
            </Box>
          </Box>
          <Box backgroundColor={'#081329'} p="15px" mt="20px" >
            <Box display="flex" flexDirection={'row'} gap={'30px'} flexWrap={'wrap'}
              alignItems={'center'} justifyContent={'space-between'}>
              <Typography>REPORTS & SUPPORT</Typography>
              <Box display="flex" flexDirection="row"
                sx={{gap: {xs: '20px', md: '40px'}}} flexWrap={'wrap'}>
                <Button variant="outlined" color={'info'} startIcon={<AssessmentIcon />}
                  sx={{width: "250px"}} onClick={()=>navigate('/reportsPanel')}
                  size="large">RUN REPORTS</Button>
                <Button variant="outlined" color={'success'} startIcon={<HeadsetMicIcon />}
                  sx={{width: "250px"}} onClick={()=> window.open('https://www.loantabs.com/support', '_blank')}
                  size="large">GET SUPPORT</Button>
              </Box>
            </Box>
          </Box>
        </Box>}
      </Box>
    </Box>
  )
}

export default QuickLinks