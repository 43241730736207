const tenPercentWeeklyRate = 1.02411368908445

const removeCommas = (stringWithCommas)=>{
  const numberWithoutCommas = parseFloat(stringWithCommas.replace(/,/g, ''), 10);
  return numberWithoutCommas
}

const scheduleLoan = (loanAmount, interestRate, duration, startDate, 
  rateFormat, durationFormat, repaymentCycle, decimalDigits)=>{
  
  const decimalPoints = decimalDigits || 0
  var timeline = rateFormat === 'per month' ? 1: 12; 
  const monthlyInterestRate = interestRate / 100 / timeline ;
  var durationInMonthsOrYears = durationFormat === 'months'? 1: 12
  var loanDurationMonths = duration * durationInMonthsOrYears;
  var maxPeriod = false
  const startDateUnformated = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()+1)
  const startDateFormated = formatDate(startDateUnformated.toISOString().slice(0, 10))

  if (loanDurationMonths > 96) {
    loanDurationMonths = 96;
    maxPeriod = true
  }

  if(repaymentCycle === 'simpleLumpSum') {
    const endDate = new Date(startDate)
    if(durationFormat === 'months'){
      endDate.setMonth(endDate.getMonth() + duration)
    }else{
      endDate.setFullYear(endDate.getFullYear() + duration)
    }
    const endDateUnformated = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()+1)
    const endDateFormated = formatDate(endDateUnformated.toISOString().slice(0, 10))
    const interestOnLoan = loanAmount * monthlyInterestRate * loanDurationMonths
    const instalment = [{
      id: 1, 
      date: endDateFormated,
      principal: formatNumber(loanAmount.toFixed(decimalPoints)),
      amountToPay: formatNumber((loanAmount + interestOnLoan).toFixed(decimalPoints)),
      interest: formatNumber(interestOnLoan.toFixed(decimalPoints))
    }]
    return [instalment, loanDurationMonths, rateFormat, startDateFormated, maxPeriod, interestOnLoan]; 
  }

  const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -loanDurationMonths));
  const installmentDetails = [];

  let currentBalance = loanAmount;
  for (let i = 1; i <= loanDurationMonths; i++) {
    const interestPayment = currentBalance * monthlyInterestRate;
    const principalPayment = monthlyPayment - interestPayment;
    const installmentDate = new Date(startDate.getFullYear(), startDate.getMonth() + i, startDate.getDate());

    const installment = {
      id: i,
      date: formatDate(installmentDate.toISOString().slice(0, 10)),
      principal: formatNumber(principalPayment.toFixed(decimalPoints)),
      interest: formatNumber(interestPayment.toFixed(decimalPoints)),
      balance: formatNumber(currentBalance.toFixed(decimalPoints)),
      totalPayment: formatNumber(parseFloat(interestPayment.toFixed(decimalPoints)) + parseFloat(principalPayment.toFixed(decimalPoints)))
    };

    installmentDetails.push(installment);

    currentBalance -= principalPayment;
  }

  if(repaymentCycle === 'lumpSum'){
      
      const installments = [];
      let newInterest = loanAmount * interestRate /100
      let amountToPay = loanAmount + newInterest;
      let principal = amountToPay - newInterest; 
      let currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
      
      for (let i = 0; i < duration; i++) {
        const installment = {
          id: i+1,
          date: formatDate(currentDate.toISOString().slice(0, 10)),
          principal: formatNumber(principal.toFixed(decimalPoints)),
          amountToPay: formatNumber(amountToPay.toFixed(decimalPoints)),
          interest: formatNumber(newInterest.toFixed(decimalPoints))
        };
        
        currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + i+1, startDate.getDate());
        installments.push(installment);
        console.log('installment::: ', installment);
        newInterest = amountToPay * interestRate /100
        amountToPay += amountToPay * interestRate /100;
        currentDate.setMonth(currentDate.getMonth() + 1);
        principal = amountToPay - newInterest; 
      }
      return [installments, loanDurationMonths, rateFormat, startDateFormated, maxPeriod,
        removeCommas(installments[installments.length - 1].amountToPay) - loanAmount];
  }

  return [installmentDetails, loanDurationMonths, rateFormat, startDateFormated, maxPeriod,
    (monthlyPayment * loanDurationMonths) - loanAmount];
}

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const monthName = monthNames[date.getMonth()];
  const formattedDate = `${day}-${monthName}-${year}`;
  return formattedDate;
}

const formatGraphQLDate = (dateString)=>{
  const date = new Date(dateString);
  // const options = { day: "2-digit", month: "short", year: "2-digit" };
  // const formattedDate = date.toLocaleDateString("en-UK", options);
  return date;
}

const formatNumber = (number)=>{
  // const newNumber = Math.floor(number)
  return new Intl.NumberFormat().format(number)
}

function calculateAmortizedLoan(startDate, loanAmount, interestRate, durationMonths, 
  repaymentCycle, decimalDigits) {

  const decimalPoints = decimalDigits || 0
  const monthlyInterestRate = interestRate / 100;
  const totalPayments = durationMonths;
  const monthlyPayment = loanAmount * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments)));
  const amortizedLoan = [];
  const summaryObject = []

  let currentDate = new Date(startDate);

  if(repaymentCycle === 'lumpSum'){
    const interestFactor = 1 + (interestRate / 100);
    const compoundedAmount = loanAmount * Math.pow(interestFactor, durationMonths);
    currentDate.setMonth(currentDate.getMonth() + durationMonths);
    const summaryObj = {
      date: currentDate.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" }),
      monthlyPayment: compoundedAmount
    }
    summaryObject.push(summaryObj)
    return summaryObject;
  }

  for (let i = 0; i < totalPayments; i++) {
    const interestPayment = loanAmount * monthlyInterestRate;
    const principalPayment = monthlyPayment - interestPayment;

    const summaryObj = {
      date: currentDate.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" }),
      monthlyPayment: monthlyPayment
    }

    const paymentObj = {
      paymentNumber: i + 1,
      date: currentDate.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" }),
      principalPayment: principalPayment.toFixed(decimalPoints),
      interestPayment: interestPayment.toFixed(decimalPoints),
      remainingBalance: (loanAmount - principalPayment).toFixed(decimalPoints),
      monthlyPayment: monthlyPayment
    };
    summaryObject.push(summaryObj)

    amortizedLoan.push(paymentObj);

    currentDate.setMonth(currentDate.getMonth() + 1);
    loanAmount -= principalPayment;
  }

  return summaryObject;
}

export {scheduleLoan, formatNumber, formatGraphQLDate, calculateAmortizedLoan};

