import { useNavigate } from 'react-router-dom'
import Header from "../../components/Header"
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { DatePickerField } from '../../components/DatePicker';
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
import "../../assets/custom.css";
import { tokens } from "../../theme";
import PointsManager from '../../components/PointsManager'
import AlertMessage from "../../components/AlertMessage";
import { numberRegex } from '../../assets/currenciesObj';
import moment from "moment";

const EditLoanDetails = ({loanId, cancelLoanEdit, updateLoanDetails, initialDetails}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [maxPeriod, setMaxPeriod] = useState()
    const [processing, setProcessing] = useState('Update Loan Details') 
    const [loanEditDone, setLoanEditDone] = useState()
    const ref = useRef(null);
    const topref = useRef()
    const navigate = useNavigate();
    const [theDate, setTheDate] = useState(initialDetails.startDate)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const initialValues = {
      principal: initialDetails.principal ||'',
      fees: initialDetails.fees || '',
      interestRate: initialDetails.interestRate || '',
      startDate: initialDetails.startDate,
      duration: initialDetails.duration || '', 
      rateFormat: initialDetails.rateFormat || 'per month',
      durationFormat: initialDetails.durationFormat || 'months',
      toggle: initialDetails.fees || false,
      repaymentCycle: initialDetails.repaymentCycle || 'instalment',
    };
    
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'
    
    const handleFormSubmit = (values) => {
      values.startDate = new Date(values.startDate?.target?.value)
      const newLoanDetails = {
        principal: parseFloat(values.principal.replace(/,/g, '')),
        fees: values.fees===''? 0 : parseFloat(values.fees.replace(/,/g, '')),
        interestRate: parseFloat(values.interestRate),
        startDate: values.startDate,
        duration: parseFloat(values.duration),
        rateInterval: values.rateFormat,
        durationInterval: values.durationFormat,
        repaymentCycle: values.repaymentCycle
      };
      setProcessing('Processing... please wait')
      updateLoan(newLoanDetails)  
      console.log('newLoanDetails::: ', newLoanDetails.startDate);
    };

    const updateLoan =  async(newLoanDetails) =>{
        const {principal, fees, interestRate, startDate, duration, rateInterval,
          durationInterval, repaymentCycle} = newLoanDetails
      try{
        // eslint-disable-next-line
        const updatedLoan = await API.graphql(graphqlOperation(`mutation MyMutation {
          updateLoan(input: {
            id: "${loanId}", 
            fees: ${fees}, 
            interestRate: ${interestRate}, 
            principal: ${principal}, 
            startDate: "${startDate}"
            duration: ${duration}
            durationInterval: "${durationInterval}"
            rateInterval: "${rateInterval}"  
            laonType: "${repaymentCycle}"  
          }) {
            id
          }
        }
        `))     
        if(updatedLoan){
          setLoanEditDone(updatedLoan)
          newLoanDetails.interestRate =  newLoanDetails.interestRate + "%"
          updateLoanDetails(newLoanDetails)
        }
      }
      catch(e){
        console.log('Loan update Failed', e)
      }
    }
  
    return (
      <Box>
        <PointsManager />
        {loanEditDone && <Box m="60px">
            <Header title={'LOAN SUCCESSFULLY UPDATED'} />
          </Box>}
        {!loanEditDone && <Box mt="20px">
            <Box className='printNot'>
              <Box ref={topref}>
                <Header title="Edit the Loan Details" />
              </Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
        
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                    <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Update the amount being borrowed"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.principal.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        name="principal"
                        error={!!touched.principal && !!errors.principal}
                        helperText={touched.principal && errors.principal}
                        sx={{ gridColumn: "span 3" }}
                      />
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Loan Interest %"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.interestRate}
                        name="interestRate"
                        error={!!touched.interestRate && !!errors.interestRate}
                        helperText={touched.interestRate && errors.interestRate}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <Box display='flex' gap="20px">
                        <Field
                          as="select"
                          name="rateFormat"
                          onChange={handleChange}
                        >
                          <option value="per month">Per Month</option>
                          <option value="per year">Per Year</option>
                        </Field>
                      </Box>
                      <Box role="group" aria-labelledby="my-radio-group" 
                        sx={{ gridColumn: "span 3" }} >
                        <Typography sx={{color: colors.greenAccent[200], 
                          marginBottom: "10px", fontSize: '17px'}}>Loan Repayment Cycle </Typography>
                        <Box gap="10px" sx={{display: 'flex', flexDirection: 'column', }}>
                          <Box sx={{ }}>
                            <label>
                              <Field type="radio" name="repaymentCycle" value="instalments" />
                              {`Equal Instalments - Reducing Balance`}
                            </label>
                            <AlertMessage buttonText={'Explain more'}
                            // message={`The interest is calculated on the unpaid balance.\n
                            // The payments are distributed into equal instalments.`}
                            message={<Box>
                              <Typography>The interest is calculated on the unpaid balance.</Typography>
                              <Typography>The payments are distributed into equal instalments.</Typography>
                            </Box>}/>
                          </Box>
                          <Box>
                            <label>
                              <Field type="radio" name="repaymentCycle" value="flatInstalments" />
                              {`Equal Instalments - Flat Rate`}
                            </label>
                            <AlertMessage buttonText={'Explain more'}
                            message={`The interest is calculated on the Principal and Duration.
                            The payments are distributed into equal instalments.`}/>
                          </Box>
                          <Box>
                            <label>
                              <Field type="radio" name="repaymentCycle" value="lumpSum" />
                              {`Lump-sum Payment - Compound Interest`}
                            </label>
                            <AlertMessage buttonText={'Explain more'}
                            message={`Interest is compounded per month: \n(NewMonthPricipal = PastMonthPricipal + Interest).\n 
                            One lumpsum payment is made at the end of the loan duration.`}/>
                          </Box>
                          <Box>
                            <label>
                              <Field type="radio" name="repaymentCycle" value="simpleLumpSum" />
                              {`Lump-sum Payment - Simple Interest`}
                            </label>                          
                            <AlertMessage buttonText={'Explain more'}
                            message={`Interest is calculated on the Principal and Duration.\n 
                            One lumpsum payment is made at the end of the loan duration.`}/>
                          </Box>
                        </Box>
                      </Box>
        
                      <Box  gap="20px" sx={{ gridColumn: "span 3" }}>
                          <p>Loan Start Date</p>
                          <DatePickerField name="startDate" initialDate={initialValues.startDate}
                          setTheDate={setTheDate}/>
                          {values.startDate === '' &&
                          <p style={{fontSize: 12, color: '#E64333'}}>Start Date Required</p>}
                      </Box>
        
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Loan Duration"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.duration}
                        name="duration"
                        error={!!touched.duration && !!errors.duration}
                        helperText={touched.duration && errors.duration}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <Box display='flex' gap="20px">
                        <Field
                          as="select"
                          name="durationFormat"
                          onChange={handleChange}
                        >
                          <option value="months">Month(s)</option>
                          <option value="years">Year(s)</option>
                        </Field>
                        {maxPeriod && <p style={{color: 'red'}}>Max Period is 8 Years</p>}
                      </Box>
                      {!values.toggle && <Box gap='20px' sx={{ gridColumn: "span 3" }}>
                        <label>
                          <Field type="checkbox" name="toggle" />
                          This loan has addition fees
                        </label>
                      </Box>}
                      {values.toggle && <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Enter the value of any Loan Fees"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fees.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        name="fees"
                        error={!!touched.fees && !!errors.fees}
                        helperText={touched.fees && errors.fees}
                        sx={{ gridColumn: "span 2" }}
                      />}
        
                    </Box>
                    <Box display="flex" mt="30px" gap="30px">
                      <Button type="submit" color="success" variant="contained"
                        disabled={theDate?.length !== 10}>
                        {processing}
                      </Button>
                      <Button onClick={cancelLoanEdit} color="info" variant="outlined">
                        CANCEL
                      </Button>
                    </Box>
                    {(theDate?.length !== 10) && <Typography color="error"
                      >Please select start date</Typography>}
                  </form>
                )}
              </Formik>
            </Box>
        </Box>}
      </Box>
    );
  };
  const durationRegex = /^(?:[1-9]\d{0,2}|1000)$/;
  const interestRateRegEx = /^(?:[1-9]|[1-9][0-9]|100)$/
  
  const checkoutSchema = yup.object().shape({
    principal: yup.string().matches(numberRegex, "must be a number").required("required"),
    interestRate: yup.string().matches(interestRateRegEx, "must be a number between 1 and 100").required("required"),
    duration: yup.string().matches(durationRegex, "must be a number").required("required"),
    fees: yup.string().matches(numberRegex, "must be a number")
  });
  
export default EditLoanDetails