import React, { useEffect, useContext } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import { UserIDContext } from '../../../App';
import { API, graphqlOperation} from 'aws-amplify';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../../assets/custom.css'
import PointsManager from '../../../components/PointsManager'
import { loadingBorrowers, emptyBorrowers } from "../../../assets/loadingArrays";

const AssignedBorrowers = ({adminName, userAdminId}) => {
  const [borrowers, setBorrowers] = useState(loadingBorrowers)
  const [processing, setProcessing] = useState(false)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userID = useContext(UserIDContext)
  const navigate = useNavigate();
  
  useEffect(()=>{
    const listofB = []
    const listofB2 = []

    const checkBorrowerUserAdmin = async(nextToken, allBorrowers)=>{
      const token = nextToken || ''
      try{
        const borrowerUserAdmins = await API.graphql(graphqlOperation(`query MyQuery {
          listBorrowerUserAdmins(
            filter: { userAdminId: {eq: "${userAdminId}"}}, 
            limit: 1000 
            ${token}
          ) {
            items {
              borrowerId
              }
            nextToken
          }
        }
        `));
        if(borrowerUserAdmins.data.listBorrowerUserAdmins?.items.length > 0){
          const listOfBUA = borrowerUserAdmins.data.listBorrowerUserAdmins.items.map((item)=>({
            borrowerId: item.borrowerId,
          }))
          listofB2.push(listOfBUA)
          if(borrowerUserAdmins.data.listBorrowerUserAdmins.nextToken){
            checkBorrowerUserAdmin(`,nextToken: "${borrowerUserAdmins.data.listBorrowerUserAdmins.nextToken}"`, 
            allBorrowers)
          }else{
            const mergedList = [].concat(...listofB2);
            const arrayOfIds = mergedList.map((item) =>item.borrowerId)
            const setOfIds = new Set(arrayOfIds)
            const formatedBorrowers = allBorrowers.map((borrower) =>{
              if(setOfIds.has(borrower.id)){
                borrower.isAssigned = true
              }else{
                borrower.isAssigned = false
              }
              return borrower
            })
            setBorrowers(formatedBorrowers);
          }
        }else{
          const formatedBorrowers = allBorrowers.map((borrower) =>{
            borrower.isAssigned = false
            return borrower
          })
          setBorrowers(formatedBorrowers);
        }
      }catch(e){
        console.log('Error getting BorrowerUserAdmins: ',e)
      }
    }

    const listBorrowers = async(nextToken)=>{
      const token = nextToken || ''
      try{
        const listOfBorrowers = await API.graphql(graphqlOperation(`query MyQuery {
          listBorrowers(
            filter: {userBorrowersId: {eq: "${userID}"}},
            limit: 1000
            ${token}
            ) {
            items {
              firstname
              id
              otherPhoneNumber
              othername
              phoneNumber
            }
            nextToken
          }
        }
        `));
        if(listOfBorrowers.data.listBorrowers?.items?.length > 0){
          const borrowerDetails = listOfBorrowers.data.listBorrowers.items.map((borrower)=>({
            id: borrower.id,
            firstName: borrower.firstname,
            otherName: borrower.othername,
            phoneNumber: borrower.phoneNumber,
            otherPhoneNumber: borrower.otherPhoneNumber, 
          }))
          listofB.push(borrowerDetails);
          if(listOfBorrowers.data.listBorrowers?.nextToken){
            listBorrowers(`,nextToken: "${listOfBorrowers.data.listBorrowers.nextToken}"`)
          }else{
            const mergedList = [].concat(...listofB);
            checkBorrowerUserAdmin(null, mergedList)
          }
        }else {
          setBorrowers(emptyBorrowers);
        }
      }catch(e){
        console.log('Error getting Borrowers: ',e)
      }
    }
    listBorrowers()
    // eslint-disable-next-line
  },[])

  const runAssignAllBorrowers = () =>{
    setProcessing(true)
    const updatedBorrowers = borrowers.map((borrower) =>{
      if(!borrower.isAssigned) {
        assignAllBorrowers(borrower.id)
        borrower.isAssigned = true
      }
      return borrower
    });
    setBorrowers(updatedBorrowers)
    setTimeout(()=>setProcessing(false), 4000)
  }

  const assignAllBorrowers = async (borrowerId)=>{
    try{
      await API.graphql(graphqlOperation(`mutation MyMutation {
        createBorrowerUserAdmin(input: {
          borrowerId: "${borrowerId}", 
          userAdminId: "${userAdminId}",
        }) {
          id
        }
      }
      `));
    }catch(e){
      console.log('Error creating Borrower User Admin: ',e)
    }
  }

  const assignBorrower = async (borrowerId)=>{
    setProcessing(true)
    try{
      const createBorrowerUserAdmin = await API.graphql(graphqlOperation(`mutation MyMutation {
        createBorrowerUserAdmin(input: {
          borrowerId: "${borrowerId}", 
          userAdminId: "${userAdminId}",
        }) {
          id
        }
      }
      `));
      if(createBorrowerUserAdmin){
        const updatedBorrowers = borrowers.map((borrower)=>{
          if(borrower.id === borrowerId){
            borrower.isAssigned = true;
          }
          return borrower
        })
        setBorrowers(updatedBorrowers)
        setProcessing(false)
        //update datagrid functionality
      }
    }catch(e){
      console.log('Error creating Borrower User Admin: ',e)
    }
  }
  
  const removeBorrower = async (borrowerId)=>{
    setProcessing(true)
    try{
      const getBorrowerUserAdmin = await API.graphql(graphqlOperation(`query MyQuery {
        listBorrowerUserAdmins(
          filter: {
            borrowerId: {eq: "${borrowerId}"}, 
            userAdminId: {eq: "${userAdminId}"}
          }) {
          items {
            id
          }
        }
      }
      `));
      if(getBorrowerUserAdmin){
        const deletedBorrowerUserAdmin = await API.graphql(graphqlOperation(`mutation MyMutation {
          deleteBorrowerUserAdmin(input: {
            id: "${getBorrowerUserAdmin.data.listBorrowerUserAdmins.items[0].id}"
          }) {
            id
          }
        }
        `));
        if(deletedBorrowerUserAdmin){
          const updatedBorrowers = borrowers.map((borrower)=>{
            if(borrower.id === borrowerId){
              borrower.isAssigned = false;
            }
            return borrower
          })
          setBorrowers(updatedBorrowers)
          setProcessing(false)
        }
      }
    }catch(e){
      console.log('Error deleting Borrower User Admin: ',e)
    }
  }

  const columns = [
    
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "otherName",
      headerName: "Other Name",
      flex: 1.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1.2,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: "otherPhoneNumber",
      headerName: "Other Phone Number",
      align: 'center',
      headerAlign: 'center',
      flex: 1.2,
    },
    {
      field: "label",
      headerName: "",
      flex: 2.3,
      renderCell: (params) => (
        processing ? 'processing...' :
        <Box  p="3px" display="flex" flexDirection={'row'} gap="10px">
            <Button onClick={()=>assignBorrower(params.id)} color='info' size="small"
              variant="contained" startIcon={<AddCircleOutlineIcon />} 
              disabled={params.row.isAssigned || params.row.firstName === '-'}
              >Assign
            </Button>
            <Button onClick={()=>{removeBorrower(params.id)}} color='error' size="small" disabled={!params.row.isAssigned}
              variant="outlined" startIcon={<RemoveIcon />} 
              >Remove
            </Button>
        </Box>
      )
    },
  ];
  function QuickSearchToolbar() {
    return (
      <Box display="flex" flexDirection={'row'} justifyContent={'space-between'}>
        <Box
          sx={{
            mt: "-10px",
            ml: "20px",
            gap: "10px"
          }}
        >
          <Typography sx={{color: `${colors.greenAccent[200]} !important`,}}>TYPE A NAME TO SEARCH: </Typography>
          <GridToolbarQuickFilter />
        </Box>
        <Box>
          <Button onClick={runAssignAllBorrowers} variant="outlined" color="info"
            disabled={borrowers[0].firstName === '-'}
            startIcon={<AddCircleOutlineIcon />}>Assign All</Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box m="20px">
      <PointsManager />
      <Box display={'flex'} flexDirection={'row'} gap="80px" alignItems={'center'}>
        <Typography variant="h4" color={colors.grey[200]}>
        {`${adminName} will only be able to View / Edit Borrowers Assigned to them.`}
        </Typography>
      </Box>
      {borrowers && <Box
        m="40px 0 0 0"
        width='800px'
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            height: "50px"
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .normalLineHeight": {
            height: `200px !important`,
          },
        }}
      >
        <DataGrid rows={borrowers} columns={columns} 
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: QuickSearchToolbar }}
          initialState={{
            sorting: {
                sortModel: [{field: 'firstName', sort: 'asc'}],
            },
          }}
        />
      </Box>}
    </Box>
  );
};

export default AssignedBorrowers;