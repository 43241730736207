import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { API, graphqlOperation } from "aws-amplify";

//component imports
import { UserIDContext, UserTypeContext } from '../App'
import TransitionAlerts from '../components/AlertMessage'
import { useNavigate } from 'react-router-dom';
import SuccessMessage from '../components/SuccessMessage';

function BecomeChild() {

    //constants
    const {requestForParenthood, setChildStatus} = useContext(UserTypeContext)
    const userID = useContext(UserIDContext)
    const navigate = useNavigate()

    //states
    const [successMessage, setSuccessMessage] = useState()

    //functions
    function acceptRequest(){
        const updateUser = async()=>{
            try{
              const updatedUser = await API.graphql(graphqlOperation(`mutation MyMutation {
                updateUser(input: {
                    id: "${userID}", 
                    status: "${requestForParenthood?.accessLevel};${requestForParenthood?.parentId};${requestForParenthood?.userAdminId}",
                    userAttribue2: "",
                }) {
                  id
                }
              }
              `));
              if(updatedUser){
                updateUserAdmin()
              }
            }catch(e){
              console.log('Error submitting request: ',e)
            }
        }
        updateUser()
    }

    const updateUserAdmin = async()=>{
        try{
            const updatedUserAmin = await API.graphql(graphqlOperation(`mutation MyMutation {
                updateUserAdmin(input: {
                    id: "${requestForParenthood?.userAdminId}", 
                    attribue1: "${requestForParenthood?.accessLevel}"
                }) {
                  id
                }
              }
              
            `));
            if(updatedUserAmin){
              setChildStatus(requestForParenthood);
              setSuccessMessage("Request Approved Successfully");
              setTimeout(()=>navigate('/'), 2000)                   
            }
        }catch(e){
            console.log('Error updating UserAdmin: ',e)
        }
    }

  return (
    <Box display="flex" flexDirection={'column'} width="850px">
        {successMessage && <SuccessMessage message={successMessage} />}
        <Typography variant="h2">NOTICES</Typography>
        <Box display={'flex'} flexDirection={'column'} mt="10px">
            <Typography variant="h5" mb="10px">
                {requestForParenthood?.parentEmail} has requested to become your Parent Account.</Typography>
            <TransitionAlerts buttonText={'Explain More'}
                message={<>
                    <Typography>Your account will become a Child Account to {requestForParenthood?.parentEmail}</Typography>
                    <Typography>Your Business will be replaced with the Business of the Parent Account.</Typography>
                    <Typography>The Parent Account has control over what you can view/edit.</Typography>
                </>}
            />
            <Box display="flex" gap="40px" mt="40px">
                <Button variant='contained' color='success' onClick={acceptRequest}>Accept Request</Button>
                <Button variant='outlined' color='error'>Ignore</Button>
            </Box>
        </Box>
    </Box>
  )
}

export default BecomeChild