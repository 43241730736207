import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import React, { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//component imports
import { tokens } from "../theme";
import {currenciesObj} from "../assets/currenciesObj";
import Header from "../components/Header";
import { UserTypeContext } from "../App";
import { UserIDContext, SignOutContext } from "../App";
import SuccessMessage from "../components/SuccessMessage";

const Settings = () => {

  //constants
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currencies = Object.keys(currenciesObj)
  const {userCurrency, setBusinessName, setUserCurrency, businessName, setUserTypeAWS,
    setDecimalPoints} = useContext(UserTypeContext);
  const userID = useContext(UserIDContext)
  const signOut = useContext(SignOutContext)
  const navigate = useNavigate()
  
  //states
  const [processing, setProcessing] = useState('Save Changes')
  const [successMessage, setSuccessMessage] = useState()
  const [checked, setChecked] = useState(false)
  const [deleteCheck, setDeleteCheck] = useState(false)
  const [deleteButton, setDeleteButton] = useState('Yes, Delete Everything')

  //useEffects

  //functions
  
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a' 

  const initialValues = {
    currency: userCurrency || 'USD',
    businessName: businessName ||''
  };

//update user
  const updateUser = async(businessName, currency)=>{
    try{
      const updatedUser = await API.graphql(graphqlOperation(`mutation MyMutation {
        updateUser(input: {
          id: "${userID}", 
          businessName: "${businessName}", 
          userAttribue1: "${currency}"
        }) {
          id
        }
      }
      `))
      if(updatedUser){
        setUserCurrency(currency)
        setBusinessName(businessName)
        setDecimalPoints(currenciesObj[currency].decimal_digits)
        setSuccessMessage("Settings updated successfully")
        setTimeout(()=>{
          setSuccessMessage(null)
          navigate('/')
        },3000)
      };
    }
    catch(e){
      console.log('Error updating user', e)
    }
  }
  
  //delete user
    const deleteUser = async()=>{
      setDeleteButton('Deleting...')
      try{
        const deletedUser = await API.graphql(graphqlOperation(`mutation MyMutation {
          updateUser(input: {id: "${userID}", deletedUser: "true"}) {
            id
          }
        }        
        `))
        if(deletedUser){
          signOut()
        };
      }
      catch(e){
        console.log('Error updating user', e)
      }
    }


  const handleFormSubmit = (values) => {
    const {currency, businessName} = values
    setProcessing('Processing...Please Wait...')
    updateUser(businessName, currency)
  };

  const enableEditing =(e)=>{
    setChecked(e.target.checked);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
      {!deleteCheck && <Box m="20px" >
        {successMessage && <SuccessMessage message={successMessage}/>}
        <Header 
            title={'LoanTab Settings'} 
            />
        <FormGroup
        sx={{ml: "20px", mt: '10px'}}>
          <FormControlLabel control={
            <Checkbox 
              color="success"
              defaultChecked={false} 
              onChange={enableEditing}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} 
          label="Enable Editing" />
        </FormGroup>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: "span 3" },
                }}
              >
                <Box sx={{ gridColumn: "span 3" }}>
                  <Box display='flex' gap="10px">
                    <p>Currency used in your Business: </p>
                    <Field
                      className="formSelect"
                      disabled={!checked}
                      as="select"
                      name="currency"
                      onChange={handleChange}
                    >
                    {currencies.map((currency, i)=>(
                      <option value={currency} key={i}>{currency}</option>
                    ))}
                    </Field>
                  </Box>
                </Box>
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Business Name (optional)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.businessName}
                  name="businessName"
                  sx={{ gridColumn: "span 3" }}
                />
              </Box>
              <Box display="flex" mt="50px" mb='20px' gap="30px">
                <Button type="submit" color="info" variant="outlined" >
                  {processing}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box>
          <Button color="error" variant="outlined"
            onClick={() => setDeleteCheck(true)}
          >Delete My Data & Account</Button>
        </Box>
      </Box>}
      {deleteCheck && <Box display="flex" flexDirection={'column'} gap="20px" alignItems={'center'}>
        <Typography variant="h4">This action is can NOT be reversed. <br/>All your data will be deleted permanently.</Typography>
        <Box display="flex" flexDirection={'column'} gap="20px" alignItems={'center'}>
          <Button color='info' variant="outlined"
            onClick={() => setDeleteCheck(false)}
          >Cancel</Button>
          <Button color='error' variant="outlined"
            onClick={deleteUser}
            disabled= {deleteButton === 'Deleting...'}
          >{deleteButton}</Button>
        </Box>
      </Box>}
    </Box>
  );
};

export default Settings;