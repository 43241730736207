import React, { useEffect, useState, useContext } from "react";
import { Box,  Button,  Checkbox,  FormControlLabel,  FormGroup,  Typography,  useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { API, graphqlOperation } from "aws-amplify";
import { tokens } from "../theme";
import '../assets/custom.css'
import '../index.css'
import { dateToday } from "../assets/getDate";
import Header from "./Header";
import { createLoanStatementA } from "../assets/AmortizedLoanStatementArrays";
import EditLoanDetails from "../scenes/Loans/EditLoanDetails";
import EditLoanValues from "../scenes/Loans/EditLoanValues";
import NewPaymentOnStatement from "./NewPaymentOnStatement";
import SuccessMessage from "./SuccessMessage";
import EditIcon from '@mui/icons-material/Edit';
import { formatDate } from "../assets/AmortizedLoanStatementArrays";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import moment from "moment";
import { LoansContext, UserTypeContext } from "../App";
import PaymentReceipt from "../scenes/Payments/PaymentReceipt";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NewPenalty from "./NewPenalty";

const LoanStatement = ({loanData, LoanAmount, InterestRate, startDate, loanId,
  Installment, Period, paymentsData, viewLoans, borrower, duration, status, updateStatus, currency,
  addPayment, borrowerID, repaymentCycle, updatedPayments, updateLoans, updatedStatus,
  stopInterest, lIBS, addPenalty, penaltiesData, loanPeriodFormat, grossLoanValue,
  paymentFrequency}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const gridRef = useGridApiRef()
  const printerWidth = 145; 
  const borderMargin = '16px'
  const [printWidth, setPrintWidth] = useState(printerWidth)
  const [printHeaderGap, setPrintHeaderGap] = useState('80px')
  const [paymentWindow, setPaymentWindow] = useState()
  const [paymentMessage, setPaymentMessage] = useState()
  const [amountPaid, setAmountPaid] = useState()
  const [paymentEdit, setPaymentEdit] = useState()
  const [newPaymentDate, setNewPaymentDate] = useState()
  const [loanRepaymentCycle, setLoanRepaymentCycle] = useState()
  //new for payments on datagrid
  const [rowModesModel, setRowModesModel] = useState({});
  const [loanStatement, setLoanStatement] = useState()
  const [rows, setRows] = useState()
  const [totalPayments, setTotalPayments] = useState()
  const [disableEditButton, setDisableEditButton] = useState(false)
  const [amountDue, setAmountDue] = useState()
  const LoanEndDate = moment(startDate).add(duration, loanPeriodFormat).format('DD-MMM-YYYY')
  const {businessName, userTypeAWS} = useContext(UserTypeContext)
  const hasBusinessName = businessName || (businessName !== '') ? businessName : '.' 
  const buttonDisabled = userTypeAWS === 'viewer' ? true : false

  // eslint-disable-next-line
  const [printState, setPrintState] = useState(true)
  const [printColor, setPrintColor] = useState(colors.grey[100])
  //Loan mutations states
  const [editLoan, setEditLoan] = useState()
  const [loanDeleted, setLoanDeleted] = useState()
  const [overrideStatus, setOverrideStatus] = useState()
  const [statementInfo, setStatementInfo] = useState()
  const [lateInterestBySystem, setLateInterestBySystem] = useState(lIBS)
  const [paymentReceiptDetails, setPaymentReceiptDetails] = useState()
  const [penaltyWindow, setPenaltyWindow] = useState()
  const [editingPenalty, setEditingPenalty] = useState(false)

  const {decimalPoints} = useContext(UserTypeContext);
  const {setLoansContext} = useContext(LoansContext)

  //initialDetails
  const initialDetails = {
    principal: LoanAmount, 
    interestRate: InterestRate,
    startDate: new Date(formatDate(startDate)),
    duration: duration, 
    rateFormat: Period,
    repaymentCycle: repaymentCycle,
    instalmentFrequency: paymentFrequency
  }
  const decimalOptions = {
    maximumFractionDigits: decimalPoints || 0,
    minimumFractionDigits: decimalPoints || 0
  }

  //functions to enable the new datagrid payments
  const handleRowEditStop = (params, event) => {
    setEditingPenalty(false)
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (params) => {
    params.row.description.includes('PENALTY') && setEditingPenalty(true)
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setEditingPenalty(false)
  };

  // handling delete
  const handleDeleteClick = ({id, description}) =>()=> {
    (description === 'Payment Made') && getPaymentAccount(id);
    description.includes("PENALTY") && deletePenalty(id)
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    setEditingPenalty(false)
  };

  const processRowUpdate = (updatedRow) => {
    setEditingPenalty(false)
    if(updatedRow.description === 'Payment Made'){
      updatePayment(updatedRow)
    }else {
      if(!updatedRow.description.includes("PENALTY")){
        updatedRow.description = `PENALTY: ${updatedRow.description}`
      }
      updatePenalty(updatedRow);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const editableRow = (params) => {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      
      return [
        <GridActionsCellItem
          disabled={buttonDisabled}
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: '#fff',
          }}
          onClick={()=>{
            handleSaveClick(params.id)
            setDisableEditButton(false)
          }}
        />,
        <GridActionsCellItem
          disabled={buttonDisabled}
          icon={<CancelIcon />}
          label="Cancel"
          className="textInfo"
          onClick={()=>{
            handleCancelClick(params.id)
            setDisableEditButton(false)
          }}
          color="inherit"
        />
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textInfo"
        disabled={disableEditButton || buttonDisabled}
        onClick={()=>{
          handleEditClick(params)
          setDisableEditButton(true)
        }}
        color="#fff"
      />,
      <GridActionsCellItem
        icon={<DeleteOutlineIcon />}
        label="Delete"
        onClick={handleDeleteClick(params.row)}
        color="inherit"
        disabled={buttonDisabled}
      />
    ];
  }

  const enableSystemInterest =(e)=>{
    setLateInterestBySystem(e.target.checked);
    stopInterest(e.target.checked);
  }

  useEffect(()=>{
    switch(repaymentCycle) {
      case 'instalments': 
        setLoanRepaymentCycle('Equal Instalments - Reducing Balance');
        break;
      case 'lumpSum': 
        setLoanRepaymentCycle('Lump-sum Payment - Compound Interest');
        break;
      case 'flatInstalments': 
        setLoanRepaymentCycle('Equal Instalments - Flat Rate');
        break;
      case 'simpleLumpSum': 
        setLoanRepaymentCycle('Lump-sum Payment - Simple Interest');
        break;
    }
  }, [])
  //loan Statement computations
  useEffect(()=>{
    const loanDetails = createLoanStatementA(loanData, paymentsData, startDate, 
        duration, InterestRate, status, lateInterestBySystem, penaltiesData,
        Period, loanPeriodFormat, true, grossLoanValue)
    updateRows(loanDetails)
  },[lateInterestBySystem])

  //loan mutation functions

  const updateRows = (loanDetails)=>{
    setRows(loanDetails[0])
    setStatementInfo(loanDetails)
    setTotalPayments(loanDetails[1])
    setAmountDue(loanDetails[3])
    setLoanStatement(loanDetails[0])
  }

  const editLoanDetails = ()=>{
    setEditLoan(true)
  }
  const cancelPayment = ()=>{
    setPaymentWindow(false)
  }
  const cancelPenalty = ()=>{
    setPenaltyWindow(false)
  }

  const paymentAdded =()=>{
    setPaymentWindow(false)
    setPaymentMessage(`Payment Successfully Added`)
    setTimeout(()=>{setPaymentMessage(false)}, 4000)
    addPayment()
  }
  const penaltyAdded =()=>{
    setPenaltyWindow(false)
    setPaymentMessage(`Penalty Successfully Added`)
    setTimeout(()=>{setPaymentMessage(false)}, 4000)
    addPenalty()
  }
  //update Datagrid after a payment has been added
  const addNewPaymentToStatement = (paymentId, amount, paymentDate)=>{
    const updatedPaymentsData = [ ...paymentsData, {id: paymentId, paymentDate, paymentAmount: amount}]
    const loanDetails = createLoanStatementA(loanData, updatedPaymentsData, startDate, 
      duration, InterestRate, status, lateInterestBySystem, penaltiesData,
      Period, loanPeriodFormat, true, grossLoanValue)
    updateRows(loanDetails)
  }

  const addNewPenaltyToStatement = (penaltyId, amount, penaltyDate, comment)=>{
    const updatedPenaltiesData = [ ...penaltiesData, {id: penaltyId, penaltyDate, penaltyAmount: amount, comment}]
    const loanDetails = createLoanStatementA(loanData, paymentsData, startDate, 
      duration, InterestRate, status, lateInterestBySystem, updatedPenaltiesData,
      Period, loanPeriodFormat, true, grossLoanValue)
    updateRows(loanDetails)
  }

  const getLoanAccount = async()=>{
    try{
      const loanAccount = await API.graphql(graphqlOperation(`query MyQuery {
        listLoanAccounts(filter: {loanId: {eq: "${loanId}"}}) {
          items {
            id
          }
        }
      }
      `))     
      if(loanAccount.data.listLoanAccounts.items.length > 0) {
        deleteLoanAccount(loanAccount.data.listLoanAccounts.items[0].id);
      }else {
        deleteLoan()
      }
    }
    catch(e){
      console.log('Error getting loanAccount', e)
    }
  }

  const deleteLoanAccount = async(loanAccountId) => {
    try{
      const deletedLoanAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
        deleteLoanAccount(input: {id: "${loanAccountId}"}) {
          id
        }
      }
      `))     
      if(deletedLoanAccount){
        deleteLoan()
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete LoanAccount:::', e)
    }
  }

  const deleteLoan = async()=>{
    try{
      const deletedLoan = await API.graphql(graphqlOperation(`mutation MyMutation {
        deleteLoan(input: {id: "${loanId}"}) {
          id
        }
      }
      `))     
      if(deletedLoan){
        setLoansContext(null);
        setTimeout(()=> {
          setLoanDeleted(deletedLoan)
          setTimeout(()=> viewLoans(loanId), 1500)
        }, 500)
      }
    }
    catch(e){
      console.log('Loan deleting failed', e)
    }
  }

  const updatePayment = async(updatedRow) => {
    const {id, paymentAmount, date} = updatedRow
    const amount = parseFloat(paymentAmount.replace(/,/g, ''))
    const newDate = date ? `paymentDate: "${date}"` : ''
    try{
      const updatedPayment = await API.graphql(graphqlOperation(`mutation MyMutation {
        updatePayment(input: {
          id: "${id}", 
          amount: ${amount}, ${newDate}
        }) {
          id
        }
      }
      `))     
      if(updatedPayment){
        const updatedPaymentsData = paymentsData.map(payment =>
          (id === payment.id ? {id, paymentAmount: amount, paymentDate: date} : payment))
        const loanDetails = createLoanStatementA(loanData, updatedPaymentsData, startDate, 
            duration, InterestRate, status, lateInterestBySystem, penaltiesData,
            Period, loanPeriodFormat, true, grossLoanValue)
        updateRows(loanDetails)
        addPayment()
      }
    }
    catch(e){
      console.log('ERROR: Unable to update Payment:::', e)
    }
  }

  const updatePenalty = async(updatedRow) => {
    const {id, instalmentDue, date, description} = updatedRow
    const amount = parseFloat(instalmentDue.replace(/,/g, ''))
    const newDate = date ? `penaltyDate: "${date}"` : ''
    try{
      const updatedPenalty = await API.graphql(graphqlOperation(`mutation MyMutation {
        updatePenalty(input: {
          id: "${id}", 
          penaltyAttribute1: "${description}", 
          amount: ${amount}, ${newDate}
        }) {
          id
        }
      }
      `))     
      if(updatedPenalty){
        const updatedPenaltyData = penaltiesData.map(penalty =>
          (id === penalty.id ? 
            {id, penaltyAmount: amount, penaltyDate: date, comment: description} 
            : penalty))
        const loanDetails = createLoanStatementA(loanData, paymentsData, startDate, 
            duration, InterestRate, status, lateInterestBySystem, updatedPenaltyData,
            Period, loanPeriodFormat, true, grossLoanValue)
        updateRows(loanDetails)
        addPenalty()
      }
    }
    catch(e){
      console.log('ERROR: Unable to update Penalty:::', e)
    }
  }
  
  const deletePaymentAccount = async(paymentAccountId, paymentId) => {
    try{
      const deletedPaymentAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
        deletePaymentAccount(input: {id: "${paymentAccountId}"}) {
          id
        }
      }
      `))     
      if(deletedPaymentAccount){
        deletePayment(paymentId)
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete PaymentAccount:::', e)
    }
  }

  const getPaymentAccount = async(paymentId) => {
    //get Payment Accont 
    try{
      const paymentAccount = await API.graphql(graphqlOperation(`
      query MyQuery {
        listPaymentAccounts(filter: {paymentId: {eq: "${paymentId}"}}) {
          items {
            id
          }
        }
      }
      `))
      if(paymentAccount.data.listPaymentAccounts.items.length > 0) {
        deletePaymentAccount(paymentAccount.data.listPaymentAccounts.items[0].id, paymentId);
      }else {
        deletePayment(paymentId)
      }
    }catch(e){
      console.log('ERROR getting paymentAccount:::', e)
    }
  }

  const deletePayment = async(paymentId) => {
    try{
      const deletedPayment = await API.graphql(graphqlOperation(`mutation MyMutation {
        deletePayment(input: {id: "${paymentId}"}) 
        {
          id
        }
      }
      `))     
      if(deletedPayment){
        const updatedPaymentsData = paymentsData.filter(payment => (paymentId !== payment.id))
        const loanDetails = createLoanStatementA(loanData, updatedPaymentsData, startDate, 
          duration, InterestRate, status, lateInterestBySystem, penaltiesData,
          Period, loanPeriodFormat, true, grossLoanValue)
        updateRows(loanDetails)
        addPayment()
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete Payment:::', e)
    }
  }

  const deletePenalty = async(penaltyId) => {
    try{
      const deletedPenalty = await API.graphql(graphqlOperation(`mutation MyMutation {
        deletePenalty(input: {id: "${penaltyId}"}) 
        {
          id
        }
      }
      `))     
      if(deletedPenalty){
        const updatedPenaltyData = penaltiesData.filter(penalty => (penaltyId !== penalty.id))
        const loanDetails = createLoanStatementA(loanData, paymentsData, startDate, 
          duration, InterestRate, status, lateInterestBySystem, updatedPenaltyData,
          Period, loanPeriodFormat, true, grossLoanValue)
        updateRows(loanDetails)
        addPenalty()
      }
    }
    catch(e){
      console.log('ERROR: Unable to Delete Penalty:::', e)
    }
  }

  const goBack = () => {
    updatedPayments[0] && updateLoans(updatedPayments[0], updatedPayments[1])
    viewLoans()
  }

  const updateLoanDetails = (newLoanDetails) => {
    if(updatedPayments[0]){
      updateLoans(updatedPayments[0], updatedPayments[1], loanId, newLoanDetails)
    }else{
      updateLoans(null, null, loanId, newLoanDetails)
    }
    viewLoans()
  }

  const DataGridTitle = ()=>{
    return(
      <Box className="dataGridTitle" mb='30px'>
        <Box display='flex' flexDirection='column' alignItems='space-between'>
          {printWidth !== 145 && 
          <Box>
            <Box display='flex' justifyContent='space-between' gap='20px' mt='-30px' mb='10px'>
                <p style={{color: 'blue', textAlign: 'center'}}>Created using Loan Management Software from <u>www.LoanTabs.com</u></p>
                <p>Printed on: {dateToday}</p>
            </Box>
            <Box display='flex' flexDirection={'column'} mb="20px">
              <Typography variant="h2" className="printOnly" 
                sx={{pb: "10px", borderBottom: '1px solid black', mb: "20px"}}>
                {`${hasBusinessName.toUpperCase()}`}</Typography>
              <Typography variant='h3'> LOAN STATEMENT</Typography>
            </Box>
          </Box>
          }
          <Box display='flex' justifyContent='flex-start' gap={printHeaderGap}>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
              <Typography >Borrower:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{borrower.toUpperCase()}</Typography></Typography>
              <Typography >Loan Amount:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${LoanAmount}`}</Typography></Typography>
              <Typography >Total Payments Made:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${totalPayments}`}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
              <Typography>Loan Start Date:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{startDate}</Typography></Typography>
              <Typography >Interest Rate:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{InterestRate}% {Period}</Typography></Typography>
              <Typography >Loan Balance:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${loanStatement[loanStatement.length-1].balance}`}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
            <Typography >Loan Duration:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${duration} ${loanPeriodFormat}`}</Typography></Typography>
              <Typography >Instalment Amount:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${Installment}`}</Typography></Typography>
              <Typography >Amount Due:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{`${currency} ${amountDue.toLocaleString('en-US', decimalOptions)}`}</Typography></Typography> 
            </Box>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
            <Typography >Maturity Date:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{LoanEndDate}</Typography></Typography>
            <Typography >Repayment Cycle:<br/>
                <Typography
                component={'span'} color={printWidth === printerWidth ? colors.greenAccent[400] : '#000'}
                variant="h6">{loanRepaymentCycle}</Typography></Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const columns = [
    {
      field: "date",
      sortable: false,
      type: "date",
      headerName: "Date",
      width: printWidth,
      cellClassName: printWidth === printerWidth ? "name-column--cell": '' ,
      align: 'left',
      headerAlign: 'left',
      flex: printWidth === printerWidth ? 1.5 : '',
      editable: true,
      renderCell: (params)=>{
        const options = { day: "2-digit", month: "short", year: "2-digit" };
        return params.row.date ? params.row.date.toLocaleDateString("en-UK", options) : ''
      },
    },
    {
      field: "description",
      cellClassName: printWidth === printerWidth ? "name-column--cell": '' ,
      sortable: false,
      headerName: "Description",
      editable: editingPenalty,
      width: printWidth,
      align: 'left',
      headerAlign: 'left',
      flex: printWidth === printerWidth ? 1.2 : '',
      paddingTop: borderMargin, paddingBottom: borderMargin,
    },
    {
      cellClassName: printWidth === printerWidth ? "MuiDataGrid-cell": '' ,
      field: "instalmentDue",
      sortable: false,
      headerName: "Instalment Due",
      editable: editingPenalty,
      width: printWidth,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.2 : '',
      paddingTop: borderMargin, paddingBottom: borderMargin,
      renderCell: (params)=>{
        return params.row.description === 'Payment Made'
        ? <Button variant="text" color="info" size="small" startIcon={<PrintIcon/>}
          className="printNot"
          onClick={()=>{
            setPaymentReceiptDetails(params)
            setTimeout(()=>window.print(), 500)
            setTimeout(()=>{
              setPaymentReceiptDetails(null)
            }, 1000)
          }}>Receipt</Button>
        : params.row.instalmentDue
      }
    },
    {
      cellClassName: printWidth === printerWidth ? "MuiDataGrid-cell": '' ,
      paddingTop: borderMargin, paddingBottom: borderMargin,
      field: "paymentAmount",
      sortable: false,
      headerName: "Payment Made",
      width: printWidth,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.8: '',
      editable: !editingPenalty,
      cellClassName: 'payment'
    },
    {
      cellClassName: printWidth === printerWidth ? "MuiDataGrid-cell": '' ,
      paddingTop: borderMargin, paddingBottom: borderMargin,
      field: "balance",
      sortable: false,
      headerName: "Balance",
      width: printWidth,
      align: 'right',
      headerAlign: 'right',
      flex: printWidth === printerWidth ? 1.2 : ''
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: printWidth === printerWidth ? 'Edit/Delete' : '',
      // width: 100,
      cellClassName: 'actions, printNot',
      getActions: (params) => {
        return ((params.row.description === 'Payment Made')||
          (params.row.description.includes("PENALTY"))) ? editableRow(params) : []
      }
    }
  ];
 
  return (<>
    {paymentReceiptDetails && <PaymentReceipt paymentDetails={paymentReceiptDetails.row}
      borrower={borrower.toUpperCase()} principal={LoanAmount} startDate={startDate} currency={currency}
      maturityDate={LoanEndDate} amountDue={amountDue} totalPayments={totalPayments}
      loanBalance={loanStatement[loanStatement.length-1].balance}/>}
    {loanStatement && !paymentReceiptDetails && <Box>
      {loanDeleted && <Box m="60px">
            <Header title={'LOAN DELETED SUCCESSFULLY'} />
          </Box>}
      {!loanDeleted && <Box m="20px" id='schedule'>
        {!editLoan && <Box> 
          <Box display='flex' justifyContent="flex-start" gap='30px' m='-73px 0 40px 300px' className='printNot'>
          <Button startIcon={<PrintIcon />}
            onClick={()=>{
              setPrintWidth(125)
              setPrintHeaderGap('55px')
              setPrintColor('black')
              setTimeout(()=>window.print(), 100)
              setTimeout(()=>{
                setPrintWidth(printerWidth);
                setPrintHeaderGap('80px');
                setPrintColor(colors.grey[100])
              }, 100)
            }}
            color='info'
            type="button">
            PRINT STATEMENT</Button>
          <Button
            onClick={goBack} type="button" color="warning" variant="outlined">
              GO BACK
          </Button>
        </Box>
        </Box>}
        <DataGridTitle />
        {editLoan && <EditLoanValues loanId={loanId} addPayment={addPayment} status={status}
          cancelLoanEdit={()=> setEditLoan(false)} borrower={borrower} borrowerID={borrowerID}
          updateLoanDetails={updateLoanDetails} initialDetails={initialDetails}/>}
        {!editLoan && <Box
          m="0 0 40px 0"
                    // width="100%"
          width={printWidth !== printerWidth ? "100%" : "800px"}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderColor: printColor,
              height: "50px !important"
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
              height: "unset !important"
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            " .payment": {
              color: printWidth === printerWidth ? colors.greenAccent[200] : printColor,
            },
          }}
        >
          <Box display="flex" gap="10px" alignItems={'center'}
            className='printNot'>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}} mr='40px'>
              <Typography>LOAN STATUS:<br/>
                  {!updatedStatus && <Typography 
                    component={'span'} color={colors.blueAccent[300]}
                    variant="h3">{
                    (function(){
                      return statementInfo[2].includes('override') 
                        ? statementInfo[2].toUpperCase().slice(12) + ` (OR)`: statementInfo[2].toUpperCase() 
                    })()}</Typography>}
                  {updatedStatus && <Typography component={'span'} color={colors.blueAccent[300]}
                    variant="h3">{updatedStatus.toUpperCase().slice(12)}</Typography>}
                </Typography>
            </Box>
            {!overrideStatus && !statementInfo[2].includes('override') &&
              !updatedStatus?.includes('override') &&
              <Button variant='outlined' color="error" disabled={buttonDisabled}
                onClick={()=>setOverrideStatus(true)}>
                Override Loan Status (Not recommended)</Button>}
            {overrideStatus && <div>
              <Typography color={colors.redAccent[300]}>Override Loan Status to:</Typography>
              {/* <Button onClick={()=>{
                  updateLoans(null, null, loanId, null, 'override to current');
                  updateStatus('override to current')
                }} type="button" color="warning" >
                "CURRENT"
              </Button> */}
              <Button disabled={buttonDisabled} onClick={()=>{
                  updateLoans(null, null, loanId, null, 'override to cleared')
                  updateStatus('override to cleared')
                  setTimeout(()=>setOverrideStatus(null), 1000)
                }} type="button" color="warning" variant="outlined">
                "CLEARED"
              </Button>
              {/* <Button onClick={()=>{
                  updateLoans(null, null, loanId, null, 'override to payment due')
                  updateStatus('override to payment due')
                }} type="button" color="warning" >
                "PAYMENT DUE"
              </Button>
              <Button onClick={()=>{
                  updateLoans(null, null, loanId, null, 'override to defaulted')
                  updateStatus('override to defaulted')
                }} type="button" color="warning" >
                "DEFAULTED"
              </Button> */}
            </div>}
            {(statementInfo[2].includes('override') || updatedStatus?.includes('override')) && 
            <Button variant='outlined' color="success" disabled={buttonDisabled}
            onClick={()=>{
              updateLoans(null, null, loanId, null, 'overdue')
              updateStatus('overdue')
              setTimeout(()=>{viewLoans()}, 2000)
            }} type="button"
           >Cancel Status override (recommended)</Button>}
          </Box>
          {(statementInfo[2].includes('overdue') || updatedStatus?.includes('overdue')) &&
          <FormGroup className='printNot' >
            <FormControlLabel control={
              <Checkbox
                disabled={buttonDisabled}
                color="info"
                defaultChecked={lIBS} 
                onChange={enableSystemInterest}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
              />} 
            label="Continue to charge Interest on the Outstanding Amount past the Maturity Date" />
          </FormGroup>}
          <Box mb="20px" mt="20px" display="flex" gap="20px"
            justifyContent='space-between' className='printNot' > 
            <Button onClick={()=>{
                setPaymentWindow(true)
                setPenaltyWindow(false)
              }} type="button" disabled={buttonDisabled}
              startIcon={<AddIcon />} color="info" variant="outlined">
              ADD PAYMENT
            </Button>
            <Button onClick={()=>{
                setPenaltyWindow(true)
                setPaymentWindow(false)
              }} type="button" disabled={buttonDisabled}
              startIcon={<ErrorOutlineIcon />} color="warning" variant="outlined">
              ADD PENALTY
            </Button>
            <Button onClick={editLoanDetails} type="button" disabled={buttonDisabled}
              startIcon={<EditIcon />} color="info" variant="outlined">
              EDIT LOAN DETAILS
            </Button>
            <Button onClick={getLoanAccount} type="button" disabled={buttonDisabled}
              startIcon={<DeleteOutlineIcon />} color="error" variant="outlined">
              DELETE LOAN
            </Button>
          </Box>
          {paymentWindow && <Box mt="20px">
            <NewPaymentOnStatement loanId={loanId} paymentAdded={paymentAdded} 
              addNewPaymentToStatement={addNewPaymentToStatement}
              setAmountPaid={setAmountPaid} cancelPayment={cancelPayment} currency={currency}/>
          </Box>}
          {penaltyWindow && <Box mt="20px">
            <NewPenalty loanId={loanId} penaltyAdded={penaltyAdded} 
              addNewPenaltyToStatement={addNewPenaltyToStatement}
              setAmountPaid={setAmountPaid} cancelPenalty={cancelPenalty} currency={currency}/>
          </Box>}
          {paymentMessage && <Box>
            <SuccessMessage message={paymentMessage} />
          </Box>}
          <Header otherTitle={'Transactions:'} color={printWidth === printerWidth ? colors.grey[100] : colors.grey[900]}/>
          <Box marginTop={'-60px'}>
            <DataGrid rows={rows} columns={columns}
              getRowHeight={() => 'auto'}
              autoHeight={true}
              hideFooter={true}
              sx={{
                marginTop: 10,
                '@media print': {
                  '.MuiDataGrid-main': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '.dataGridTitle': {display: 'block'},
                }
              }}
              columnVisibilityModel={{
                principal: printState,
                interest: printState
              }}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              initialState={{
                sorting: {
                    sortModel: [{field: 'date', sort: 'asc'}],
                },
              }}
            />
          </Box>
        </Box>}
      </Box>}
    </Box>}
  </>);
};

export default LoanStatement;