import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { API, graphqlOperation} from 'aws-amplify';
import moment from 'moment';
import UsersData from './UsersData';
//components
import { SignOutContext } from "../../App";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, pb: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function LoanTabsAdmin() {
  //states
    const [value, setValue]  = useState(0)
    const [users, setUsers] = useState()

  //Contants
  const signOut = useContext(SignOutContext)

  //useEffect
  useEffect(()=>{
  
    const configureUsers = (userList) =>{

      const today = moment()
      //remove test accounts and format.
      userList = userList.filter((user)=>{
        user.createdAt = new Date(user.createdAt);
        const endDate = moment(user.userTypeChangeDate)
        user.daysLeft = Math.max(0, endDate.diff(today, 'days'))
        return (
          !user.email.includes('amugisha.ug') 
          && !user.email.includes('amugisha.ke')
          && !user.email.includes('arymic.team')
          && !user.email.includes('andrewmugisha.ug')
          && !user.email.includes('loantabs.com')
        )
      })
      setUsers(userList);
    }

    const listofU = []

    const getUsers = async(nextToken)=>{
      const token = nextToken || ''
      try{
        const listOfUsers = await API.graphql(graphqlOperation(`query MyQuery {
          listUsers(
            limit: 1000
            ${token}
          ) {
            nextToken
            items {
              email
              userType
              userTypeChangeDate
              createdAt
              id
            }
          }
        }
        `));
        if(listOfUsers.data.listUsers.items.length > 0) {
          listofU.push(listOfUsers.data.listUsers.items)
          if(listOfUsers.data.listUsers.nextToken){
            getUsers(`,nextToken: "${listOfUsers.data.listUsers.nextToken}"`)
          }else{
            const mergedList = [].concat(...listofU);
            configureUsers(mergedList); 
          }
        }
      }catch(e){
        console.log('Error getting users: ',e)
      }
    }
    getUsers()
    // eslint-disable-next-line
  },[])

  //functions
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const filterByType = (type) => {
    if(users) return users.filter(user => user.userType === type)
  }
  const filterActiveOnly = (list)=>{
    if(list) return list.filter(user => user.daysLeft > 0)
  }

  return (
    <Box p="40px" width="100%"
      sx={{display: 'flex', flexDirection: "row", justifyContent: 'center'}}>
        <Box width="900px" display="flex" flexDirection={'column'}>
          <Box><Button 
            onClick={signOut}
            variant='contained' color='error'
            >Sign Out</Button></Box>
          <Typography variant='h4' mt="20px" component={'span'}
            >LOANTABS USERS DATA</Typography>
          <Box>
              <Box mt="15px" mb="10px">
              <Tabs value={value} onChange={handleChange} textColor="secondary"
                  indicatorColor="secondary" variant="scrollable"
                  scrollButtons="auto">
                  <Tab sx={{fontSize: 16}} label="ALL USERS" {...a11yProps(0)} />
                  <Tab sx={{fontSize: 16}} label="ACTIVE USERS" {...a11yProps(1)} />
                  <Tab sx={{fontSize: 16}} label="ACTIVE FREE TRIALS" {...a11yProps(2)} />
                  <Tab sx={{fontSize: 16}} label="PRO ($15/M)" {...a11yProps(3)} />
                  <Tab sx={{fontSize: 16}} label="CORPORATE ($45/M)" {...a11yProps(4)} />
              </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                  <UsersData users={users}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                  <UsersData users={filterActiveOnly(users)}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                  <UsersData users={filterActiveOnly(filterByType('lite'))}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                  <UsersData users={filterByType('PRO')}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                  <UsersData users={filterByType('CORPORATE')}/>
              </CustomTabPanel>
          </Box>
        </Box>
    </Box>
  )
}

export default LoanTabsAdmin