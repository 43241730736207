import { Button, Box, useTheme, Typography } from '@mui/material'
import React, { useState } from 'react'
import PackageCard from '../components/PackageCard'
import { tokens } from '../theme'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserTypeContext } from '../App'
import { loanTabsPackages } from '../assets/packages'
import ToggleButtons from '../components/ToggleButtons'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Packages() {
  //variables
  const navigate = useNavigate()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {userCurrency} = useContext(UserTypeContext)
  const {PRO, CORPORATE} = loanTabsPackages
  const currencies = ['BWP', 'GHS', 'INR', 'KES', 'NGN', 'TZS', 'UGX', 'ZAR', 'ZMK']
  const currencySet = new Set(currencies)
  // const currency = userCurrency === 'UGX' ? 'UGX' : 'USD'
  let initialCurrency = currencySet.has(userCurrency)? userCurrency : 'USD'
  if (userCurrency === 'ZMK') initialCurrency = 'ZMW'

  //states
  const [currency, setCurrency] = useState(initialCurrency)
  const [buttonValue, setButtonValue] = useState('monthly')
  const [proPriceDisplay, setProPriceDisplay] = useState(`${currency}_monthly`)
  const [proPrice, setProPrice] = useState(PRO[`${currency}_monthly`])
  const [corporatePriceDisplay, setCorporatePriceDisplay] = useState(`${currency}_monthly`)
  const [corporatePrice, setCorporatePrice] = useState(CORPORATE[`${currency}_monthly`])
  const [multiplier, setMultiplier] = useState(1)

  const litePoints = [
    'Unlimited Borrowers',
    'Unlimited Loans',
    'Unlimited Loan Statements'
  ]
  const proPoints = [
    '1 User',
    'Unlimited Borrowers',
    'Unlimited Loans',
    'Unlimited Loan Statements'
  ]
  const corporatePoints = [
    'All features of PRO',
    '5 Users',
    'Set User Access Levels',
    'Priority Support',
  ]

  const toggleButtons = [
    {value: 'monthly', text: "Monthly"},
    {value: 'yearly', text: "Yearly (20% Discount)"},
  ]

  const handleChange = (event, value, cur) => {
    const thisCurrency = cur ? cur : currency
    setButtonValue(value);
    setProPriceDisplay(`${thisCurrency}_${value}`);
    setProPrice(PRO[`${thisCurrency}_${value}`]);
    setCorporatePriceDisplay(`${thisCurrency}_${value}`);
    setCorporatePrice(CORPORATE[`${thisCurrency}_${value}`]);
    value === 'yearly' ? setMultiplier(12) : setMultiplier(1);
  };

  const useUSD =(e)=>{
    if(e.target.checked) {
      setCurrency('USD');
      handleChange(null, buttonValue, 'USD')
    }else{
      setCurrency(initialCurrency);
      handleChange(null, buttonValue, initialCurrency);
    }
  }

  return (
    <Box maxWidth={'100%'}>
      <Box sx={{justifyContent: 'center', display: 'flex'}}>
        <h1>LoanTabs Subscription Packages</h1>
      </Box>
      <Box display="flex" justifyContent={'center'} mb="10px" alignItems={'center'}>
        <ToggleButtons buttons={toggleButtons} handleChange={handleChange} value={buttonValue}/>
        {initialCurrency !=='USD' && <FormGroup
        sx={{ml: "40px"}}>
          <FormControlLabel control={
            <Checkbox 
              color="success"
              defaultChecked={false} 
              onChange={useUSD}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} 
          label="Pay in USD" />
        </FormGroup>}
      </Box>
      <Box gap='30px' m='20px' sx={{
        flexWrap: 'wrap', display: 'flex', justifyContent: 'center'
      }}>
          <PackageCard cardTitle={'Lite'} price={'Free'} perMonth={''}
            intro={"Free 30 Days Trial for New Users"} bullets={litePoints} backgroundColor={'pink'} />
          <PackageCard buttonText={'BUY PRO'} buttonColor={'black'}
            paymentAmount={proPrice * multiplier} cardTitle={'PRO'} currency={currency} 
            price={PRO[proPriceDisplay]} perMonth={' /month'}
            intro="For Small Money Lenders" bullets={proPoints} backgroundColor={colors.greenAccent[300]} />
          <PackageCard cardTitle='CORPORATE' currency={currency} 
            price={CORPORATE[corporatePriceDisplay]} 
            perMonth={'/month'} paymentAmount={corporatePrice * multiplier}
            intro="For Mid-Sized Lenders & Microfinances" bullets={corporatePoints} backgroundColor='yellow' 
            buttonText={'BUY CORPORATE'} />
      </Box>
      <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <h1>Have an inquiry? </h1>
        <Button onClick={()=>window.open('https://www.loantabs.com/support', '_blank')} color='success' variant='contained'>CONTACT US</Button>
      </Box>
    </Box>
  )
}

export default Packages