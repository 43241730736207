const loanTabsPackages = {
    PRO: {
        name: 'PRO',
        BWP_monthly: 232,
        BWP_yearly: 194,
        BWP_monthly_planId: "121409",
        GHS_monthly: 250,
        GHS_yearly: 210,
        GHS_monthly_planId: "121399",
        INR_monthly: 1400,
        INR_yearly: 1180,
        INR_monthly_planId: "121413",
        KES_monthly: 2200,
        KES_yearly: 1850,
        KES_monthly_planId: "121397",
        NGN_monthly: 22600,
        NGN_yearly: 19000,
        NGN_monthly_planId: "121408",
        TZS_monthly: 44000,
        TZS_yearly: 37000,
        TZS_monthly_planId: "121403",
        UGX_monthly: 57000,
        UGX_yearly: 47000,
        UGX_monthly_planId: "119602",
        USD_monthly: 15,
        USD_yearly: 12,
        USD_monthly_planId: "119585",
        ZAR_monthly: 318,
        ZAR_yearly: 270,
        ZAR_monthly_planId: "121412",
        ZMW_monthly: 437,
        ZMW_yearly: 370,
        ZMW_monthly_planId: "121404",
    },
    CORPORATE: {
        name: 'CORPORATE',
        BWP_monthly: 670,
        BWP_yearly: 563,
        BWP_monthly_planId: "121410",
        GHS_monthly: 720,
        GHS_yearly: 610,
        GHS_monthly_planId: "121402",
        INR_monthly: 4050,
        INR_yearly: 3410,
        INR_monthly_planId: "121414",
        KES_monthly: 6400,
        KES_yearly: 5400,
        KES_monthly_planId: "121398",
        NGN_monthly: 65000,
        NGN_yearly: 54600,
        NGN_monthly_planId: "121407",
        TZS_monthly: 127000,
        TZS_yearly: 107000,
        TZS_monthly_planId: "121401",
        UGX_monthly: 157000,
        UGX_yearly: 137000,
        UGX_monthly_planId: "119604",
        USD_monthly: 45,
        USD_yearly: 37,
        USD_monthly_planId: "119603",
        ZAR_monthly: 915,
        ZAR_yearly: 770,
        ZAR_monthly_planId: "121411",
        ZMW_monthly: 1260,
        ZMW_yearly: 1060,
        ZMW_monthly_planId: "121405",
    }
}

export {loanTabsPackages}