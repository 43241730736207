import React, {useEffect} from "react";
import { useField, useFormikContext } from "formik";
import moment from "moment";
import { useState } from "react";

export const DatePickerField = ({ ...props}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
   
  return (
    <input
      style={{width: '150px'}}
      type="date"
      max={props.max}
      min={props.min}
      // {...field}
      // {...props}
      // selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        // console.log('val::: ', val);
        // const date = new Date(val.target.value)
        setFieldValue(field.name, val);
        props.setTheDate && props.setTheDate(val.target.value)
      }}
      // dateFormat="MMMM d, yyyy"
    />
  );
};