//loansPanel
const loadingLoansPanel =[
    { 
        id: 'noDataAvailable',
        borrowerName: 'Loading...',
        interestRate: 'Loading...',
        duration: 'Loading...',
        status: 'Loading...',
        label: 'Loading...',
        principal: 0,
        amountDue: 0,
        balance: 0,
        totalPaid: 0

    }
]
const emptyLoansPanel =[
    { 
        id: 'noDataAvailable',
        borrowerName: 'none',
        interestRate: 'none',
        duration: 'none',
        status: 'none',
        label: 'none',
        principal: 0,
        amountDue: 0,
        balance: 0,
        totalPaid: 0
    }
]

//borrowers
const loadingBorrowers = [
    {
      id: 1,
      firstName: '-',
      otherName: 'Loading...',
      phoneNumber: 'Loading...',
      otherPhoneNumber: 'Loading...',
      label: 'Loading...',
    }
  ];
const emptyBorrowers = [
    {
      id: 1,
      firstName: '-',
      otherName: 'none',
      phoneNumber: 'none',
      otherPhoneNumber: 'none',
      label: 'none',
    }
  ];

export {loadingLoansPanel, emptyLoansPanel,
    loadingBorrowers, emptyBorrowers}