import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useContext, useEffect } from "react";
import {UserIDContext} from "../../../App"
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CreateUserAdminForm = ({initialUserAdmin, readyToEdit, editedUserAdmin}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [processing, setProcessing] = useState()
  const userID = useContext(UserIDContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //states
  const [checked, setChecked] = useState(!initialUserAdmin)

  //useEffects
  useEffect(()=>{
    if(initialUserAdmin){
      setProcessing('Save Changes')
      setChecked(false)
    }else{
      setProcessing('Create New Team Member')
    }
  },[initialUserAdmin])

  //functions
  const enableEditing =(e)=>{
    setChecked(e.target.checked);
  }
  
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a'

  const initialValues = {
    name: initialUserAdmin?.name  || "",
    phoneNumber: initialUserAdmin?.phoneNumber || "",
    email: initialUserAdmin?.email || "",
    type: initialUserAdmin?.type || "",
  };

  const saveToAmplify =  async(values) =>{
    const {name, phoneNumber, email, type} = values
    try{
      const createdUserAdmin = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          createUserAdmin(input: {
            userUserAdminsId: "${userID}",
            name: "${name}",
            email: "${email}",
            phoneNumber: "${phoneNumber}",
            type: "${type}"
          }) 
          {
            id
          }
        }`
      ))
      if(createdUserAdmin)
      {
        values.id = createdUserAdmin.data.createUserAdmin.id
        readyToEdit(values);
      }
    }
    catch(e){
      console.log('UserAdmin Creation Failed', e)
    }
  }

  const updateAmplify =  async(values) =>{
    const {name, phoneNumber, email, type} = values
    values.id = initialUserAdmin.id
    try{
      const updatedUserAdmin = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          updateUserAdmin(input: {
            id: "${initialUserAdmin.id}",
            email: "${email}",
            name: "${name}",
            phoneNumber: "${phoneNumber}",
            type: "${type}"
          }) 
          {
            id
          }
        }`
      ))
      if(updatedUserAdmin)
      {
        editedUserAdmin(values);
      }
    }
    catch(e){
      console.log('UserAdmin Editing Failed', e)
    }
  }

  const handleFormSubmit = (values) => {
    setProcessing('Processing...Please Wait...')
    initialUserAdmin ? updateAmplify(values) : saveToAmplify(values)
  };

  return (
    <Box width="100%" sx={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
      {initialUserAdmin && <FormGroup
        sx={{ml: "20px", mt: '10px'}}>
          <FormControlLabel control={
            <Checkbox 
              color="success"
              defaultChecked={false} 
              onChange={enableEditing}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} 
          label="Enable Editing" />
        </FormGroup>}
      <Box m="20px" maxWidth="700px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Name of Team Member"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  name="phoneNumber"
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 2" }}
                />
                <Box mt="-10px" sx={{gridColumn: 'span 4'}}>
                    <Typography color={colors.grey[200]} sx={{fontSize: '12px'}}>
                      You must provide a <span className="myBold">Name, Phone Number</span> and
                      <span className="myBold"> Email Address</span>. 
                      Everything else is Optional.
                    </Typography>
                </Box>
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Role/Designation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  name="type"
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" mt="50px" mb='80px' gap="30px">
                <Button type="submit" color="secondary" variant="contained" disabled={!checked}>
                  {processing}
                </Button>
                <Button onClick={()=> resetForm()} color="error" variant="outlined">
                  CANCEL
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const phoneRegExp = /^(?![A-Za-z]{1,9}$)[^A-Za-z]{9,}$/

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required")
});

export default CreateUserAdminForm;