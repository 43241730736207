import React, { useContext } from 'react'
import logo from '../assets/loantabs_logo.png'
import { Box, Button, Typography } from '@mui/material'
import { SignOutContext } from '../App'

function DeltedUser() {

  const signOut = useContext(SignOutContext)

  return (
    <Box display={'flex'} flexDirection={'column'} p='40px'
        alignItems={'center'}>
        <img src={logo} alt="LoanTabs Logo" />
        <Typography variant="h2">This Account was Deleted</Typography>
        <Button variant='contained' color='error'
          onClick={()=>signOut()}>Go back</Button>
    </Box>
  )
}

export default DeltedUser