import React, { useState, useEffect } from "react";

const NetworkChecker =()=> {
  	const [isOnline, setIsOnline] = useState(navigator.onLine);
  		
  	useEffect(() => {
    	function onlineHandler() {
      		setIsOnline(true);
    	}
	
    	function offlineHandler() {
      		setIsOnline(false);
    	}
	
    	window.addEventListener("online", onlineHandler);
    	window.addEventListener("offline", offlineHandler);

	
    	return () => {
      		window.removeEventListener("online", onlineHandler);
      		window.removeEventListener("offline", offlineHandler);
    	};
  	}, [isOnline]);

	
  	return (
    	<div>
      		{isOnline === false &&
        		<h2 style={{color: 'red', margin: 20, textAlign: "center"}}>You are offline. Internet Connection required.</h2>
      		}
    	</div>
  	);
}

export default NetworkChecker;