import React, { useEffect, createContext, useState } from 'react';
import { Amplify, API, graphqlOperation } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import AppSrc from './AppSrc';
import NetworkChecker from './components/NetworkChecker';
import moment from 'moment';
import Onboarding from './scenes/Onboarding';
import { currenciesObj } from './assets/currenciesObj';
import Loading from './components/Loading';
import LoanTabsAdmin from './appScenes/LoanTabsAdmin/LoanTabsAdmin';
import DeletedUser from './components/DeletedUser';

Amplify.configure(awsExports);

const SignOutContext = createContext()
const UserIDContext = createContext()
const UserEmailContext = createContext()
const UserTypeContext = createContext()
const UserTypeChangeDateContext = createContext()
const LoansContext = createContext()

function App({ signOut, user }) {

  const [userTypeAWS, setUserTypeAWS] = useState()
  const [userTypeLocal, setUserTypeLocal] = useState('lite')
  const today = moment()
  const currentDate = today.format('LL')
  const [daysLeft, setDaysLeft] = useState('wait')
  const [onBoardUser, setOnBoardUser] = useState()
  const [userName, setUserName] = useState()
  const [businessName, setBusinessName] = useState()
  const [accounts, setAccounts] = useState()
  const [userCurrency, setUserCurrency] = useState()
  const [loansContext, setLoansContext] = useState()
  const [decimalPoints, setDecimalPoints] = useState()
  const [requestForParenthood, setRequestForParenthood] = useState()
  const [childStatus, setChildStatus] = useState()
  const [userId, setUserId] = useState()
  const [userAdminId, setUserAdminId] = useState()
  const [deletedUser, setDeletedUser] = useState()
  const loantabsAdmin = user.attributes.email === 'support@loantabs.com' ? true : false
     
  useEffect(()=>{

    const createAccount = async(difference)=>{
      try{
        const createdAccount = await API.graphql(graphqlOperation(`mutation MyMutation {
          createAccount(input: {
            accountName: "Cash Account", 
            openingBalance: 0, 
            userAccountsId: "${user.attributes.sub}",
            currency: "UGX",
            accountStatus: "default"
          }) {
            id
          }
        }
        `))
        if(createdAccount){
          setAccounts([{
            id: createdAccount.data.createAccount.id,
            accountName: 'Cash Account',
            openingBalance: 0,
            currency: 'UGX',
          }])
          setDaysLeft(difference)
        };
      }
      catch(e){
        console.log('Error creating account', e)
      }
    }

    const checkIfChild = async()=>{
      try{
        const currentUserType = await API.graphql(graphqlOperation(`query MyQuery {
          getUser(id: "${user.attributes.sub}") 
          {
            status
            id
            deletedUser
          }
        }
        `));
        
        if(currentUserType.data.getUser?.deletedUser){
          setDeletedUser(true)
        }
        if(currentUserType.data.getUser?.status){
          const parentDetials = currentUserType.data.getUser?.status.split(";")
          setUserAdminId(parentDetials[2])
          checkUserType(parentDetials[0], parentDetials[1])
        }else{
          if(currentUserType.data.getUser?.id){
            checkUserType()
          }else{
            setUserId(user.attributes.sub)
            setOnBoardUser(true)
            setDaysLeft(30)
          };
        }
      }
      catch(e){
        console.log('ERROR: UNABLE TO CHECK USER TYPE::: ', e)
      }
    }
    
    const checkUserType = async(accessLevel, parentId)=> {
      const idToUse = parentId || user.attributes.sub
      try{
        const currentUserType = await API.graphql(graphqlOperation(`query MyQuery {
          getUser(id: "${idToUse}") 
          {
            userType
            businessName
            userTypeChangeDate
            userAttribue1
            userAttribue2
            username
            accounts {
              items {
                id
                accountName
                openingBalance
                currency
                accountStatus
              }
            }
          }
        }
        `));
        if(currentUserType.data.getUser){
          setUserId(idToUse)
          const userType = accessLevel || currentUserType.data.getUser.userType
          setUserTypeAWS(userType)
          setBusinessName(currentUserType.data.getUser.businessName)
          if(currentUserType.data.getUser.userAttribue2){
            const request = currentUserType.data.getUser.userAttribue2.split(';')
            setRequestForParenthood({accessLevel: request[0], parentEmail: request[1], 
              parentId: request[2], userAdminId: request[3]})
          }
          const username = currentUserType.data.getUser.username 
            || user.attributes.email.substring(0, user.attributes.email.indexOf('@'))
          setUserName(username)
          const currency = currentUserType.data.getUser.userAttribue1 || 'UGX'
          setUserCurrency(currency)
          setDecimalPoints(currenciesObj[currency].decimal_digits)
          const systemExpiryDate = moment(currentUserType.data.getUser.userTypeChangeDate, 'MMMM DD, YYYY')
          const difference = Math.max(systemExpiryDate.diff(currentDate, 'days'), 0);
          if(currentUserType.data.getUser.accounts.items.length > 0){
            setAccounts(currentUserType.data.getUser.accounts.items)
            setDaysLeft(difference)
          }else { 
            createAccount(difference)
          }
        }
      }
      catch(e){
        console.log('ERROR: UNABLE TO CHECK USER TYPE::: ', e)
      }
    }
    checkIfChild()
    // eslint-disable-next-line 
  }, [])

  useEffect(() =>{
    const confirmUserType = async()=> {
      try{
        const userType = await API.graphql(graphqlOperation(`query MyQuery {
          getUser(id: "${user.attributes.sub}") 
          {
            userType
          }
        }
        `));
        if(userType){
          setUserTypeAWS(userType.data.getUser.userType)
        };
      }
      catch(e){
        console.log(e)
      }
    }

    const userTypesArray = ['blocked', 'subscriptionExpired', 'PRO', 'CORPORATE']
    const userTypeSet = new Set(userTypesArray)
    if(userTypeSet.has(userTypeLocal)){
      confirmUserType()
    }
  },[userTypeLocal])

  useEffect(() =>{
    const getChildUserDetails = async()=> {
      try{
        const currentUserType = await API.graphql(graphqlOperation(`query MyQuery {
          getUser(id: "${childStatus.parentId}") 
          {
            businessName
            userTypeChangeDate
            userAttribue1
            accounts {
              items {
                id
                accountName
                openingBalance
                currency
                accountStatus
              }
            }
          }
        }
        `));
        if(currentUserType.data.getUser){
          setUserId(childStatus.parentId)
          setRequestForParenthood(null)
          setUserTypeAWS(childStatus.accessLevel)
          setBusinessName(currentUserType.data.getUser.businessName)
          const currency = currentUserType.data.getUser.userAttribue1 || 'UGX'
          setUserCurrency(currency)
          setDecimalPoints(currenciesObj[currency].decimal_digits)
          const systemExpiryDate = moment(currentUserType.data.getUser.userTypeChangeDate, 'MMMM DD, YYYY')
          const difference = systemExpiryDate.diff(currentDate, 'days');
          if(currentUserType.data.getUser.accounts.items.length > 0){
            setAccounts(currentUserType.data.getUser.accounts.items)
            setDaysLeft(difference)
          }
        }
      }
      catch(e){
        console.log('ERROR: UNABLE TO GET USER DETAILS::: ', e)
      }
    }
    childStatus && getChildUserDetails()
  }, [childStatus])

  return (
    <>
      {deletedUser && <>
        <SignOutContext.Provider value={signOut}>
          <DeletedUser />
        </SignOutContext.Provider>
      </>}
      {daysLeft === 'wait' && <Loading />}
      { daysLeft !== 'wait' && !onBoardUser && 
        !deletedUser &&
        <SignOutContext.Provider value={signOut}>
          <UserIDContext.Provider value={userId}>
            <UserEmailContext.Provider value={user.attributes.email}>
              <UserTypeContext.Provider value={{userTypeAWS, setUserTypeAWS, accounts, userCurrency,
                setUserTypeLocal, userName, businessName, setAccounts, setDaysLeft, decimalPoints,
                setBusinessName, setUserCurrency, setDecimalPoints, requestForParenthood, 
                setRequestForParenthood, setChildStatus, userAdminId}}>
                <UserTypeChangeDateContext.Provider value={{daysLeft}}>
                  <LoansContext.Provider value={{loansContext, setLoansContext}}>
                    <NetworkChecker />
                    {loantabsAdmin && <LoanTabsAdmin />}
                    {!loantabsAdmin && <AppSrc/>}
                  </LoansContext.Provider>
                </UserTypeChangeDateContext.Provider>
              </UserTypeContext.Provider>
            </UserEmailContext.Provider>
          </UserIDContext.Provider>
        </SignOutContext.Provider>
      }
      {onBoardUser && <Onboarding userId={user.attributes.sub} email={user.attributes.email}
        setOnBoardUser={setOnBoardUser} setUserName={setUserName} setBusinessName={setBusinessName}
        setUserTypeAWS={setUserTypeAWS} signOut={signOut} setAccounts={setAccounts}
        setUserCurrency={setUserCurrency}/>} 
    </>
  );
}

export default withAuthenticator(App);
export {SignOutContext, UserIDContext, UserEmailContext, UserTypeContext, 
  UserTypeChangeDateContext, LoansContext}
