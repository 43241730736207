import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonalAddIcon from "@mui/icons-material/PersonOutlined";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import RedeemIcon from '@mui/icons-material/Redeem';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SettingsIcon from '@mui/icons-material/Settings';

const getNavItems = (reportsPanel) =>{
    return [
        {head: 'MENU', title: 'Dashboard', to: "/dashboard", icon: <HomeOutlinedIcon />},
        {head: null, title: 'Packages', to: "/packages", icon: <RedeemIcon />},
        {head: 'BORROWERS', title: 'Add Borrower', to: "/createBorrower", icon: <PersonalAddIcon />},
        {head: null, title: 'Manage Borrowers', to: '/manageBorrowers', icon: <PeopleOutlinedIcon />},
        {head: 'LOANS', title: 'New Loan', to: "/newLoan", icon: <MonetizationOnIcon />},
        {head: null, title: 'Loans Panel', to: "/loansPanel", icon: <ReceiptOutlinedIcon />},
        {head: 'TEAM', title: 'New Team Member', to: "/createUserAdmin", icon: <GroupAddIcon />},
        {head: null, title: 'Manage Team', to: "/manageUserAdmins", icon: <Diversity3Icon />},
        {head: 'ACCOUNTS', title: 'Create Account', to: "/createAccount", icon: <AddCardIcon />},
        {head: null, title: 'Manage Accounts', to: "/manageAccounts", icon: <AccountBalanceIcon />},
        {head: 'REPORTS', title: 'Reports Panel', to: reportsPanel, icon: <AssessmentIcon />},
        {head: 'SETTINGS', title: 'Settings', to: "/settings", icon: <SettingsIcon />},
        {head: 'SUPPORT', title: 'Contact Support', link: "https://www.loantabs.com/support", icon: <HeadsetMicIcon />},
        {head: null, title: 'Video Tutorials', link: "https://www.loantabs.com/video-tutorials/", icon: <YouTubeIcon />},
    ];
}
const goToLink = (link) => {
    window.open(link)
}

export {getNavItems, goToLink}