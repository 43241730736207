import { Box, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'

function UsersData({users}) {

    const columns = [
       {
           field: "createdAt", 
           headerName: "SIGN-UP DATE",
           flex: 1,
           type: 'date',
           renderCell: (params)=>{
            return params.row.createdAt.toLocaleDateString("en-UK", { day: "2-digit", month: "short", year: "2-digit" })
           }
       },
       {
           field: "email", 
           headerName: "EMAIL",
           flex: 1
       },
       {
           field: "daysLeft", 
           headerName: "SUBSCRIPTION DAYS LEFT",
           flex: 1
       },
    ]

  return (<>
    {users && <Box
        sx={{
            "& .MuiDataGrid-columnHeaders": {
                fontWeight: 'bold',
                color: 'blue',
              },
        }}>
        <Typography component="span" variant="h4">Number of Users: {users.length}</Typography>
        <DataGrid 
            columns={columns} rows={users}
            initialState={{
                sorting: {
                    sortModel: [{field: 'createdAt', sort: 'desc'}],
                }
            }}
        />
    </Box>}
  </>)
}

export default UsersData