import * as React from 'react';
import {useTheme, Box, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCardIcon from '@mui/icons-material/AddCard';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from "aws-amplify";
//component imports
import { completeTransactions } from '../../../assets/configureAccounts';
import MakeTransaction from '../MakeTransaction';
import { tokens } from '../../../theme';
import { UserTypeContext } from '../../../App';

export default function ViewTransactions ({transactions, openingBalance, createdAt, accountId,
    hidePanel, accountName, includeNewTransaction, updateTransactions, deleteTransaction }) {
  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});
  const [depositing, setDepositing] = useState(false)
  const [processing, setProcessing] = useState('SAVE')
  const [editableDebit, setEditableDebit] = useState(false)
  const [editableCredit, setEditableCredit] = useState(false)
  const [disableEditButton, setDisableEditButton] = useState(false)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {decimalPoints} = useContext(UserTypeContext);

  useEffect(()=>{
    if(transactions){
      setRows(completeTransactions(transactions, openingBalance, createdAt))
     }
  },[]) 

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (params) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => {
    setEditableCredit(false);
    setEditableDebit(false);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // handling delete
  const handleDeleteClick = (params) =>()=> {
    const newRows = rows.filter((row) => row.id !== params.id);
    deleteAWSTransaction(params, newRows);
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  // funciton to update the balance column
  const updateBalance = (updatedRows) => {
    updatedRows.sort((a, b) => b?.date - a?.date);
    const updatedRowsBalance = []
    updatedRowsBalance.push(updatedRows[updatedRows.length - 1]);
    for(let i = updatedRows.length-1; i >0 ; i--) {
      updatedRows[i-1].balance = parseFloat(updatedRows[i]?.balance) + parseFloat(updatedRows[i-1]?.debit)
      - parseFloat(updatedRows[i-1]?.credit)
      updatedRowsBalance.push(updatedRows[i-1])
    }
    setRows(updatedRowsBalance.reverse());
  }

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    updateAWSTransactions(updatedRow, updatedRows);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const editableRow = (params) => {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: '#fff',
          }}
          onClick={()=>{
            handleSaveClick(params.id)
            setDisableEditButton(false)
          }}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textInfo"
          onClick={()=>{
            handleCancelClick(params.id)
            setDisableEditButton(false)
          }}
          color="inherit"
        />
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textInfo"
        disabled={disableEditButton}
        onClick={()=>{
          if((params.row.transactionType === 'deposit') || params.row.transactionType === 'income'){
            setEditableDebit(true)
            setEditableCredit(false)
          } 
          if((params.row.transactionType === 'withdrawal') || (params.row.transactionType === 'expense')){
            setEditableDebit(false)
            setEditableCredit(true)
          }
          setDisableEditButton(true)
          handleEditClick(params)
        }}
        color="#fff"
      />,
      <GridActionsCellItem
        icon={<DeleteOutlineIcon />}
        label="Delete"
        onClick={handleDeleteClick(params)}
        color="inherit"
      />
    ];
  }
  //create Transaction in Datagrid
  const createTransaction = (transaction) => {
    setRows((oldRows) => {
      const newRows = [...oldRows, transaction]
      return completeTransactions(newRows, openingBalance, createdAt)
    });
  };

//Create Deposit Function
  const createDeposit =  async(values) =>{
    setProcessing('SAVING...')
    const {date, narration, type, expenseType} = values
    const amount = parseFloat(values.amount.replace(/,/g, ''))
    try{
      const createdTransaction = await API.graphql(graphqlOperation(`
        mutation MyMutation {
          createTransaction(input: {
            accountTransactionsId: "${accountId}", 
            amount: ${amount}, 
            attribute1: "${expenseType}",
            date: "${date}", 
            details: "${narration}",
            type: "${type}"
          }) {
            id
          }
        }
      `));
      if (createdTransaction){
        setProcessing('SUCCESS')
        const transactionDate = new Date(date)
        const transactionId = createdTransaction.data.createTransaction.id
        const debit = (type === 'deposit') || (type === 'income') ? amount : 0
        const credit = (type === 'withdrawal') || (type === 'expense') ? amount : 0
        const transaction = {id: transactionId, date: transactionDate, details: narration, 
          debit, credit, transactionType: type, client: '-'}
        includeNewTransaction(accountId, transaction, 'Transaction Successful')
        createTransaction(transaction)
        setTimeout(() => {
          setProcessing('SAVE')
          setDepositing(false)
        }, 1500)
      }
    }catch(e){
      console.log('error creating deposit: ',e)
    }
  }

//update Deposit function
  const updateAWSTransactions =  async(values, updatedRows) =>{
    const {id, credit, debit, date, details, transactionType} = values
    const transactionValue = (transactionType === 'deposit') ||
    (transactionType === 'income') ? debit : credit
    const amount = typeof(transactionValue) === 'number' ? transactionValue 
      : parseFloat(transactionValue.replace(/,/g, ''))
    try{
      const updatedTransaction = await API.graphql(graphqlOperation(`
        mutation MyMutation {
          updateTransaction(input: {
            id: "${id}", 
            date: "${date}", 
            details: "${details}",
            amount: ${amount}
          }) {
            id
          }
        }
      `));
      if (updatedTransaction){
        const transaction = {id, date, details, transactionType, client: '-'}
        if((transactionType === 'deposit') || (transactionType === 'income')){
          transaction.debit = amount
          transaction.credit = 0
        }else {
          transaction.debit = 0
          transaction.credit = amount
        }
        updateBalance(updatedRows);
        updateTransactions(accountId, transaction, `${transactionType} Details Updated`)
      }
    }catch(e){
      console.log('error updating transaction: ',e)
    }
  }

//delete Deposit function
  const deleteAWSTransaction =  async(values, updatedRows) =>{
    const {id, debit, credit, transactionType} = values.row
    const transactionValue = (transactionType === 'deposit') ||
      (transactionType === 'income') ? debit : credit
    let amount = 0
    if (transactionValue){
      amount = typeof(transactionValue) === 'number' ? transactionValue 
        : parseFloat(transactionValue.replace(/,/g, ''))
    }
    try{
      const deletedTransaction = await API.graphql(graphqlOperation(`
        mutation MyMutation {
          deleteTransaction(input: {id: "${id}"}) {
            id
          }
        }      
      `));
      if (deletedTransaction){
        const transactionId = id
        const transaction = {accountId, transactionId}
        if((transactionType === 'deposit') || (transactionType === 'income')){
          transaction.debit = amount
          transaction.credit = 0
        }else {
          transaction.debit = 0
          transaction.credit = amount
        }
        updateBalance(updatedRows);
        deleteTransaction(transaction)
      }
    }catch(e){
      console.log('error deleting deposit: ',e)
    }
  }

  const columns = [
    {
        field: "date",
        sortable: false,
        headerName: "Date",
        flex: 1,
        type: 'date',
        cellClassName: "name-column--cell",
        renderCell: (params)=>{
          const options = { day: "2-digit", month: "short", year: "2-digit" };
          return params.row.date ? params.row.date.toLocaleDateString("en-UK", options) : ''
        },
        editable: true,
    },
    {
        field: "details",
        sortable: false,
        headerName: "Transaction Details",
        flex: 1.5,
        align: 'left',
        headerAlign: 'left',
        editable: true,
    },
    {
        field: "transactionType",
        sortable: false,
        headerName: "Transaction Type",
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        type: 'text',
    },
    {
        field: "client",
        sortable: false,
        headerName: "Client / Staff",
        flex: 1,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: "debit",
        sortable: false,
        headerName: "Debit",
        editable: editableDebit,
        flex: 1,
        align: 'right',
        cellClassName: 'debit',
        headerAlign: 'right',
        renderCell: (params)=>{
          return params?.row?.debit ? params.row.debit.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
}) : ''
        }
    },
    {
        field: "credit",
        sortable: false,
        headerName: "Credit",
        editable: editableCredit,
        cellClassName: 'credit',
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params)=>{
          return params?.row?.credit ? params.row.credit.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
}) : ''
        }
    },
    {
        field: "balance",
        sortable: false,
        headerName: "Balance",
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params)=>{
          return params?.row?.balance ? params.row.balance.toLocaleString('en-US', { maximumFractionDigits: decimalPoints
}) : ''
        }
    },
    {
      field: 'actions',
      sortable: false,
      type: 'actions',
      headerName: 'Edit/Delete',
      // width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return (params.row.transactionType === 'deposit') || 
        (params.row.transactionType === 'income') || (params.row.transactionType === 'expense') 
        || (params.row.transactionType === 'withdrawal') ? editableRow(params) : []
      }
    }
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          mt: "-10px",
          ml: "20px",
          gap: "10px"
        }}
      >
        <Typography sx={{color: `${colors.greenAccent[200]} !important`,}}>TYPE TO SEARCH... </Typography>
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        // height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.success',
        },
        '& .textInfo': {
          color: 'text.info',
        },
        "& .MuiDataGrid-root": {
          border: 'none',
        },
        " .debit": {
          color: colors.greenAccent[200],
        },
        " .credit": {
          color: colors.redAccent[200],
        },
      }}
    >  
      <Box display="flex" flexDirection={'column'} mb="30px">
        <Box display={"flex"} flexDirection={'row'} justifyContent={'space-between'}>
          <Box display={"flex"} flexDirection={'row'} gap={'20px'} alignItems={'baseline'}>
            <Typography variant="h4" color={colors.greenAccent[200]}>Account Name:</Typography>
            <Typography variant="h3">{accountName}</Typography>
          </Box>
          <Button variant={'outlined'} color="error"
              onClick={()=> hidePanel(false)}>Go Back</Button>
        </Box>
        <Box display={"flex"} flexDirection={'row'} gap={'20px'} mt="20px">
          <Button variant="outlined" color="info" mr="20px" 
            disabled={depositing}
            onClick={()=>setDepositing('expense')}>Add Expense</Button>
          <Button variant="outlined" color="info" mr="20px" 
            disabled={depositing}
            onClick={()=>setDepositing('income')}>Add Other Income</Button>
          <Button variant="outlined" color="info" mr="20px" 
            disabled={depositing}
            onClick={()=>setDepositing('deposit')}>Add Deposit</Button>
          <Button variant="outlined" color="info"  
            disabled={depositing}
            onClick={()=>setDepositing('withdrawal')}>Make Withdrawal</Button>
        </Box>
      </Box> 
      {depositing && <MakeTransaction type={depositing} 
        cancelTransaction={() => setDepositing(false)} handleValues={createDeposit} 
        processing={processing}/>}
      {rows &&
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: QuickSearchToolbar }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
            sorting: {
                sortModel: [{field: 'date', sort: 'desc'}],
                sortable: false,
            },
        }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />}
    </Box>
  );
}