import React, { useEffect, useState } from 'react'
import { tokens } from '../theme';
import { useTheme, Box, Typography, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { LoansContext, UserTypeChangeDateContext, UserTypeContext } from '../App';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PerformanceSummary from './DashBoardComponents/PerformanceSummary'
import QuickLinks from './DashBoardComponents/QuickLinks';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
};
}

function Dashboard() {
  const {daysLeft} = useContext(UserTypeChangeDateContext)
  const {userTypeAWS} = useContext(UserTypeContext)
  const {loansContext} = useContext(LoansContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

  //states
  const [value, setValue] = useState(0);

  //useEffect
  useEffect(()=>{
    !loansContext && navigate('/')
  },[])

  //functions
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width="100%" mb="80px" sx={{justifyContent: 'center', display: 'flex'}}>
      <Box mt='20px' sx={{display: 'flex', flexDirection: 'column'}}>
        {userTypeAWS === 'blocked' && 
        <Box>
          <Typography sx={{color: 'red'}}>FREE TRIAL EXPIRED. PLEASE BUY SUBSCRIPTION</Typography>
          <Button onClick={()=>navigate('/packages')} sx={{width: '200px', marginTop: '10px'}}
          color='info' variant='contained'>BUY SUBSCRIPTION</Button>
        </Box> 
        }
        {userTypeAWS === 'subscriptionExpired' && 
        <Box>
          <Typography sx={{color: 'red'}}>SUBSCRIPTION EXPIRED. PLEASE RENEW</Typography>
          <Button onClick={()=>navigate('/packages')} sx={{width: '200px', marginTop: '10px'}}
          color='info' variant='contained'>RENEW SUBSCRIPTION</Button>
        </Box> 
        }
        {userTypeAWS === 'lite' && <Typography sx={{color: colors.greenAccent[300]}}>FREE USER. {daysLeft} DAY(S) LEFT.</Typography> }
        {userTypeAWS === 'PRO' && <Typography sx={{color: colors.greenAccent[300]}}>PRO USER. {daysLeft} DAYS LEFT.</Typography> }
        {userTypeAWS === 'CORPORATE' && <Typography sx={{color: colors.greenAccent[300]}}>CORPORATE USER. {daysLeft} DAYS LEFT.</Typography> }
        
        {loansContext && <>
        <Box mt="20px" sx={{ borderBottom: 1, borderColor: 'divider'}} >
          <Tabs value={value} onChange={handleChange} textColor="secondary"
            indicatorColor="secondary" variant="fullWidth"
            scrollButtons="auto">
            <Tab sx={{fontSize: 16}} label={<Typography 
              sx={{fontSize: {xs: 16, md: 24}}}
              >Loans Performance Summary</Typography>} {...a11yProps(0)} />
            <Tab sx={{fontSize: 16}} label={<Typography 
              sx={{fontSize: {xs: 16, md: 24}}}
              >Quick Links</Typography>} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <PerformanceSummary />
        </CustomTabPanel><CustomTabPanel value={value} index={1}>
          <QuickLinks />
        </CustomTabPanel>
        </>}
      </Box>
    </Box>
  )
}

export default Dashboard