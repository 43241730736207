import { API, graphqlOperation } from 'aws-amplify';
import { UserIDContext } from '../App';
import { useContext } from 'react';
import { UserTypeContext } from '../App';
import React from 'react';
import { useEffect } from 'react';
import moment from 'moment'
import { UserTypeChangeDateContext } from '../App';

const PointsManager = ()=>{
    const userID = useContext(UserIDContext)
    const {userTypeAWS, setUserTypeAWS, setUserTypeLocal} = useContext(UserTypeContext)
    const {daysLeft} = useContext(UserTypeChangeDateContext)

    useEffect(()=>{
        const endSubscription = () =>{
            setUserTypeAWS('subscriptionExpired')
            setUserTypeLocal('subscriptionExpired')
        }
    
        const blockUser = async()=>{
            setUserTypeAWS('blocked')
            setUserTypeLocal('blocked')
        }

        const checkDate = async()=> {
            if(daysLeft <1){
                userTypeAWS === 'PRO' && endSubscription()
                userTypeAWS === 'CORPORATE' && endSubscription()
                userTypeAWS === 'lite' && blockUser()
                userTypeAWS === 'viewer' && endSubscription()
                userTypeAWS === 'editor' && endSubscription()
            }
            userTypeAWS === 'deletedAccount' && endSubscription()
        }
        checkDate();
    },[])

    return( 
        <></>
    )
}

export default PointsManager 