import { Typography, Box, useTheme, Button } from '@mui/material'
import React, { useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
//component imports
import { LoansContext, UserTypeContext } from '../../App'
import { tokens } from '../../theme'

function AllLoans({totalCurrentLoans, totalOverdueLoans, totalCurrentWithMissedPayments}) {
//variables
  const {loansContext} = useContext(LoansContext)
  const {decimalPoints} = useContext(UserTypeContext);

  const navigate = useNavigate()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
//functions
    const columnTotal = (column) => {
        return loansContext.reduce((acc, loan) => {      
        return acc + loan[column]
        },0)
    }

    const columns = [
      {
        field: "status",
        headerName: "Loans Status",
        flex: 1,
        type: 'text',
      },
      {
        field: "balance",
        headerName: "Total Loans",
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        renderCell: (params) => {
          return !isNaN(params.row.balance) ? 
            params.row.balance.toLocaleString('en-US',{maximumFractionDigits: decimalPoints})
            : 0
        }
      },
    ]

    const rows = [
      {id:1, status: 'Current', balance: totalCurrentLoans},
      {id:2, status: 'Overdue', balance: totalOverdueLoans},
      {id:3, status: 'Missed Payments', balance: totalCurrentWithMissedPayments},
    ]

  return (
    <Box mb="10px" display="flex" gap="10px" flexDirection={'column'} mt="5px"
        >
        <Box display="flex" flexDirection="row" gap="10px" mt="10px">
          <Typography  color={colors.greenAccent[500]}>
            Total Principal Released:</Typography>
          <Typography >
            {` ${columnTotal('principal').toLocaleString('en-US', {
              maximumFractionDigits: decimalPoints
            })}`}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="10px">
            <Typography  color={colors.greenAccent[500]}>
                Total Interest Charged:</Typography>
            <Typography >
                {` ${(columnTotal('balance') + columnTotal('totalPaid')
                - columnTotal('principal')).toLocaleString('en-US', {
                maximumFractionDigits: decimalPoints
                })}`}
            </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="10px">
            <Typography  color={colors.greenAccent[500]}>
                Total Amount Due:</Typography>
            <Typography >
            {` ${columnTotal('amountDue').toLocaleString('en-US', {
              maximumFractionDigits: decimalPoints
            })}`}
            </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="10px">
            <Typography  color={colors.greenAccent[500]}>
                Total Payments Received:</Typography>
            <Typography >
            {` ${columnTotal('totalPaid').toLocaleString('en-US', {
              maximumFractionDigits: decimalPoints
            })}`}
            </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="10px">
          <Typography  color={colors.greenAccent[500]}>
            Total Loans Balance:</Typography>
          <Typography >
            {` ${columnTotal('balance').toLocaleString('en-US', {
              maximumFractionDigits: decimalPoints
            })}`}
          </Typography>
        </Box>
        <Box
          sx={{
            height: '100%', 
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal"
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important"
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              height: "30px"
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            // "& .normalLineHeight": {
            //   height: `200px !important`,
            // },
          }}
          >
          <Box width="99%">
            <DataGrid rows={rows} columns={columns}
              hideFooter={true}
            />
          </Box>
        </Box>
    </Box>
  )
}

export default AllLoans