import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ToggleButtons({buttons, handleChange, value}) {
  
  return (
    <ToggleButtonGroup
      color="warning"
      size="large"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >{buttons.map((button, i)=>{
        return (<ToggleButton key={i} value={button.value}>{button.text}</ToggleButton>)
      })}
    </ToggleButtonGroup>
  );
}