import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { UserTypeContext, UserTypeChangeDateContext } from '../App'

function PaymentReceivedPRO() {
  const {userTypeAWS} = useContext(UserTypeContext)

  const viewContext = ()=>{
    
  }

  return (
    <Box maxWidth={'100%'}>
      <Box sx={{justifyContent: 'center', display: 'flex'}}>
        <h1>Loan<span style={{color: '#70d8bd'}}>Tabs</span> PRO</h1>
      </Box>
    </Box>
  )
}

export default PaymentReceivedPRO