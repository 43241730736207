import React, { useContext, useState } from "react";
import { Box,  Button,  Typography,  useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { tokens } from "../theme";
import '../assets/custom.css'
import '../index.css'
import { dateToday } from "../assets/getDate";
import { UserTypeContext } from "../App";

const LoanScheduleForLumpSum = ({loanData, LoanAmount, InterestRate, startDate, LoanEndDate,
  TotalPayment, noOfInstallments, Period, createLoan, Fees, borrower, loanCurrency,
  durationToDisplay, durationFormatToDisplay}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const gridRef = useGridApiRef()
  const [printWidth, setPrintWidth] = useState(145)
  const [printHeaderGap, setPrintHeaderGap] = useState('80px')
  // eslint-disable-next-line
  const [printState, setPrintState] = useState(true)
  const {businessName} = useContext(UserTypeContext)
  const hasBusinessName = businessName || (businessName !== '') ? businessName : '.' 
  
  const DataGridTitle = ()=>{
    return(
      <Box className="dataGridTitle" mb='30px'>
        <Box display='flex' flexDirection='column' alignItems='space-between'>
          {printWidth !== 145 && <Box display='flex' justifyContent='space-between' gap='20px' mt='-30px' mb='10px'>
              <p style={{color: 'blue', textAlign: 'center'}}>Created using Loan Management Software from <u>www.LoanTabs.com</u></p>
              <p>Printed on: {dateToday}</p>
          </Box>}
          <Box display='flex' flexDirection={'column'} mb="20px">
            <Typography variant="h2" className="printOnly" 
              sx={{pb: "10px", borderBottom: '1px solid black', mb: "20px"}}>
              {`${hasBusinessName.toUpperCase()}`}</Typography>
            <Typography variant='h3'> LOAN PAYMENT SCHEDULE</Typography>
          </Box>
          <Box display='flex' justifyContent='flex-start' gap={printHeaderGap}>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
              <Typography >Borrower:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{borrower.toUpperCase()}</Typography></Typography>
              <Typography >Loan Amount:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{`${loanCurrency} ${LoanAmount}`}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
              <Typography>Loan Start Date:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{startDate}</Typography></Typography>
              <Typography >Interest Rate:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{InterestRate}% {Period}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
            <Typography >Loan End Date:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{LoanEndDate}</Typography></Typography>
              <Typography >Total Payment:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{`${loanCurrency} ${TotalPayment}`}</Typography></Typography>
            </Box>
            <Box display='flex' gap='20px' sx={{flexDirection: 'column'}}>
            <Typography >Loan Duration:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{durationToDisplay} {durationFormatToDisplay}</Typography></Typography>
              {Fees !== '0' ? <Typography >Loan Fees:<br/>
                <Typography
                component={'span'} color={printWidth === 145 ? colors.greenAccent[400] : '#000'}
                variant="h5">{`${loanCurrency} ${Fees}`}</Typography></Typography> : <Box>-</Box>}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const columns = [
    
    {
      field: "id",
      headerName: "#",
      cellClassName: "name-column--cell",
      width: 40
      
    },
    {
      field: "date",
      headerName: "Date",
      width: printWidth,
      cellClassName: printWidth === 145 ? "name-column--cell": '' ,
      align: 'center',
      headerAlign: 'center',
      flex: printWidth === 145 ? 1 : ''
    },
    {
      field: "principal",
      headerName: "Beginning Balance",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      flex: printWidth === 145 ? 1 : ''
    },
    {
      field: "interest",
      headerName: "Interest",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      flex: printWidth === 145 ? 1 : ''
    },
    {
      field: "amountToPay",
      headerName: "Amount Due",
      width: printWidth,
      align: 'center',
      headerAlign: 'center',
      flex: printWidth === 145 ? 1 : ''
    },
    // {
    //   field: "label",
    //   headerName: "",
    //   flex: 2,
    //   renderCell: ({ row: { label } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         cursor='pointer'
    //         // justifyContent="center"
    //         backgroundColor= {colors.greenAccent[600]}
    //         borderRadius="4px"
    //       >
    //         <AddCircleOutlineIcon />
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           Create a Loan
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px" id='schedule'>
      
      {/* <Header title="Loan Schedule" subtitle={'Summary of the Loan Details:'}/> */}
        <Box display='flex' justifyContent="flex-start" gap='40px' m='-53px 0 0 210px' className='printNot'>
          <Button
            // onClick={()=>{gridRef.current.exportDataAsPrint({
              //   fields: ['id', 'date', 'totalPayment'],
            // })}}
            onClick={()=>{
              // setPrintState(true)
              setPrintWidth(140)
              setPrintHeaderGap('45px')
              setTimeout(()=>window.print(), 100) 
              setTimeout(()=>{setPrintWidth(145); setPrintHeaderGap('80px');}, 100) 
              // setTimeout(()=>setPrintState(true), 500) 
            }}
            type="button" variant="outlined" color="info">
            PRINT PAYMENT SCHEDULE</Button>
          <Button onClick={createLoan} type="button" color="success" variant="contained">
                  CONFIRM & SAVE LOAN
          </Button>
        </Box>
        <Box
          m="40px 0 40px 0"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid rows={loanData} columns={columns}
            apiRef={gridRef} 
            components={{Toolbar: DataGridTitle}}
            autoHeight={true}
            hideFooter={true}
            sx={{
              '@media print': {
                '.MuiDataGrid-main': {
                  color: 'rgba(0, 0, 0, 0.87)',
                },
                '.dataGridTitle': {display: 'block'},
              },
            }}
            columnVisibilityModel={{
              principal: printState,
              interest: printState
            }}
          />
      </Box>
    </Box>
  );
};

export default LoanScheduleForLumpSum;