const expenseCategories = [
    "Bad Debts (write-offs)",
    "Debt Collection Expenses",
    "Accommodation",
    "Accounting Expenses",
    "Advertising and Marketing",
    "Bank Fees and Charges",
    "Computer and Software",
    "Depreciation",
    "Entertainment",
    "Equipment and Furniture",
    "Food and Drinks",
    "Insurance",
    "Interest Expenses",
    "Legal and Professional Fees",
    "Medical Services",
    "Miscellaneous / Other",
    "Phone and Communication",
    "Printing",
    "Rent",
    "Repairs and Maintenance",
    "Salaries and Allowances",
    "Stationery",
    "Taxes and Licenses",
    "Trainings, Conferences & Meetings",
    "Travel Expenses",
    "Utilities",
    "Vehicle Repairs & Maintenance",
]

const businessIncomeTypes = [
    "Collateral Sale",
    "Capital Gains",
    "Dividends",
    "Grants",
    "Interest Received",
    "Miscellaneous / Other",
    "Membership Fee",
    "Motor Vehicle Sale",
    "Passive Activities",
    "Rental Income",
    "Securities Sale",
    "Stock Options"
  ];

export {expenseCategories, businessIncomeTypes} 
