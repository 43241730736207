import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { tokens } from '../theme';

function DashboardCard({cardTitle, icon, onClick}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
      
  return (
    <Box width={'150px'} onClick={onClick} sx={{
        p: '20px', color: colors.grey[100], borderRadius: "20px", 
        justifyContent: 'space-between', display: 'flex', flexDirection: 'column', alignContent: 'center',
        cursor: 'pointer'
    }}>
        {icon}
        <Typography sx={{fontWeight: 600, fontSize: '18px', display: 'flex', textAlign: 'center'}}>{cardTitle}</Typography>
    </Box>
  )
}

export default DashboardCard