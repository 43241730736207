import { useEffect, useState } from "react";
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { assetPlaceHolderImage } from "../assets/uploads3";

const UploadFile = ({uploadLabel, setUploadFile, placeholderImage,
  handleSave, req, setRes, res})=>{
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('SAVE')
  const [fileLink, setFileLink] = useState()
  const [editing, setEditing] = useState()

  useEffect(()=>{
    if(res){
      setFileLink(true)
      setStatus('SAVE')
      setSelectedImage(res)
    }
  },[res])

  const handleImageUpload = (event) => {
    setError(null)
    const file = event.target.files[0];
    if (file) {
      // Check if the file size is less than or equal to 10MB (10 * 1024 * 1024 bytes)
      if (file.size <= 10 * 1024 * 1024) {
        const reader = new FileReader();
        reader.onload = function(event) {
          setSelectedImage(event.target.result);
          setUploadFile(event.target.result);
          setFileLink(null)
        };
        reader.readAsDataURL(file);
      } else {
        setError('File size exceeds the maximum limit of 10MB.');
      }
    }
  };

  return (
    
      <Box gap="10px" display='flex' flexDirection='column'>
        <Typography>{uploadLabel}</Typography>
        {((placeholderImage === assetPlaceHolderImage) 
          || editing)
          && <input type="file" onChange={handleImageUpload} />}
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {!selectedImage && placeholderImage && 
        <Box>
          <img 
            src={placeholderImage} alt="Unable to preview" 
            style={{ width: '180px', height: 'auto',  maxHeight: "200px",
            objectFit: 'contain', marginTop: "5px"}} 
          />
          {placeholderImage !== assetPlaceHolderImage 
          && <Box display={'flex'} gap={'30px'}>
            <Button variant="contained" color='success'
              onClick={()=>window.open(placeholderImage, '_blank')}>View</Button>
            {!editing && <Button variant="outlined" color='info'
              onClick={()=>setEditing(true)}>Edit</Button>}
          </Box>}
        </Box>}
        {selectedImage &&
          <Box display='flex' flexDirection='column'>
            <img 
              src={selectedImage} alt="Unable to preview" 
              style={{ maxWidth: '300px', height: 'auto', maxHeight: "200px",
              objectFit: 'contain', marginTop: "5px"}} 
            />
            <Box display={'flex'} gap="20px" mt="10px">
              {!fileLink && <Button variant="contained" color='success'
                disabled={status === 'Saving...'}
                onClick={()=>{
                  setStatus('Saving...')
                  handleSave(req, setRes)
                }}>{status}</Button>}
              {fileLink && <Button variant="contained" color='success'
                onClick={()=>window.open(placeholderImage, '_blank')}>View</Button>}
              {!fileLink && <Button variant="contained" color='error' 
                onClick={()=>{
                  setSelectedImage(null)
                  setUploadFile(null)
                  setFileLink(false)
                }}>Cancel</Button>}
            </Box>
          </Box>
        }
        
      </Box>
   
  );
};

export default UploadFile;
