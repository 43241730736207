import { Box, Typography, useTheme} from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {tokens} from '../../src/theme'

const SuccessMessage = ({message}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box 
        display='flex'
        p='8px'
        justifyContent='center'
        alignItems='center'
        flexDirection='row'
        m='20px'
        // borderRadius='20px'
        boxShadow={'0 0 10px rgba(0, 0, 0, 0.5)'}
        gap="10px"
        minWidth='400px'
        backgroundColor={colors.greenAccent[700]}

    >
        <CheckCircleIcon 
        fontSize='large'
        />
        <Typography variant='h5'
        sx={{
          color: 'white',
          fontWeight: '600'          
        }}>{message.toUpperCase()}</Typography>
    </Box>
  )
}

export default SuccessMessage