import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useContext, useEffect } from "react";
import {UserIDContext} from "../../App"
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import { tokens } from "../../theme";
import { DatePickerField } from "../../components/DatePicker";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { numberRegex } from "../../assets/currenciesObj";
import { expenseCategories, businessIncomeTypes } from "../../assets/expenseCategories";

const MakeTransaction = ({cancelTransaction, handleValues, processing, type}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const userID = useContext(UserIDContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //states
  const [checked, setChecked] = useState(false)
  const [userAdmins, setUserAdmins] = useState()

  //useEffects
  useEffect(()=>{
    const listUserAdmins = async()=>{
      try{
        const listOfUserAdmins = await API.graphql(graphqlOperation(`query MyQuery {
          listUserAdmins(filter: {userUserAdminsId: {eq: "${userID}"}}) {
            items {
              name
              id
            }
          }
        }
        `));
        if(listOfUserAdmins){
          const userAdminDetails = listOfUserAdmins.data.listUserAdmins.items.map((userAdmin)=>({
            id: userAdmin.id,
            name: userAdmin.name,
          }))
          setUserAdmins(userAdminDetails);
        }
      }catch(e){
        console.log('Error: Unable to get user admins::: ',e)
      }
    }
    listUserAdmins()
    // eslint-disable-next-line
  },[])

  //functions
  const enalbeNonStaffDeposit =(e)=>{
    setChecked(e.target.checked);
  }
  
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a'

  const initialValues = {
    amount: "",
    date: "",
    narration: "",
    expenseType: type === 'expense' ? "Bad Debts (write-offs)" : 
      ( type === 'income' ? "Collateral Sale" : ''),
    type
  };

  const handleFormSubmit = (values) => {
    values.date = new Date(values.date.target.value)
    handleValues(values);
  };

  return (
    <Box width="100%" sx={{justifyContent: 'center', display: 'flex', flexDirection: 'column',
        backgroundColor: '#071318', p: "20px", mt: "20px", mb: "30px"}}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'} mb="20px">
          <Typography variant='h4' color={colors.greenAccent[300]} >
            {`Add new ${type}`}</Typography>
          <CancelPresentationIcon fontSize='large' color='info' onClick={cancelTransaction}
              cursor="pointer" />
      </Box>
      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(11, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 11" },
                }}
              >
                <Box sx={{ gridColumn: (type === 'expense') || (type === 'income') 
                  ? "span 5" : "span 2" }}>
                  <Box display="flex" flexDirection={'row'} justifyContent={'space-between'}>
                    <Box display="flex" flexDirection={'column'} 
                      sx={{width: (type === 'expense') || (type === 'income') ? "37%" : "100%" }}>
                      <Typography color={colors.greenAccent[300]}>Transaction Type:</Typography>
                      <Field
                        className="formSelect"
                        as="select"
                        disabled
                        label="Type"
                        name="type"
                        onChange={handleChange}
                      >
                        <option value={type}>{type}</option>
                      </Field>
                    </Box>
                    {type === 'expense' && <Box display="flex" flexDirection={'column'} width="60%">
                      <Typography color={colors.greenAccent[300]}>Expense Category:</Typography>
                      <Field
                        className="formSelect"
                        as="select"
                        name="expenseType"
                        onChange={handleChange}
                      >
                        {expenseCategories.map((category)=> (
                          <option value={category}>{category}</option>
                        ))}
                      </Field>
                    </Box>}
                    {type === 'income' && <Box display="flex" flexDirection={'column'} width="60%">
                      <Typography color={colors.greenAccent[300]}>Other Income Category:</Typography>
                      <Field
                        className="formSelect"
                        as="select"
                        name="expenseType"
                        onChange={handleChange}
                      >
                        {businessIncomeTypes.map((category)=> (
                          <option value={category}>{category}</option>
                        ))}
                      </Field>
                    </Box>}
                  </Box>
                </Box>
                <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label={`Amount`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.amount.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    name="amount"
                    error={!!touched.amount && !!errors.amount}
                    helperText={touched.amount && errors.amount}
                    sx={{ gridColumn: (type === 'expense') || (type === 'income') ? "span 3" : "span 6" }}
                />
                <Box sx={{ gridColumn: "span 3" }}>
                    <Box display="flex" flexDirection={'column'} >
                        <Typography color={colors.greenAccent[300]}>{`Date of ${type}`}</Typography>
                        <DatePickerField name="date" />
                        {values.date === '' &&
                        <Typography style={{fontSize: 11, color: '#E64333'}}>Required</Typography>}
                    </Box>
                </Box>
                <CssTextField focusColor={labelFocusColor}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Transaction Details"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.narration}
                  name="narration"
                  sx={{ gridColumn: "span 10" }}
                />
              </Box>
              <Box display="flex" mt="20px" gap="30px">
                <Button type="submit" color="secondary" variant="contained"
                    disabled={(!values.date) || (processing !== 'SAVE')}>
                  {processing}
                </Button>
                <Button color="error" variant="outlined"
                    onClick={cancelTransaction}>
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  amount: yup.string().matches(numberRegex, "must be a number").required("required"),
});

export default MakeTransaction;