import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useContext, useEffect } from "react";
import {UserIDContext} from "../../../App"
import { API, graphqlOperation } from "aws-amplify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { DatePickerField } from "../../../components/DatePicker";
import {countries} from "../../../assets/listOfCountries"
import Header from "../../../components/Header";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";

const MoreBorrowerDetailsForm = ({initialBorrower, editedBorrower}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [processing, setProcessing] = useState()
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //states
  const [borrower, setBorrower] = useState()
  const [checked, setChecked] = useState(false)

  //useEffects
  useEffect(()=>{
    if(initialBorrower){
      setBorrower(initialBorrower)
      setProcessing('Save Changes')
      setChecked(false)
    }else{
      setProcessing('Create New Borrower')
    }
  },[initialBorrower])

  //functions
  const enableEditing =(e)=>{
    setChecked(e.target.checked);
  }
  
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a'

  const initialValues = {
    employerName: initialBorrower?.employerName || "",
    employmentStatus: initialBorrower?.employmentStatus || "",
    creditScore: initialBorrower?.creditScore || "",
    additionalNote1: initialBorrower?.additionalNote1 || "",
    additionalNote2: initialBorrower?.additionalNote2 || "",
  };

  const updateAmplify =  async(values) =>{
    const {
      employerName, employmentStatus ,creditScore ,additionalNote1 ,additionalNote2 
    } = values
    values.id = initialBorrower.id
    try{
      const updatedBorrower = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          updateBorrower(input: {
            id: "${initialBorrower.id}",
            employerName: "${employerName}",
            employmentStatus: "${employmentStatus}",
            creditScore: "${creditScore}",
            additionalNote1: "${additionalNote1}",
            additionalNote2: "${additionalNote2}"
          }) 
          {
            id
          }
        }`
      ))
      if(updatedBorrower)
      {
        editedBorrower(values);
      }
    }
    catch(e){
      console.log('Borrower Editing Failed', e)
    }
  }

  const handleFormSubmit = (values) => {
    setProcessing('Processing...Please Wait...')
    updateAmplify(values)
  };

  return (
    <Box width="100%" sx={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
      {initialBorrower && <FormGroup
        sx={{ml: "20px", mt: '10px'}}>
          <FormControlLabel control={
            <Checkbox 
              color="success"
              defaultChecked={false} 
              onChange={enableEditing}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />} 
          label="Enable Editing" />
        </FormGroup>}
      <Box m="20px" maxWidth="700px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Employment Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employmentStatus}
                  name="employmentStatus"
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name of Employer"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employerName}
                  name="employerName"
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Assiged Credit Score"
                  onBlur={handleBlur}
                  value={values.creditScore}
                  onChange={handleChange}
                  name="creditScore"
                  sx={{ gridColumn: "span 2" }}
                />
                <CssTextField focusColor={labelFocusColor}
                  disabled={!checked}
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Additonal Note about Borrower"
                  onBlur={handleBlur}
                  value={values.additionalNote1}
                  onChange={handleChange}
                  name="additionalNote1"
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" mt="50px" mb='80px' gap="30px">
                <Button type="submit" color="secondary" variant="contained" disabled={!checked}>
                  {processing}
                </Button>
                <Button onClick={()=> resetForm()} color="error" variant="outlined">
                  CANCEL
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const phoneRegExp = /^(?![A-Za-z]{1,9}$)[^A-Za-z]{9,}$/

export default MoreBorrowerDetailsForm;