import { useState, useContext, useEffect } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css";
import '../../index.css'
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonalAddIcon from "@mui/icons-material/PersonOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import RedeemIcon from '@mui/icons-material/Redeem';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SettingsIcon from '@mui/icons-material/Settings';
import './GlobalStyles.css' 
import { SignOutContext } from "../../App";
import { LoansContext, UserTypeContext } from "../../App";

const Item = ({ title, to, icon, selected, setSelected, target, comment }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 

  return (
    <MenuItem
    active={selected === title}
    style={{
      color: colors.grey[100],
    }}
    onClick={() => setSelected(title)}
    icon={icon}
    component={<Link to={to} target={target}/>}
    >
      <Typography>{title}</Typography>
      {comment}
    </MenuItem>
  );
};

const MySidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");  
  const [locked, setLocked] = useState(false);  
  const [reportsPanel, setReportsPanel] = useState('/loadReports')
  const signOut = useContext(SignOutContext)
  const {loansContext} = useContext(LoansContext)
  const {businessName} = useContext(UserTypeContext)
  const displayBusinessName = businessName || (businessName !== '') ? businessName : 'LoanTabs'
  const navigate = useNavigate()

  const handleMouseEnter = () => {
    !locked && setIsCollapsed(false);
  };
 
  const handleMouseLeave = () => {
    !locked && setIsCollapsed(true);
  };

  useEffect(()=>{
    loansContext && setReportsPanel('/reportsPanel')
  }, [loansContext])
  
  return (
    <Box backgroundColor='#1F2A40' className='sidebar' pl="10px" 
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <Sidebar
        collapsed={isCollapsed}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: colors.primary[400],
          },
        }}
      >
        <Menu iconShape="square"
          menuItemStyles={{
            button: {
                  // backgroundColor: '#1F2A40',
                  '&:hover': {
                      backgroundColor: '#141B2D',
                  },
              },
          }}
        >
          {/* LOGO AND MENU ICON */}
    
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "7px 0 7px 0",
              color: colors.grey[100],
            }} 
          >
            {!isCollapsed && !locked && (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                ml="15px"
                onClick={() => {
                  setLocked(true)
                  setIsCollapsed(false)
                }}
              >
                <Typography color={colors.greenAccent[100]}>
                  MENU
                </Typography>
                <IconButton >
                  <LockOpenIcon />
                </IconButton>
              </Box>
            )}
            {!isCollapsed && locked && (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                ml="15px"
                onClick={() => {
                  setLocked(false)
                  setIsCollapsed(true)
                }}
              >
                <Typography color={colors.greenAccent[100]}>
                  MENU
                </Typography>
                <IconButton >
                  <LockIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && displayBusinessName && (
            <Box mb="20px" backgroundColor="black" p="10px">
              <Box textAlign="left">
                <Typography
                  variant="h3"
                  color={'white'}
                  fontWeight="bold"
                  // sx={{ m: "10px 0 0 15px" }}
                >
                  {displayBusinessName.toUpperCase()}
                </Typography>
                {/* <Typography variant="h5" color={colors.greenAccent[500]}>
                  Loan Management Software
                </Typography> */}
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "3px"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Packages"
              to="/packages"
              icon={<RedeemIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Borrowers
            </Typography>}
                <Item
                  title="Add New Borrower"
                      to="/createBorrower"
                  icon={<PersonalAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manager Borrowers"
                      to="/manageBorrowers"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Loans
            </Typography>}
                <Item
                  title="New Loan"
                      to="/newLoan"
                  icon={<MonetizationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Loans Panel"
                      to="/loansPanel"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Team Members
            </Typography>}
                <Item
                  title="New Team Member"
                      to="/createUserAdmin"
                  icon={<GroupAddIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Team"
                      to="/manageUserAdmins"
                  icon={<Diversity3Icon />}
                  selected={selected}
                  setSelected={setSelected}
                />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Accounts & Transactions
            </Typography>}
            <Item
              title="Create Account"
              to="/createAccount"
              icon={<AddCardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Accounts & Transactions"
              to="/manageAccounts"
              icon={<AccountBalanceIcon />}
              selected={selected}
              setSelected={setSelected}
              comment={!isCollapsed && <Typography
                fontSize={'10px'}
                color={colors.grey[100]}
                sx={{ m: "0 0 5px" }}
              >
               {`(expenses, deposits, withdrawals)`}
              </Typography>}
            />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Reports
            </Typography>}
            {/* <Item
              title="Create Report"
              to="/underDevelopment"
              icon={<AddchartIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Reports Panel"
              to={reportsPanel}
              icon={<AssessmentIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Settings
            </Typography>}
            <Item
              title="Settings"
              to="/settings"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {!isCollapsed && <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Get Support
            </Typography>}
            <Item
              title="Video Tutorials"
              to="https://www.loantabs.com/video-tutorials/"
              target="_blank"
              icon={<YouTubeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Contact Support"
              to="https://www.loantabs.com/support"
              target="_blank"
              icon={<HeadsetMicIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
        {!isCollapsed && <Box pt="10px" pb="80px" display="flex" justifyContent={'center'}>
          <Button 
            onClick={()=>{
              navigate('/')
              signOut()
            }}
            variant="contained" color="error">
            Sign out</Button>
        </Box>}
      </Sidebar>
    </Box>
  );
};

export default MySidebar;