import { Box, Typography, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react'
import FlutterwaveHook from './flutterwaveHook';
import { tokens } from '../theme';

function PackageCard({cardTitle, price, perMonth, intro, bullets, currency,
    paymentAmount, backgroundColor, buttonText, buttonColor, disabled}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
      
    const CheckedPoint = ({bullet})=>{
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', gap: "10px"}}>
                <CheckCircleIcon fontSize='small'/>
                <Typography>{bullet}</Typography>
            </Box>
        )
    }
  return (
    <Box minWidth={'260px'} sx={{
        p: '30px', backgroundColor, color: colors.blueAccent[900], borderRadius: "20px", 
        justifyContent: 'space-between', display: 'flex', flexDirection: 'column'
    }}>
        <Box>
            <Typography mb='15px' sx={{fontSize: '20px', fontWeight: 700}}>{cardTitle}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography mt="15px" mr='5px' sx={{fontWeight: 700}}>{currency}</Typography>
                <Typography sx={{fontSize: '40px', fontWeight: 700}}>{price}</Typography>
                <Typography mt="15px" sx={{fontWeight: 700}}>{perMonth}</Typography>
            </Box>
            <Typography mt='10px' mb='20px' sx={{fontWeight: 600}}>{intro}</Typography>
            {bullets && bullets.map((item)=>(<Box mt='10px'>
                <CheckedPoint bullet={item}/>
            </Box>))}
        </Box>
        <Box mt='20px'>
            {paymentAmount && <FlutterwaveHook paymentAmount={paymentAmount} buttonText={buttonText} 
            buttonColor={buttonColor} cardTitle={cardTitle} currency={currency} disabled={disabled}/>}
        </Box>
    </Box>
  )
}

export default PackageCard