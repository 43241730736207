import React, { useEffect, useContext } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Header from "../../components/Header";
import { UserIDContext } from '../../App';
import { API, graphqlOperation} from 'aws-amplify';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../assets/custom.css'
import PointsManager from '../../components/PointsManager'
import TransitionAlerts from "../../components/AlertMessage";

const ManageUserAdmins = () => {
  const [userAdmins, setUserAdmins] = useState([])
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userID = useContext(UserIDContext)
  const navigate = useNavigate();
  
  useEffect(()=>{
    const listUserAdmins = async()=>{
      try{
        const listOfUserAdmins = await API.graphql(graphqlOperation(`query MyQuery {
          listUserAdmins(
            filter: {userUserAdminsId: {eq: "${userID}"}},
            limit: 1000
          ) {
            items {
              name
              id
              phoneNumber
              email
              type
              attribue1
            }
          }
        }
        `));
        if(listOfUserAdmins.data.listUserAdmins.items.length === 0){
          setUserAdmins([
            {
              id: 1,
              name: "none",
              email: "none",
              phoneNumber: "none",
              type: "none", 
              accessLevel: "none"
            }
          ])
        }else{
          const userAdminDetails = listOfUserAdmins.data.listUserAdmins.items.map((userAdmin)=>({
            id: userAdmin.id,
            name: userAdmin.name,
            email: userAdmin.email,
            phoneNumber: userAdmin.phoneNumber,
            type: userAdmin.type, 
            accessLevel: userAdmin.attribue1
          }))
          setUserAdmins(userAdminDetails);
        }
      }catch(e){
        console.log('Error: Unable to get user admins::: ',e)
      }
    }
    listUserAdmins()
    // eslint-disable-next-line
  },[])

  const handleRowClick =(params)=>{
    const email = params.row.email || '-'
    const role = params.row.type || '-'
    const accessLevel = params.row.accessLevel || '-'
    navigate(
      `/createUserAdmin/${params.id}/${params.row.name}/${email}/${params.row.phoneNumber}/${role}/${accessLevel}`
      )
  }

  const columns = [
    
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: "email",
      headerName: "Email Address",
      align: 'left',
      headerAlign: 'left',
      flex: 1.5,
    },
    {
      field: "type",
      headerName: "Role/Designation",
      align: 'left',
      headerAlign: 'left',
      flex: 1.5,
    },
    {
      field: "accessLevel",
      headerName: "Log In Access",
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      renderCell: (params)=>params.row?.accessLevel?.toUpperCase()
    },
    {
      field: "label",
      headerName: "",
      flex: 2,
      renderCell: (params) => {
        if(params.row.name !== "none"){
          return (
            <Box
              m="3px 3px"
              p="5px"
              display="flex"
              sx={{cursor: "pointer"}}
              // justifyContent="center"
              backgroundColor= {colors.blueAccent[500]}
              borderRadius="4px"
              onClick={()=> handleRowClick(params)}
            >
              <AddCircleOutlineIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                View Details/Edit
              </Typography>
            </Box>
          );

        }
      },
    },
  ];
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          mt: "-10px",
          ml: "20px",
          gap: "10px"
        }}
      >
        <Typography sx={{color: `${colors.greenAccent[200]} !important`,}}>TYPE A NAME TO SEARCH: </Typography>
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <PointsManager />
      <Box display={'flex'} flexDirection={'row'} gap="80px" alignItems={'center'}>
        <Header title="Manage Team" subtitle="Click on any Team Member to View and Edit" />
        {userAdmins && <Button variant="outlined" color='info' startIcon={<AddCircleOutlineIcon />}
          disabled={userAdmins.length >4}
          onClick={()=> navigate(`/createUserAdmin`)}>Add New Team Member / Staff</Button>}
      </Box>
      {(userAdmins.length >4) && <TransitionAlerts buttonText={'Create More Team Members'}
        message={<Box>
          <Typography>Upgrade to ENTERPRISE PACKAGE to create unlimited Team Members</Typography>
        </Box>}
        button1={<Button 
          onClick={()=> window.open('https://www.loantabs.com/enterprise', '_blank')} variant="contained"
          color="info" m="20px">Upgrade to Enterprise</Button>}
      />}
      <Box
        m="40px 0 0 0"
        width='900px'
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            height: "50px"
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .normalLineHeight": {
            height: `200px !important`,
          },
        }}
      >
        {userAdmins && <DataGrid rows={userAdmins} columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: QuickSearchToolbar }}
        />}
      </Box>
    </Box>
  );
};

export default ManageUserAdmins;