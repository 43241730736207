import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
//componet imports
import ReportDateRange from '../../components/ReportDateRange';
import { tokens } from '../../theme';
import { LoansContext } from '../../App';
import { UserTypeContext } from '../../App';

function LoansReport() {

    //constants
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const {loansContext} = useContext(LoansContext)
    const {decimalPoints} = useContext(UserTypeContext);

    //states
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [rows, setRows] = useState()

    //useEffect
    useEffect(() => {
        const configureReport = () => {
            const loansInPeriod = loansContext.filter((loan) => { 
                return moment(loan.startDate).isSameOrAfter(moment(startDate))
                    && moment(loan.startDate).isSameOrBefore(moment(endDate))
            })
            const rowsArray = []
            const createRow = (status, id) => {
                const selectedLoans = loansInPeriod.filter((loan) => 
                    loan.status === status)
                if(selectedLoans.length > 0){
                    const row = {
                        id: id,
                        status: status.toUpperCase(),
                        fees: selectedLoans.reduce((acc,loan)=> acc + loan.fees, 0),
                        numberOfLoans: selectedLoans.length,
                        principalReleased: selectedLoans.reduce((acc,loan)=> acc + loan.principal, 0),
                        interest: selectedLoans.reduce((acc,loan)=> acc + loan.totalInterest, 0),
                    }
                    row.total = row.principalReleased + row.interest
                    rowsArray.push(row)
                }else{
                    rowsArray.push({id: id, status: status.toUpperCase(), fees: 0, numberOfLoans: 0, 
                        principalReleased:0, interest: 0, total: 0})
                }
            }
            let count = 0;
            createRow('current', count++)
            createRow('payment due', count++)
            createRow('overdue', count++)
            createRow('cleared', count++)
            
         //total (all loans)
            const totalsRow = {
                id: count++,
                status: 'TOTAL (ALL LOANS)',
                fees: loansInPeriod.reduce((acc,loan)=> acc + loan.fees, 0),
                numberOfLoans: loansInPeriod.length,
                principalReleased: loansInPeriod.reduce((acc,loan)=> acc + loan.principal, 0),
                interest: loansInPeriod.reduce((acc,loan)=> acc + loan.totalInterest, 0),
            }
            totalsRow.total = totalsRow.principalReleased + totalsRow.interest
            rowsArray.push(totalsRow)
    
            setRows(rowsArray);
        }
        loansContext && configureReport()
    },[startDate, endDate, loansContext])

    //columns
    const columns = [
        {
            field: "status",
            sortable: false,
            headerName: "Loan Status",
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            type: 'text',
        },
        {
            field: "numberOfLoans",
            sortable: false,
            headerName: "No. of Loans",
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            type: 'number',
        },
        {
            field: "principalReleased",
            sortable: false,
            headerName: "Principal Released",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            cellClassName: 'name-column--cell',
            renderCell: (params) => {
              return params.row.principalReleased?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "interest",
            sortable: false,
            headerName: "Interest Charged",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.interest?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "fees",
            sortable: false,
            headerName: "Fees Charged",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            renderCell: (params) => {
              return params.row.fees?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
        {
            field: "total",
            sortable: false,
            headerName: "Total",
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            type: 'number',
            cellClassName: 'interest',
            renderCell: (params) => {
              return params.row.total?.toLocaleString('en-US', {
                 maximumFractionDigits: decimalPoints
              })
            }        
        },
    ]

  return (
    <Box sx={{minWidth: '900px'}} display="flex" flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'} gap={'60px'} mb='20px' justifyContent={'space-between'}>
            <Typography variant="h2" >LOANS REPORT</Typography>
            <Button color='error' variant='outlined' 
                onClick={()=>navigate('/reportsPanel')}>Close Report</Button>
        </Box>
        <Box>
            {startDate && endDate && <ReportDateRange startDate={startDate} endDate={endDate}
                setStartDate={setStartDate} setEndDate={setEndDate}/>}
        </Box>
        {rows && <Box mt='20px'width="850px"
            sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
            },
            "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important"
            },
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                height: "50px"
            },
            "& .name-column--cell": {
                color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            ".bottomRow": {
                backgroundColor: colors.blueAccent[900],
                borderBottom: "3px solid white",
                borderTop: "1px solid white",
                fontWeight: "bold",
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            "& .normalLineHeight": {
                height: `200px !important`,
            },
            ".interest": {
                color: colors.blueAccent[200],
            }
            }}>
          <DataGrid rows={rows} columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            autoHeight={true}
            hideFooter={true}
            getRowClassName={(params) => params.row.status === "TOTAL (ALL LOANS)"
                ? 'bottomRow' : ''}
          />
        </Box>}
    </Box>
  )
}

export default LoansReport