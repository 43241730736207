import { useEffect, useState } from "react";
import React from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";

//component imports
import { assetPlaceHolderImage } from "../assets/uploads3";
import { tokens } from "../theme";

//MAIN FUNCTION
const UploadNamedDocument = ({uploadLabel, setUploadFile, placeholderImage,
  handleSave, reqFile, setResFileURL, resFileURL, prevFileName, prevFileURL, setTitle})=>{

  //states

  const [selectedImage, setSelectedImage] = useState(prevFileURL);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('SAVE')
  const [fileLink, setFileLink] = useState()
  const [editing, setEditing] = useState()
  const [disableInput, setDisableInput] = useState(false)

  //useEffects

  useEffect(()=>{
    if(resFileURL){
      setFileLink(true)
      setStatus('SAVE')
      setSelectedImage(resFileURL)
    }
  },[resFileURL])

  //constants
  const initialValues = { documentTitle: prevFileName || ''}
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));

  const labelFocusColor = '#3da58a'

  //functions
  const handleFormSubmit = (values) => {
    setTitle(values.documentTitle)
    handleSave(reqFile, setResFileURL)
    setStatus('Saving...') 
    setDisableInput(true) 
  };

  const handleImageUpload = (event) => {
    setError(null)
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 10 * 1024 * 1024) {
        const reader = new FileReader();
        reader.onload = function(event) {
          setSelectedImage(event.target.result);
          setUploadFile(event.target.result);
          setFileLink(null)
          setDisableInput(false) 
          setEditing(true)
        };
        reader.readAsDataURL(file);
      } else {
        setError('File size exceeds the maximum limit of 10MB.');
      }
    }
  };

  return (
    <Box gap="10px" display='flex' flexDirection='column' mt="30px" >
      <Typography variant='h5' textAlign={'center'}
        color={colors.greenAccent[300]}>{uploadLabel}</Typography>
      <Box sx={{border: `1px solid ${colors.greenAccent[300]}`, p: "20px", minHeight: '340px'}}>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              {!editing && <Typography variant='h3' mb="15px">{prevFileName}</Typography>}
              {((placeholderImage === assetPlaceHolderImage) 
                || editing)
                && <input type="file" onChange={handleImageUpload} />}
              {error && <Typography color="error">{error}</Typography>}
              {!selectedImage && placeholderImage && 
              <Box>
                <img 
                  src={placeholderImage} alt="Unable to preview" 
                  style={{ width: '180px', height: 'auto',  maxHeight: "200px",
                  objectFit: 'contain', marginTop: "5px"}} 
                />
              </Box>}
              {selectedImage &&
                <Box display='flex' flexDirection='column'>
                  <img 
                    src={selectedImage} alt="Unable to preview" 
                    style={{ maxWidth: '300px', height: 'auto', maxHeight: "200px",
                    objectFit: 'contain', marginTop: "5px"}} 
                  />
                </Box>
              }
              {(placeholderImage !== assetPlaceHolderImage) && !editing
              && <Box display={'flex'} gap={'30px'} mt="10px" mb="10px">
                <Button variant="contained" color='success'
                  onClick={()=>window.open(prevFileURL, '_blank')}>View</Button>
                {!editing && <Button variant="outlined" color='info'
                  onClick={()=>{
                    setEditing(true)
                    setUploadFile(selectedImage)
                  }}>Edit</Button>}
              </Box>}
              {selectedImage && editing && <Box display={'flex'} flexDirection={'column'} mt="15px">
                <CssTextField focusColor={labelFocusColor}
                  fullWidth
                  variant="filled"
                  disabled={disableInput}
                  type="text"
                  label="Please give this file a name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.documentTitle}
                  name="documentTitle"
                  error={!!touched.documentTitle && !!errors.documentTitle}
                  helperText={touched.documentTitle && errors.documentTitle}
                />
                <Box display={'flex'} gap="20px" mt="10px">
                  {!fileLink && <Button variant="contained" color='success'
                    disabled={status === 'Saving...'} type="submit" >{status}</Button>}
                  {fileLink && <Button variant="contained" color='success'
                    onClick={()=>window.open(prevFileURL, '_blank')}>View</Button>}
                  {!fileLink && <Button variant="contained" color='error' 
                    onClick={()=>{
                      setEditing(false)
                      setSelectedImage(prevFileURL)
                    }}>Cancel</Button>}
                </Box>
              </Box>}
            </form>
          )}
        </Formik>
      </Box>

    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  documentTitle: yup.string().required("required"),
});

export default UploadNamedDocument;
