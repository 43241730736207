import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState, useContext} from "react";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { API, graphqlOperation } from "aws-amplify";
import { UserIDContext, UserTypeContext } from "../App";
import { DatePickerField } from './DatePicker';
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
import "../assets/custom.css";
import { tokens } from "../theme";
import { numberRegex } from "../assets/currenciesObj";

const NewPaymentOnStatement = ({loanId, paymentAdded, setAmountPaid, cancelPayment, 
  currency, addNewPaymentToStatement}) => {
 
  //states
    const [processing, setProcessing] = useState(false)
    const loanCurrency = currency || 'UGX'

  //constants
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userID = useContext(UserIDContext)
    const {accounts} = useContext(UserTypeContext)
    const activeAccounts = accounts.filter(account => account.accountStatus !== 'inactive')

    const initialValues = {
      payment: '',
      account: activeAccounts ? activeAccounts[0].id : "",
      paymentDate: '',
    };
        
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'
    
    const handleFormSubmit = (values) => {
      values.paymentDate = new Date(values.paymentDate?.target?.value)
      const paymentArgs = {
        amount: parseFloat(values.payment.replace(/,/g, '')),
        paymentDate: values.paymentDate,
        accountId: values?.account || activeAccounts[0].id,
      };
      createPayment(paymentArgs.amount, paymentArgs.paymentDate, paymentArgs.accountId)
    };

    const createPayment =  async(amount, paymentDate, accountId) =>{
      setProcessing(true)
      try{
        const createdPayment = await API.graphql(graphqlOperation(`
          mutation MyMutation {
            createPayment(input: {
              amount: ${amount}, loanPaymentsId: "${loanId}", paymentDate: "${paymentDate}"
            }) 
            {
              id
            }
          }
        `));
        if (createdPayment){
          createPaymentAccount(accountId, createdPayment.data.createPayment.id, amount, paymentDate)
        }
      }catch(e){
        console.log('error creating payment: ',e)
      }
    }
    
    const createPaymentAccount = async(accountId, paymentId, amount, paymentDate) => {
      try{
        const newPaymentAccount = await API.graphql(graphqlOperation(`
        mutation MyMutation {
          createPaymentAccount(input: {
            accountId: "${accountId}", paymentId: "${paymentId}"
          }) 
          {
            id
          }
        }
        `));
        if(newPaymentAccount){
          setAmountPaid(amount)
          paymentAdded()
          addNewPaymentToStatement(paymentId, amount, paymentDate)
          setProcessing(false)
        }
      }
      catch(e){
        console.log('error creating payemntAccount ', e)
      }
    }
  
    return (
      <Box p='20px' sx={{backgroundColor: '#071318'}} mb="20px" >
          {!activeAccounts && <Box m="40px" className='printNot'>
            <p>LOADING, PLEASE WAIT...</p>
          </Box>}
          {activeAccounts && <Box className='printNot' pt="20px">
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'start'} mb="20px">
                <Typography variant='h4' >Register a New Payment:</Typography>
                <CancelPresentationIcon fontSize='large' color='info' onClick={cancelPayment}
                  cursor="pointer" />
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection={'row'} gap="20px" justifyContent={'space-between'}>
                    <Box display="flex" flexDirection={'column'} >
                        <Typography color={colors.greenAccent[300]}>Payment Date:</Typography>
                        <DatePickerField name="paymentDate" />
                        {values.paymentDate === '' &&
                        <Typography style={{fontSize: 11, color: '#E64333'}}>Payment Date Required</Typography>}
                    </Box>
                    <Box display="flex" flexDirection={'column'}>
                      <Typography color={colors.greenAccent[300]}>Amount Paid:</Typography>
                      <CssTextField focusColor={labelFocusColor}
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Enter the Payment amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.payment.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        name="payment"
                        error={!!touched.payment && !!errors.payment}
                        helperText={touched.payment && errors.payment}
                      />
                    </Box>
                    <Box display="flex" flexDirection={'column'} >
                      <Typography color={colors.greenAccent[300]}>
                        Select an Account to receive the payment:</Typography>
                      <Field
                        as="select"
                        name="account"
                        onChange={handleChange}
                      >{activeAccounts.map((item)=>(
                          item.currency === loanCurrency && <option value={item.id} key={item.id}>
                            {`${item.accountName} (${loanCurrency})`}
                          </option>
                        ))}
                      </Field>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent={'space-between'} mt="30px">
                    <Button type="submit" color="success" variant="contained" disabled={values.paymentDate === ''}>
                      Confirm Payment
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>}
          {processing && <Box display='flex'>
            <Typography
              variant="h4"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "10px 0 60px 0" }}
            >Processing... Please Wait...</Typography>
          </Box>}
      </Box>
    );
  };
  
  const checkoutSchema = yup.object().shape({
    payment: yup.string().matches(numberRegex, "must be a number").required("required")
    // paymentDate: yup.string().required("required"),
  });
  
export default NewPaymentOnStatement