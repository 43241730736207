import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react'
//component imports
import { dateToday } from '../../assets/getDate';
import { UserTypeContext } from '../../App';

function PaymentReceipt({paymentDetails, borrower, currency, principal, startDate,
    maturityDate, amountDue, loanBalance, totalPayments}) {

    //constants
    const {businessName} = useContext(UserTypeContext)
    const hasBusinessName = businessName || (businessName !== '') ? businessName : '.' 
    const {date, paymentAmount } = paymentDetails
    const options = { day: "2-digit", month: "short", year: "numeric" };

  return (
    <Box className="printOnly" display="flex" flexDirection={'column'} 
        color="black" width="800px" p="40px">
        <Box display='flex' justifyContent='space-between' gap='20px' mt='-70px' mb='10px'>
            <p style={{color: 'blue', textAlign: 'center'}}>Created using Loan Management Software from <u>www.LoanTabs.com</u></p>
            <p>Printed on: {dateToday}</p>
        </Box>
        <Box display='flex' flexDirection={'column'} mb="20px">
            <Typography variant="h2"  
            sx={{pb: "10px", borderBottom: '1px solid black', mb: "20px"}}>
            {`${hasBusinessName.toUpperCase()}`}</Typography>
            <Typography variant='h3'> PAYMENT RECEIPT</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}
            sx={{pb: "60px", borderBottom: '1px solid black', mb: "20px"}}>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='h5'>Collection Date: </Typography>
                <Typography variant='h4'>{date.toLocaleDateString("en-UK", options)} </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='h5'>Paid By: </Typography>
                <Typography variant='h4'>{borrower}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='h5'>Amount Paid </Typography>
                <Typography variant='h4'>{`${currency} ${paymentAmount}`}</Typography>
            </Box>
        </Box>
        <Typography variant='h5'>The above payment has been allocated to the loan with the following details:</Typography>
        <Box display={'flex'} flexDirection={'row'} gap="30px" mt="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
                <Typography >Loan Principal: </Typography>
                <Typography >{`${currency} ${principal}`} </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography >Loan Start Date: </Typography>
                <Typography >{startDate}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography >Loan Maturity Date:</Typography>
                <Typography >{maturityDate}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography >Amount Due</Typography>
                <Typography >{`${currency} ${amountDue.toLocaleString()}`}</Typography>
            </Box>
            {loanBalance && <Box display={'flex'} flexDirection={'column'}>
                <Typography >Loan Balance</Typography>
                <Typography >{`${currency} ${loanBalance}`}</Typography>
            </Box>}
        </Box>
        {/* <Typography >This Payment brings the Total Payments Received for this Loan to {`${currency} ${totalPayments}`}</Typography> */}
    </Box>
  )
}

export default PaymentReceipt