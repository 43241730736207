import moment from "moment/moment"
import { formatGraphQLDate } from "./loanCalculations"

const configureAccounts = (accountsArray) => {
    const configuredAccounts = []
    let count = Math.random()
    accountsArray.forEach(account => {
      //transactions
        const transactions = []
        let id = Math.random() + 1
      
      //deposits
        if(account.deposits.items.length > 0) {
            account.deposits.items.forEach(deposit => {
                if(deposit){
                    transactions.push({
                        id: deposit.id,
                        date: deposit.depositDate,
                        details: deposit.depositDetails,
                        debit: deposit.amount,
                        credit: 0,
                        client: '-',
                        transactionType: 'deposit'
                    })
                }
            })
        }
      //transactions
        if(account.transactions.items.length > 0) {
            account.transactions.items.forEach(transaction => {
                if(transaction.type){
                    const obj = {
                        id: transaction.id,
                        date: transaction.date,
                        details: transaction.details,
                        transactionType: transaction.type,
                        client: '-',
                    }
                    if(transaction.type === 'deposit'){
                        obj.debit = transaction.amount;
                        obj.credit = 0
                    }
                    if(transaction.type === 'income'){
                        obj.debit = transaction.amount;
                        obj.credit = 0
                    }
                    if(transaction.type === 'withdrawal'){
                        obj.debit = 0
                        obj.credit = transaction.amount;
                    }
                    if(transaction.type === 'expense'){
                        obj.debit = 0
                        obj.credit = transaction.amount;
                    }
                    transactions.push(obj)
                }
            })
        }
      //expenses
        if(account.expenses.items.length > 0) {
            account.expenses.items.forEach(expenseObj => {
                if(expenseObj){
                    const expense = expenseObj.expense
                    transactions.push({
                        id: expense.id,
                        date: expense.expenseDate,
                        details: expense.expenseDetails,
                        debit: 0,
                        credit: expense.amount,
                        client: '-',
                        transactionType: 'expense'
                    })
                }
            })
        }
      //loanFees
        if(account.loanFees.items.length > 0) {
            account.loanFees.items.forEach(loanFeeObj => {
                if(loanFeeObj){
                    const loanFee = loanFeeObj.loanFees
                    if(loanFee.loan && loanFee.loan?.borrower){
                        transactions.push({
                            id: id++,
                            date: loanFee.loan?.startDate,
                            details: `Loan Fees paid (${loanFee.loan?.principal} loan)`,
                            debit: loanFee.amount,
                            credit: 0,
                            client: loanFee.loan?.borrower?.othername 
                                ? `${loanFee.loan?.borrower?.firstname} ${loanFee.loan?.borrower?.othername}` 
                                : loanFee.loan?.borrower?.firstname,
                            transactionType: 'loan fees'
                        })
                    }
                }
            })
        }
      //investments
        if(account.investments.items.length > 0) {
            account.investments.items.forEach(investmentObj => {
                if(investmentObj){
                    const investment = investmentObj.investment
                    transactions.push({
                        id: id++,
                        date: investment.startDate,
                        details: `Investment received`,
                        debit: investment.principal,
                        credit: 0,
                        client: investment.investor?.othername 
                            ? `${investment.investor?.firstname} ${investment.investor?.othername}` 
                            : investment.investor?.firstname,
                        transactionType: 'investment'
                    })
                }
            })
        }
      //loans
        if(account.loans.items.length > 0) {
            account.loans.items.forEach(loan => {
                if(loan){
                    const loanDetails = loan.loan;
                    if(loanDetails.borrower){
                        transactions.push({
                            id: id++,
                            date: loanDetails.startDate,
                            details: `Loan issued`,
                            debit: 0,
                            credit: loanDetails.principal,
                            client: loanDetails.borrower?.othername 
                                ? `${loanDetails.borrower?.firstname} ${loanDetails.borrower?.othername}` 
                                : loanDetails.borrower?.firstname,
                            transactionType: 'loan'
                        })
                        if(loanDetails.fees >0){
                            transactions.push({
                                id: id++,
                                date: loanDetails.startDate,
                                details: `Loan Fees paid (${loanDetails.principal} loan)`,
                                debit: loanDetails.fees,
                                credit: 0,
                                client: loanDetails.borrower?.othername 
                                    ? `${loanDetails.borrower?.firstname} ${loanDetails.borrower?.othername}` 
                                    : loanDetails.borrower?.firstname,
                                transactionType: 'loan fees'
                            })
                        }
                    }
                }
            })
        }
      //payments
        if(account.payments.items.length > 0) {
            account.payments.items.forEach(paymentObj => {
                if(paymentObj){
                    const payment = paymentObj.payment
                    if(payment.loan && payment.loan?.borrower){
                        transactions.push({
                            id: id++,
                            date: payment.paymentDate,
                            details: `Payment received`,
                            debit: payment.amount,
                            credit: 0,
                            client: payment.loan?.borrower?.othername 
                                ? `${payment.loan?.borrower?.firstname} ${payment.loan?.borrower?.othername}` 
                                : payment.loan?.borrower?.firstname,
                            transactionType: 'payment'
                        })
                    }
                }
            })
        }
      //withdrawals
        if(account.withdrawals.items.length > 0) {
            account.withdrawals.items.forEach(withdrawal => {
                if(withdrawal){
                    transactions.push({
                        id: withdrawal.id,
                        date: withdrawal.withdrawalDate,
                        details: withdrawal.withdrawalDetails,
                        debit: 0,
                        credit: withdrawal.amount,
                        client: '-',
                        transactionType: 'withdrawal'
                    })
                }
            })
        }
      //valid transactions
        transactions.forEach((transaction) => {
            transaction.date = formatGraphQLDate(transaction.date)
        })
        const validTransactions = transactions.filter(transaction => {
            const tDate = moment(transaction.date)
            return tDate.isSameOrAfter(moment(account.createdAt))
        })
      //closing balance
      const closingBalance = account.openingBalance 
        + validTransactions.reduce((acc, transaction) => acc + transaction?.debit - transaction?.credit, 0)
      //final account object
        configuredAccounts.push({
            accountName: account.accountName, 
            createdAt: account.createdAt,
            id: count++,
            accountId: account.id,
            currency: account.currency,
            openingBalance: account.openingBalance,
            accountStatus: account.accountStatus,
            closingBalance,
            transactions: validTransactions
        })
    })
    return configuredAccounts;
}

const updateAccounts = (accounts, accountId, accountStatus, accountName) => {
    const updatedAccounts = []
    for(let i=0; i<accounts.length; i++) {
        const account = accounts[i]
        account.id = Math.random() + i
        if(account.accountId !== accountId){
            updatedAccounts.push(account)
        }else{
            if(accountStatus){
              account.accountStatus = accountStatus
              updatedAccounts.push(account)
            }
            if(accountName){
              account.accountName = accountName
              updatedAccounts.push(account)
            }
        }
    }
    return updatedAccounts; 
}

const completeTransactions = (transactions, openingBalance, createdAt) =>{
    transactions.sort((a,b) => a.date - b.date)
    const accountCreationTransaction = {
        id: Math.random() * 10000,
        date: formatGraphQLDate(createdAt),
        details: 'Account Created',
        debit: openingBalance,
        credit: 0,
        client: '-',
        balance: openingBalance,
    }
    const completedTransactions = []
    if (transactions[0]?.details !== 'Account Created'){
        completedTransactions.push(accountCreationTransaction)
        for(let i = 0; i < transactions.length; i++){
            transactions[i].balance = completedTransactions[i].balance + 
                transactions[i].debit - transactions[i].credit
            completedTransactions.push(transactions[i])
        }
    }else {
        completedTransactions.push(transactions[0])
        for(let i = 1; i < transactions.length; i++){
            transactions[i].balance = completedTransactions[i-1].balance + 
                transactions[i].debit - transactions[i].credit
            completedTransactions.push(transactions[i])
        }
    }  
    return completedTransactions.reverse()
}

const getAllTransactions = (accounts)=>{
    return accounts.map(account => {
        account.transactions.forEach(transaction => {
            transaction.account = account.accountName
            transaction.currency = account.currency
        })
            return account.transactions
        }).flat()
}

const harvestTokens = () => {}

export { configureAccounts, updateAccounts, completeTransactions, harvestTokens, getAllTransactions }