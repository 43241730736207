import { Box} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";
import UploadFile from "../../../components/UploadFile";
import { API, graphqlOperation } from "aws-amplify";
import { uploads3, assetPlaceHolderImage } from "../../../assets/uploads3";

const PhotoAndId = ({borrowerId, initialBorrower, syncBorrower}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [passportPic, setPassportPic] = useState()
  const [passportPicCloudinary, setPassportPicCloudinary] = useState()
  const [nationalIdPic, setNationalIdPic] = useState()
  const [nationalIdPicCloudinary, setNationalIdPicCloudinary] = useState()

  useEffect(()=>{
    if(passportPicCloudinary){
      saveToAmplify(`passportPicture: "${passportPicCloudinary}",`);
      syncBorrower('passportPicture', passportPicCloudinary)
    } 
  }, [passportPicCloudinary])
  
  useEffect(()=>{
    if(nationalIdPicCloudinary){
      saveToAmplify(`nationalIdPicture: "${nationalIdPicCloudinary}",`);
      syncBorrower('nationalIdPicture', nationalIdPicCloudinary)
    } 
  }, [nationalIdPicCloudinary])

  const saveToAmplify =  async(document) =>{
    try{
      const updatedBorrower = await API.graphql(graphqlOperation(
        `mutation MyMutation {
          updateBorrower(input: {
            ${document}
            id: "${borrowerId}"
          }) 
          {
            id
          }
        }`
      ))
      if(updatedBorrower)
      {
        alert('Document Saved Successfully')
      }
    }
    catch(e){
      console.log('File upload Failed', e)
    }
  }
    
  function handleSave(req, setRes){
    uploads3(req, setRes)
  }

  return (
    <Box width="100%" sx={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
      <Box
        display="grid"
        gap="30px" mt="20px" p="10px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Box sx={{ gridColumn: "span 2" }}>
          <UploadFile
            handleSave={handleSave}
            req = {passportPic}
            setRes = {setPassportPicCloudinary}
            res = {passportPicCloudinary}
            uploadLabel={'Photo of Borrower'}
            setUploadFile={setPassportPic}
            placeholderImage={(function(){
              return initialBorrower.passportPicture
                ? initialBorrower.passportPicture
                : assetPlaceHolderImage
            })()}
          />
        </Box>
        <Box sx={{ gridColumn: "span 2" }}>
          <UploadFile
            handleSave={handleSave}
            req = {nationalIdPic}
            setRes = {setNationalIdPicCloudinary}
            res = {nationalIdPicCloudinary}
            uploadLabel={'Photo of ID (National ID, Passport, Driving Permit etc.)'}
            setUploadFile={setNationalIdPic}
            placeholderImage={(function(){
              return initialBorrower.nationalIdPicture
                ? initialBorrower.nationalIdPicture
                : assetPlaceHolderImage
            })()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PhotoAndId;