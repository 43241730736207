import React, { useState, useContext} from "react";
import { useParams, useNavigate } from 'react-router-dom'
import Header from "../../components/Header"
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { API, graphqlOperation } from "aws-amplify";
import "react-datepicker/dist/react-datepicker.css"
import { styled } from "@mui/material/styles";
import "../../assets/custom.css";
import { tokens } from "../../theme";
import { UserIDContext } from "../../App";
import { useEffect } from "react";
import { currenciesObj } from "../../assets/currenciesObj";
import { UserTypeContext } from "../../App";
import AlertMessage from "../../components/AlertMessage";
import { numberRegex } from "../../assets/currenciesObj";

const CreateAccount = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [processing, setProcessing] = useState(false)
    const currencies = Object.keys(currenciesObj)
    const navigate = useNavigate();
    const userID = useContext(UserIDContext)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {accounts, setAccounts, userCurrency, userTypeAWS} = useContext(UserTypeContext);
    const corporate = userTypeAWS === 'ENTERPRISE' ? true : false

    const initialValues = {
      openingBalance: '',
      accountName: '',
      currency: userCurrency,
    };
        
    const CssTextField = styled(TextField, {
      shouldForwardProp: (props) => props !== "focusColor"
    })((p) => ({
      // input label when focused
      "& label.Mui-focused": {
        color: p.focusColor
      }
    }));
    const labelFocusColor = '#3da58a'
    
    const handleFormSubmit = (values) => {
      const newAccount = {
        openingBalance: parseFloat(values.openingBalance.replace(/,/g, '')),
        accountName: values.accountName,
        currency: values.currency
      };
      createAccount(newAccount)
    };

    const createAccount =  async(account) =>{
      setProcessing(true)
      try{
        const createdAccount = await API.graphql(graphqlOperation(`mutation MyMutation 
          {
            createAccount(input: {
              accountName: "${account.accountName}", 
              openingBalance: ${account.openingBalance}, 
              userAccountsId: "${userID}",
              currency: "${account.currency}",
              accountStatus: "active"
            }) {
              id
            }
          }`
          ))     
          if(createdAccount){
          const accountSummary = `${account.accountName} (${account.currency})`
          const updatedAccounts = [...accounts,
            {id: createdAccount.data.createAccount.id, accountName: account.accountName,
            openingBalance: account.openingBalance, currency: account.currency}]
          setAccounts(updatedAccounts)
          navigate(`/manageAccounts/${accountSummary}`)
        }
      }
      catch(e){
        console.log('Account creation Failed', e)
      }
    }
  
    return (
      <Box m="20px">
          <Box className='printNot'>
            <Box>
              <Header title={`Create a New Account`} 
                subtitle={`Accounts receive payments, fees and income. \n\n
                Loans and expenses are disbursed from accounts`} />
            </Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                    }}
                  >
                    <CssTextField focusColor={labelFocusColor}
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Give Your Account a Name e.g. Bank, Cash, Mobile Money, etc"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountName}
                      name="accountName"
                      error={!!touched.accountName && !!errors.accountName}
                      helperText={touched.accountName && errors.accountName}
                      sx={{ gridColumn: "span 3" }}
                    />
                    <Box sx={{ gridColumn: "span 1" }}>
                      <Box display='flex' gap="10px">
                        <p>Account currency</p>
                        <Field
                          className="formSelect"
                          as="select"
                          name="currency"
                          onChange={handleChange}
                          disabled={!corporate}
                        >
                        {currencies.map((currency, i)=>(
                          <option value={currency} key={i}>{currency}</option>
                        ))}
                        </Field>
                      </Box>
                    </Box>
                    <CssTextField focusColor={labelFocusColor}
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Opening Balance (put zero if there is no opening balance)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.openingBalance.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      name="openingBalance"
                      error={!!touched.openingBalance && !!errors.openingBalance}
                      helperText={touched.openingBalance && errors.openingBalance}
                      sx={{ gridColumn: "span 2" }}
                    />
                    {!corporate && 
                      <Box sx={{ gridColumn: "span 3" }}>
                        <AlertMessage buttonText={'Enable Multiple Currencies'}
                          message={<Box>
                            <Typography>Your system currency is {userCurrency}.</Typography>
                            <Typography>Multiple Currencies are only Available on Enterprise Packages</Typography>
                        </Box>}
                          button1={<Button 
                            onClick={()=> window.open('https://www.loantabs.com/enterprise', '_blank')} variant="contained"
                            color="info" m="20px">Upgrade to Enterprise</Button>}
                        />
                      </Box>}
                  </Box>

                  <Box display="flex" mt="30px">
                    <Button type="submit" color="success" variant="contained">
                      Create Account
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          {processing && <Box display='flex'>
            <Typography
              variant="h4"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: "10px 0 60px 0" }}
            >Processing... Please Wait...</Typography>
          </Box>}
      </Box>
    );
  };
  
  const checkoutSchema = yup.object().shape({
    openingBalance: yup.string().matches(numberRegex, "must be a number").required("required"),
    accountName: yup.string().required("required"),
  });
  
export default CreateAccount