import React from 'react'

const Guides = () => {
 
  return (
    <div>
      <h1>Guides</h1>
   </div>
  )
}

export default Guides