const removeCommas = (stringWithCommas)=>{
  const numberWithoutCommas = parseFloat(stringWithCommas.replace(/,/g, ''), 10);
  return numberWithoutCommas
}

function scheduleLoan(principal, interest, loanDuration, startDate, 
  interestRateFrequency, loanDurationFormat, repaymentCycle, decimalDigits,
  numberOfPayments, paymentFrequency) {

  //constants
    const interestRate = Math.max(interest, 0.0001)
    const decimalPoints = decimalDigits || 0
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const decimalOptions = {
      maximumFractionDigits: decimalPoints,
      minimumFractionDigits: decimalPoints
    }
  //funciton for lumpSum loans:
  function loanDurationMultiplier() {
    switch(interestRateFrequency){
      case 'per day':
        if(loanDurationFormat === 'days'){
          return 1 * loanDuration
        }
        if(loanDurationFormat === 'weeks'){
          return 7 * loanDuration
        }
        if(loanDurationFormat === 'months'){
          return 30 * loanDuration
        }
        if(loanDurationFormat === 'years'){
          return 365 * loanDuration
        }
        break;
        case 'per week':
          if(loanDurationFormat === 'days'){
            return Math.ceil(loanDuration/7);
          }
          if(loanDurationFormat === 'weeks'){
            return loanDuration * 1;
          }
          if(loanDurationFormat === 'months'){
            return loanDuration * 4;
          }
          if(loanDurationFormat === 'years'){
            return loanDuration * 52;
          }
          break;
        case 'per month':
          if(loanDurationFormat === 'days'){
            return Math.ceil(loanDuration/30);
          }
          if(loanDurationFormat === 'weeks'){
            return Math.ceil(loanDuration/4);
          }
          if(loanDurationFormat === 'months'){
            return loanDuration * 1;
          }
          if(loanDurationFormat === 'years'){
            return loanDuration * 12;
          }
          break;
        case 'per year':
          if(loanDurationFormat === 'days'){
            return Math.ceil(loanDuration/365);
          }
          if(loanDurationFormat === 'weeks'){
            return Math.ceil(loanDuration/52);
          }
          if(loanDurationFormat === 'months'){
            return Math.ceil(loanDuration/12);
          }
          if(loanDurationFormat === 'years'){
            return 1*loanDuration;
          }
          break;
    }

  }

  function simpleLumpSumEndDate(period) {
    const duration = period || loanDuration
    const endDate = new Date(startDate)
    if(loanDurationFormat === 'days'){
      endDate.setDate(startDate.getDate() + duration)
    }
    if(loanDurationFormat === 'weeks'){
      endDate.setDate(startDate.getDate() + (duration * 7))
    }
    if(loanDurationFormat === 'months'){
      endDate.setMonth(startDate.getMonth() + duration)
    }
    if(loanDurationFormat === 'years'){
      endDate.setFullYear(startDate.getFullYear() + duration)
    }
    return endDate;
  }

  function compoundLumpSumEndDate(period) {
    const duration = period || loanDuration
    const endDate = new Date(startDate)
    if(interestRateFrequency === 'per day'){
      endDate.setDate(startDate.getDate() + duration)
    }
    if(interestRateFrequency === 'per week'){
      endDate.setDate(startDate.getDate() + (duration * 7))
    }
    if(interestRateFrequency === 'per month'){
      endDate.setMonth(startDate.getMonth() + duration)
    }
    if(interestRateFrequency === 'per year'){
      endDate.setFullYear(startDate.getFullYear() + duration)
    }
    return endDate;
  }

  //if simpleLumpSum
  if(repaymentCycle === 'simpleLumpSum') {
    let interestOnLoan
    if(interestRateFrequency === 'per loan'){
      interestOnLoan = principal * interestRate/100
    }else{
      interestOnLoan = principal * interestRate * 
        loanDurationMultiplier() /100
    }

    const instalment = [{
      id: 1, 
      date: simpleLumpSumEndDate().toLocaleDateString('en-GB', options),
      principal: principal.toLocaleString('en-US', decimalOptions),
      amountToPay: (principal + interestOnLoan).toLocaleString('en-US', decimalOptions),
      interest: interestOnLoan.toLocaleString('en-US', decimalOptions)
    }]
    return [instalment, loanDuration, interestRateFrequency, 
      startDate.toLocaleDateString('en-GB', options), false, interestOnLoan]; 
  }

  //compound lumpSum
  if(repaymentCycle === 'lumpSum'){
    const instalments = [];
    let newInterest = principal * interestRate /100
    let amountToPay = principal + newInterest;
    let newPrincipal = amountToPay - newInterest; 
    const numberOfTimesToCompound = loanDurationMultiplier()

    for (let i = 1; i <= numberOfTimesToCompound; i++) {
      const instalment = {
        id: i,
        date: compoundLumpSumEndDate(i).toLocaleDateString('en-GB', options),
        principal: newPrincipal.toLocaleString('en-US', decimalOptions),
        amountToPay: amountToPay.toLocaleString('en-US', decimalOptions),
        interest: newInterest.toLocaleString('en-US', decimalOptions)
      };
      
      instalments.push(instalment);
      newInterest = amountToPay * interestRate /100
      newPrincipal = amountToPay
      amountToPay += newInterest;
    }
    return [instalments, loanDuration, interestRateFrequency, 
      startDate.toLocaleDateString('en-GB', options), false,
      removeCommas(instalments[instalments.length - 1].amountToPay) - principal];
  }

  // Calculate the number of payments, ensuring it's at least 1
  const calculateNumberOfPayments = ()=>{
    switch(paymentFrequency) {
      case 'per day':
        if(loanDurationFormat === 'days'){
          return loanDuration;
        }
        if(loanDurationFormat === 'weeks'){
          return loanDuration *7;
        }
        if(loanDurationFormat === 'months'){
          return loanDuration *30;
        }
        if(loanDurationFormat === 'years'){
          return loanDuration *365;
        }
        break;
      case 'per week':
        if(loanDurationFormat === 'days'){
          return Math.ceil(loanDuration/7);
        }
        if(loanDurationFormat === 'weeks'){
          return loanDuration;
        }
        if(loanDurationFormat === 'months'){
          return loanDuration *4;
        }
        if(loanDurationFormat === 'years'){
          return loanDuration *52;
        }
        break;
      case 'per month':
        if(loanDurationFormat === 'days'){
          return Math.ceil(loanDuration/30);
        }
        if(loanDurationFormat === 'weeks'){
          return Math.ceil(loanDuration/4);
        }
        if(loanDurationFormat === 'months'){
          return loanDuration;
        }
        if(loanDurationFormat === 'years'){
          return loanDuration *12;
        }
        break;
      case 'per year':
        if(loanDurationFormat === 'days'){
          return Math.ceil(loanDuration/365);
        }
        if(loanDurationFormat === 'weeks'){
          return Math.ceil(loanDuration/52);
        }
        if(loanDurationFormat === 'months'){
          return Math.ceil(loanDuration/12);
        }
        if(loanDurationFormat === 'years'){
          return loanDuration;
        }
        break;
    }
  }
  const numPayments = numberOfPayments || calculateNumberOfPayments();

  //caculate the period interest rate (equivalent of monthlyInterestRate)
  const calulatePeriodInterestRate = () =>{
    switch(interestRateFrequency){
      case 'per day':
        if(paymentFrequency === 'per day'){
          return interestRate
        }
        if(paymentFrequency === 'per week'){
          return interestRate *7
        }
        if(paymentFrequency === 'per month'){
          return interestRate *30
        }
        if(paymentFrequency === 'per year'){
          return interestRate *365
        }
        break;
      case 'per week':
        if(paymentFrequency === 'per day'){
          return interestRate /7
        }
        if(paymentFrequency === 'per week'){
          return interestRate
        }
        if(paymentFrequency === 'per month'){
          return interestRate *4
        }
        if(paymentFrequency === 'per year'){
          return interestRate *52
        }
        break;
      case 'per month':
        if(paymentFrequency === 'per day'){
          return interestRate /30
        }
        if(paymentFrequency === 'per week'){
          return interestRate/4
        }
        if(paymentFrequency === 'per month'){
          return interestRate
        }
        if(paymentFrequency === 'per year'){
          return interestRate *12
        }
        break;
      case 'per year':
        if(paymentFrequency === 'per day'){
          return interestRate /365
        }
        if(paymentFrequency === 'per week'){
          return interestRate/52
        }
        if(paymentFrequency === 'per month'){
          return interestRate/12
        }
        if(paymentFrequency === 'per year'){
          return interestRate
        }
      case 'per loan':
        return interestRate / numPayments
        break;
    }
  }
    
  const periodInterestRate = calulatePeriodInterestRate()
    
  let amortizationSchedule = [];
  let paymentAmount
  let grossLoanValue

  //flat instalment loans
  if(repaymentCycle == 'flatInstalments') {
    let interestOnLoan
    if(interestRateFrequency === 'per loan'){
      interestOnLoan = principal * interestRate/100
    }else{
      interestOnLoan = principal * interestRate * 
        loanDurationMultiplier() /100
    }
    grossLoanValue = principal + interestOnLoan;
    paymentAmount = principal + interestOnLoan
    const monthlyPayment = paymentAmount / numPayments
    let reducingBalance = paymentAmount

    for(let i = 1; i <= numPayments; i++){
      let paymentDate = new Date(startDate);
      switch (paymentFrequency) {
          case 'per day':
              paymentDate.setDate(paymentDate.getDate() + i);
              break;
          case 'per week':
              paymentDate.setDate(paymentDate.getDate() + (i * 7));
              break;
          case 'per month':
              paymentDate.setMonth(paymentDate.getMonth() + i);
              break;
          case 'per year':
              paymentDate.setFullYear(paymentDate.getFullYear() + i);
              break;
          default:
              throw new Error('Invalid loan duration format');
      }
      amortizationSchedule.push({
        id: i,
        paymentDate,
        principalAmountToPay: principal/numPayments,
        interestAmountToPay: interestOnLoan/numPayments,
        loanBalance: reducingBalance,
        totalPayment: monthlyPayment,
        closingBalance: reducingBalance - monthlyPayment,
      });
      reducingBalance -= monthlyPayment;
    }
  }  

  if(repaymentCycle === 'instalments'){
    paymentAmount = principal * ((periodInterestRate/100) /
        (1 - Math.pow(1 + (periodInterestRate/100), -numPayments)));
  
    grossLoanValue = (paymentAmount * numPayments)+0.00000001
    // Initialize variables
    let loanBalance = principal;
  
    // Loop through each payment period
    for (let i = 1; i <= numPayments; i++) {
        // Calculate interest for the current period
        const interestPayment = loanBalance * periodInterestRate/100;
  
        // Calculate principal payment for the current period
        const principalPayment = paymentAmount - interestPayment;
  
        // Calculate payment date for the current period
        let paymentDate = new Date(startDate);
        switch (paymentFrequency) {
            case 'per day':
                paymentDate.setDate(paymentDate.getDate() + i);
                break;
            case 'per week':
                paymentDate.setDate(paymentDate.getDate() + (i * 7));
                break;
            case 'per month':
                paymentDate.setMonth(paymentDate.getMonth() + i);
                break;
            case 'per year':
                paymentDate.setFullYear(paymentDate.getFullYear() + i);
                break;
            default:
                throw new Error('Invalid loan duration format');
        }
  
        // Store payment details in the amortization schedule
        amortizationSchedule.push({
            id: i,
            paymentDate,
            principalAmountToPay: principalPayment,
            interestAmountToPay: interestPayment,
            totalPayment: paymentAmount,
            loanBalance,
            closingBalance: loanBalance - principalPayment
        });
  
        // Update loan balance
        loanBalance -= principalPayment;
  
    }
  }
  
  const intsalmentDetails = amortizationSchedule.map((item)=>({
    id: item.id,
    date: item.paymentDate.toLocaleDateString('en-GB', options),
    principal: item.principalAmountToPay.toLocaleString('en-US', decimalOptions),
    interest: item.interestAmountToPay.toLocaleString('en-US', decimalOptions),
    balance: item.loanBalance.toLocaleString('en-US', decimalOptions), 
    totalPayment: item.totalPayment.toLocaleString('en-US', decimalOptions),
    closingBalance: item.closingBalance.toLocaleString('en-US', decimalOptions),
  }));

  const interestOnLoan = grossLoanValue - principal

  return [intsalmentDetails, numPayments, interestRateFrequency, 
    startDate.toLocaleDateString('en-GB', options), false, 
    paymentAmount, grossLoanValue, interestOnLoan]
}

export {scheduleLoan}
