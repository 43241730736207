import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { UserTypeContext } from '../../App';

//component imports
import CreateUserAdminForm from './UserAdminForms/CreateUserAdminForm';
import Header from '../../components/Header';
import SuccessMessage from '../../components/SuccessMessage';
import { TroubleshootOutlined } from '@mui/icons-material';
import AssignedBorrowers from './UserAdminForms/AssignedBorrowers';
import LoansPanel from '../Loans/LoansPanel';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CreateUserAdmin() {

  //variables
  const navigate = useNavigate();
  const {id, name, phoneNumber, email, type, accessLevel} = useParams()
  const loginButtonText = accessLevel !== '-' ? `Modify Log In Access for ${name}`
    : `Enable Log In Access for ${name}`
  const {userTypeAWS} = React.useContext(UserTypeContext)
  
  //states
  const [value, setValue] = React.useState(0);
  const [editableUserAdmin, setEditableUserAdmin] = useState();
  const [successMessage, setSuccessMessage] = useState()
  const [adminName, setAdminName] = useState()
  const [corporateUpgradeRequired, setCorporateUpgradeRequired] = useState()

  //useEffect 
  useEffect(() => {
    name && setEditableUserAdmin({id, name, phoneNumber, email, type, accessLevel})
    name && setAdminName(name)
  }, [])
  //funcitons

  function readyToEdit(values){
    setEditableUserAdmin(values)
    setSuccessMessage(`${values.name} Created Successfully`)
    setAdminName(values.name)
    setTimeout(() => setSuccessMessage(false), 15000)
  }

  function editedUserAdmin(values){
    const tempUserAdmin = {...editableUserAdmin}
    for (const key in values) {
      tempUserAdmin[key] = values[key];
    }
    setEditableUserAdmin(tempUserAdmin)
    setSuccessMessage(`${values.name} Edited Successfully`)
    setTimeout(() => setSuccessMessage(false), 15000)
  }

  function handleChange(event, newValue){
    setValue(newValue);
  };

  function syncUserAdmin(key, value){
    const tempUserAdmin = {...editableUserAdmin}
    tempUserAdmin[key] = value;
    setEditableUserAdmin(tempUserAdmin);
  }

  const deleteUserAdmin = async()=>{
    try{
      const deletedUserAdmin = await API.graphql(graphqlOperation(`mutation MyMutation {
        deleteUserAdmin(input: {id: "${editableUserAdmin.id}"}) {
          id
        }
      }`));
      if(deletedUserAdmin){
        setSuccessMessage(`${name} Deleted Successfully`)
        setTimeout(()=>navigate(`/manageUserAdmins`), 1500)
      }
    }catch(e){
      console.log('Error deleting userAdmin: ',e)
    }
  }

  const promptForUpgrade = () => {
    setCorporateUpgradeRequired(true);
    setTimeout(() => setCorporateUpgradeRequired(false), 8000)
  }

  return (<>
    <Box sx={{ width: 850, mt: '10px' }}>
      {!editableUserAdmin && <Header title="Create Team Member" subtitle="Create a New Team Member Profile" />}
      {editableUserAdmin && 
      <Box>
        {successMessage && <SuccessMessage message={successMessage}/>}
        <Box mt='30px' mb='30px' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
      {/*set back to CORPORATE */}
          {((userTypeAWS === 'CORPORATE') || (userTypeAWS === 'ENTERPRISE')) && <Button 
            onClick={() => navigate('/teamLogInAccess', {
              state: {
                email: editableUserAdmin.email,
                name: editableUserAdmin.name,
                id: editableUserAdmin.id,
                phoneNumber: editableUserAdmin.phoneNumber,
                role: editableUserAdmin.type,
                accessLevel: editableUserAdmin.accessLevel
              }
            })}
            variant="contained" color='info'>{loginButtonText}</Button>}
          {((userTypeAWS !== 'CORPORATE') && (userTypeAWS !== 'ENTERPRISE')) && (!corporateUpgradeRequired) && <Button 
            onClick={promptForUpgrade}
            variant="contained" color='info'>Enable Log In Access for {adminName}</Button>}
          {corporateUpgradeRequired && <Box display={'flex'} flexDirection={'row'} gap="20px">
            <Typography>Feature only available on Corporate</Typography>
            <Button
              onClick={()=>navigate('/Packages')}
              variant="contained" color='info'>UPGRADE TO CORPORATE</Button>
          </Box>}
          <Button 
            onClick={() => navigate('/manageUserAdmins')}
            variant="contained" color='success'>Manage / Add to Team</Button>
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <Header title={`${adminName} (Team Member Profile)`}
            subtitle="View/Update the Team Member details" />
          <Button 
            onClick={deleteUserAdmin}
            variant="outlined" color="error">{`Delete ${adminName}`}</Button>
        </Box>
      </Box>
        }
      <Box sx = {{bgcolor: 'background.paper', mb: '10px'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="secondary tabs example"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab {...a11yProps(0)} label="TEAM MEMBER DETAILS" />
            <Tab {...a11yProps(1)} label="ASSIGNED BORROWERS" />
            <Tab {...a11yProps(2)} label="LOANS" />
          </Tabs>  
      </Box>
      <CustomTabPanel value={value} index={0}>
        {!editableUserAdmin && <CreateUserAdminForm readyToEdit={readyToEdit}/>}
        {editableUserAdmin && <CreateUserAdminForm initialUserAdmin={editableUserAdmin}
          editedUserAdmin={editedUserAdmin}/>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {editableUserAdmin?.id && <AssignedBorrowers adminName={editableUserAdmin?.name} 
        userAdminId={editableUserAdmin?.id}/>}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {editableUserAdmin?.id && <LoansPanel adminIdToFilter={editableUserAdmin?.id} />}
      </CustomTabPanel>
    </Box>
  </>);
}
