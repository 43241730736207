import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { useNavigate } from 'react-router-dom';
//component imports
import DashboardCard from '../../components/DashboardCard'
import { tokens } from '../../theme';


function ReportsPanel() {

  //constants
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

  return (<>
    <Box display="flex" justifyContent={'center'}>
      <h1>View Reports</h1>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Box p='20px' m='20px' sx={{border: ' 1px solid', borderColor: colors.greenAccent[300],
          display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1}}>
        <Box gap="20px" sx={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
          <DashboardCard onClick={()=> navigate('/loansReport')}
            cardTitle={'Loans Report'}
            icon={<ReceiptOutlinedIcon sx={{fontSize: '60px', color: colors.greenAccent[300], 
            alignSelf: 'center'}}/>} />
          <DashboardCard onClick={()=> navigate('/paymentsReport')}
            cardTitle={'Payments Report'}
            icon={<MonetizationOnIcon sx={{fontSize: '60px', color: colors.greenAccent[300], 
            alignSelf: 'center'}}/>} />
          <DashboardCard onClick={()=> navigate('/borrowersReport')}
            cardTitle={'Borrower Reports'}
            icon={<PeopleOutlinedIcon sx={{fontSize: '60px', color: colors.greenAccent[300], 
            alignSelf: 'center'}}/>} />
          <DashboardCard onClick={()=> navigate('/incomeStatement')}
            cardTitle={'Profit/Loss Statements'}
            icon={<AutoGraphIcon sx={{fontSize: '60px', color: colors.greenAccent[300], 
            alignSelf: 'center'}}/>} />
        </Box>
      </Box>
    </Box>
  </>
  )
}

export default ReportsPanel